import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 250px)",
  },
  actionCell: {
    padding: "0",
    paddingRight: 15,
  },
  dataRow: {
    cursor: "pointer",
  },
}));
