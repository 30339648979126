import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import AddIcon from "@mui/icons-material/Add";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { Protect } from "@clerk/clerk-react";
import { isNumber } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import useStyle from "./styles";
import Button from "../../../../../components/button";
import ViewWrapper from "../../../../../components/view-wrapper";
import PopoverMenu from "../../../../../components/popover-menu";
import DealFeeFormModal from "../../../../../components/deal-fee-form-modal";
import StickyTableCell from "../../../../../components/sticky-table-cell";
import ConditionalProtect from "../../../../../components/conditional-protect";
import { useAPI, useAppSelector } from "../../../../../utils/hooks";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import {
  cn,
  navigateAndScroll,
  numberToUSD,
  sortArrayOfObjects,
  generateDealOnlyCostsRows,
  getTableColumnAccordingToStatus,
} from "../../../../../utils/helpers";
import {
  DEAL_COST_HEADERS,
  USER_PERMISSIONS,
  DEAL_FEE_TYPES,
  DEAL_FEE_ALLOCATION_METHODS,
  DEAL_FEE_COST_WEIGHTING_METHODS,
  DEAL_FEE_FORM_DEFAULT_STATE,
} from "../../../../../constants";
import {
  ITableColumn,
  ServerPaginatedResponse,
  ITableSort,
  IDealOnlyCost,
  IDealFee,
  IDealFeeForm,
  IDealFeeFormErrors,
} from "../../../../../interfaces";

export const DEAL_FEE_COLUMN: ITableColumn[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "fee_type",
    label: "Type",
    minWidth: 100,
    align: "left",
  },
  {
    id: "allocation_method",
    label: "Allocation Method",
    minWidth: 100,
    align: "left",
  },
  {
    id: "amortization_term",
    label: "Amortization Term",
    minWidth: 100,
    align: "left",
  },
  {
    id: "cost_weighting_method",
    label: "Cost Weighting Method",
    minWidth: 100,
    align: "left",
  },
  {
    id: "amount",
    label: "Fee",
    minWidth: 100,
    align: "left",
  },
  {
    id: "fee_multiplier",
    label: "Fee Multiplier",
    minWidth: 100,
    align: "left",
  },
];

interface IProps {
  getDealFees: (dealId: number) => Promise<ServerPaginatedResponse<IDealFee[]>>;
  addDealFee: (dealId: number, form: IDealFeeForm) => Promise<IDealFee>;
  getDealOnlyCost: (dealId: number) => Promise<IDealOnlyCost>;
  updateDealFee: (
    dealId: number,
    feeId: number,
    form: IDealFeeForm,
  ) => Promise<IDealFee>;
  deleteDealFee: (dealId: number, feeId: number) => Promise<boolean>;
}

export default function DealFeeTableView({
  getDealFees,
  addDealFee,
  updateDealFee,
  deleteDealFee,
  getDealOnlyCost,
}: IProps): JSX.Element {
  const styles = useStyle();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { currentDeal } = useAppSelector((state) => state.deal);

  const navigate = useNavigate();
  const { dealId, caseDealId } = useParams();

  const [dealFees, setDealFees] = React.useState<IDealFee[]>([]);
  const [addDealFeeModalOpen, setAddDealFeeModalOpen] =
    React.useState<boolean>(false);
  const [updateDealFeeModalOpen, setUpdateDealFeeModalOpen] =
    React.useState<boolean>(false);
  const [selectedDealFeeToEdit, setSelectedDealFeeToEdit] =
    React.useState<number>();
  const [form, setForm] = React.useState<IDealFeeForm>(
    DEAL_FEE_FORM_DEFAULT_STATE,
  );
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const getDealFeesData = (dealId: number) => {
    getDealFeesCallAPI(Number(dealId)).then((response) => {
      response && setDealFees(response.results);
    });
  };

  React.useEffect(() => {
    getDealFeesData(Number(caseDealId));
  }, [caseDealId]);

  React.useEffect(() => {
    dealFees && getDealOnlyCostCallAPI(Number(caseDealId));
  }, [caseDealId, dealFees]);

  const {
    callAPI: getDealFeesCallAPI,
    errored: getFeesFailed,
    loading: loadingFees,
  } = useAPI((dealId: number) => getDealFees(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: addDealFeeCallAPI,
    fieldErrors: addDealFeeFormErrors,
    setFieldErrors: setAddDealFeeFormErrors,
    loading: addDealFeeLoading,
  } = useAPI<IDealFee, IDealFeeFormErrors>(
    (dealId: number, form: IDealFeeForm) => addDealFee(Number(dealId), form),
  );

  const {
    callAPI: updateDealFeeCallAPI,
    fieldErrors: updateDealFeeFormErrors,
    setFieldErrors: setUpdateDealFeeFormErrors,
    loading: updateDealFeeLoading,
  } = useAPI<IDealFee, IDealFeeFormErrors>(
    (dealId: number, feeId: number, form: IDealFeeForm) =>
      updateDealFee(dealId, feeId, form),
  );

  const { callAPI: deleteDealFeeCallAPI } = useAPI(
    (dealId, feeId) => deleteDealFee(dealId, feeId),
    {
      setConfirmModalLoading: true,
    },
  );

  const {
    callAPI: getDealOnlyCostCallAPI,
    errored: getDealOnlyCostFailed,
    loading: loadingDealOnlyCost,
    response: dealOnlyCost,
  } = useAPI((id) => getDealOnlyCost(id), { initialLoading: true });

  const handleOnAddDealFee = async (form: IDealFeeForm) => {
    const fee = await addDealFeeCallAPI(Number(caseDealId), form);
    fee && setDealFees((prev) => [fee, ...prev]);
    return fee;
  };

  const handleOnUpdateDealFee = async (form: IDealFeeForm) => {
    const updatedFee = await updateDealFeeCallAPI(
      Number(caseDealId),
      Number(selectedDealFeeToEdit),
      form,
    );
    updatedFee &&
      setDealFees((prevFees) =>
        prevFees.map((fee) => (fee.id === updatedFee.id ? updatedFee : fee)),
      );
    return updatedFee;
  };

  const handleOpenAddDealFeeModal = () => {
    setAddDealFeeModalOpen(true);
  };

  const handleCloseAddDealFeeModal = () => {
    setAddDealFeeModalOpen(false);
  };

  const handleOpenUpdateDealFeeModal = (id: number) => {
    const selectedFee = dealFees.find((e) => e.id === id);
    if (selectedFee) {
      setSelectedDealFeeToEdit(selectedFee.id);
      const formData: IDealFeeForm = {
        fee_type: selectedFee.fee_type,
        name: selectedFee.name,
        amount: selectedFee.amount,
        allocation_method: selectedFee.allocation_method,
        input_type: selectedFee.input_type,
        spend_method: selectedFee.spend_method,
        milestone: selectedFee.milestone,
        fee_multiplier: selectedFee.fee_multiplier,
        amortization_term: selectedFee.amortization_term,
        cost_weighting_method: selectedFee.cost_weighting_method,
      };
      setForm(formData);
      setUpdateDealFeeModalOpen(true);
    }
  };

  const handleDeleteDealFee = async (id: number) => {
    const fee = await deleteDealFeeCallAPI(Number(dealId), id);
    if (fee) {
      setDealFees((prev) => prev.filter((e) => e.id !== id));
    }
  };

  const handleOnDeleteClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Fee",
        description: "Are you sure you want to delete this fee?",
        onConfirm: () => handleDeleteDealFee(id),
      }),
    );
  };

  const handleCloseUpdateDealFeeModal = () => {
    setUpdateDealFeeModalOpen(false);
  };

  const goToDealFee = (dealFeeId: number) => {
    if (ctrlPressed) {
      window.open(
        `/deal/${dealId}/case-deal/${caseDealId}/sizing/deal-fee/${dealFeeId}`,
        "_blank",
      );
      return;
    }
    navigate(
      `/deal/${dealId}/case-deal/${caseDealId}/sizing/deal-fee/${dealFeeId}`,
    );
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const createTableData = (dealFees: IDealFee[]) => {
    return dealFees.map((dealFee) => {
      return {
        id: dealFee.id,
        fee_type: DEAL_FEE_TYPES[dealFee.fee_type],
        name: dealFee.name || "N/A",
        amount:
          dealFee.input_type == "DL"
            ? numberToUSD.format(dealFee.amount ?? 0)
            : dealFee.amount?.toFixed(2) + " $/Wdc",
        fee_multiplier: dealFee.fee_multiplier.toFixed(2) + "%",
        allocation_method:
          DEAL_FEE_ALLOCATION_METHODS[dealFee.allocation_method],
        amortization_term:
          dealFee.allocation_method === "DFA"
            ? isNumber(dealFee.amortization_term)
              ? dealFee.amortization_term + "Yr"
              : "N/A"
            : "N/A",
        cost_weighting_method:
          dealFee.allocation_method === "DAC"
            ? (DEAL_FEE_COST_WEIGHTING_METHODS[dealFee.cost_weighting_method] ??
              "N/A")
            : "N/A",
      };
    });
  };

  const feesTableData = React.useMemo(
    () => createTableData(dealFees),
    [dealFees],
  );

  const sortedRows = React.useMemo(
    () =>
      sortArrayOfObjects(feesTableData, sortTable?.orderBy, sortTable?.order),
    [sortTable, feesTableData],
  );

  const handleNavigate = async (url: string, elementId: string) => {
    navigateAndScroll(() => navigate(url), elementId);
  };

  const gotoDealCost = () =>
    handleNavigate(
      `/deal/${dealId}/case-deal/${caseDealId}/component/cost`,
      "deal-cost",
    );

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const dealCostrows = React.useMemo(() => {
    if (dealOnlyCost) {
      return generateDealOnlyCostsRows(dealOnlyCost);
    }
    return [];
  }, [dealOnlyCost]);

  return (
    <>
      <ViewWrapper loading={loadingFees} error={getFeesFailed}>
        <Box className={cn("flex justify-end mb-4")}>
          <Box className={cn("flex gap-4")}>
            {!isOnCase && (
              <ConditionalProtect
                permission={USER_PERMISSIONS.DEALS_CRUD}
                type="deal"
              >
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Add Fee"
                  onClick={handleOpenAddDealFeeModal}
                />
              </ConditionalProtect>
            )}
          </Box>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer classes={{ root: styles.classes.tableContainer }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={styles.classes.header}>
                <TableRow>
                  {getTableColumnAccordingToStatus(
                    DEAL_FEE_COLUMN,
                    currentDeal?.status as string,
                  ).map((column, idx) => {
                    if (column.id === "action") {
                      return (
                        <StickyTableCell
                          key={idx}
                          direction="right"
                          fixedColumnWidth={100}
                          align="center"
                          highZIndex
                        >
                          {column.label}
                        </StickyTableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={idx}
                        align={column.align as "left"}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={sortTable.orderBy === column.id}
                          direction={
                            sortTable.orderBy === column.id
                              ? sortTable.order
                              : "asc"
                          }
                          onClick={() => sortRows(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {dealFees.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={DEAL_FEE_COLUMN.length}>
                      There are no Deal Fees in place currently, please add one.
                    </TableCell>
                  </TableRow>
                ) : null}
                {sortedRows.map((fee, idx) => {
                  return (
                    <TableRow
                      hover
                      key={idx}
                      tabIndex={-1}
                      className={styles.classes.dataRow}
                      onClick={() => goToDealFee(fee.id)}
                    >
                      <TableCell align="left">{fee.name}</TableCell>
                      <TableCell align="left">{fee.fee_type}</TableCell>
                      <TableCell align="left">
                        {fee.allocation_method}
                      </TableCell>
                      <TableCell align="left">
                        {fee.amortization_term}
                      </TableCell>
                      <TableCell align="left">
                        {fee.cost_weighting_method}
                      </TableCell>
                      <TableCell align="left">{fee.amount}</TableCell>
                      <TableCell align="left">{fee.fee_multiplier}</TableCell>
                      <StickyTableCell
                        direction="right"
                        fixedColumnWidth={100}
                        align="center"
                      >
                        {!isOnCase && (
                          <ConditionalProtect
                            type="deal"
                            permission={USER_PERMISSIONS.PROJECTS_CRUD}
                          >
                            <PopoverMenu
                              uniqueId={idx}
                              canOpenUpgrade
                              items={[
                                {
                                  label: "Edit",
                                  onClick: () =>
                                    handleOpenUpdateDealFeeModal(fee.id),
                                },
                                {
                                  label: "Delete",
                                  onClick: () => handleOnDeleteClick(fee.id),
                                },
                              ]}
                            />
                          </ConditionalProtect>
                        )}
                      </StickyTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <ViewWrapper
          loading={loadingDealOnlyCost}
          error={getDealOnlyCostFailed}
        >
          <Paper classes={{ root: cn("w-full md:w-1/2 p-2 mt-4") }}>
            <Box className={cn("flex justify-between items-center mb-4")}>
              <Typography variant="h6" id="deal-cost" fontWeight="bold">
                Deal Fees
              </Typography>
            </Box>
            <TableContainer classes={{ root: styles.classes.tableContainer }}>
              <Table stickyHeader>
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {DEAL_COST_HEADERS.map((header, idx) => {
                      return (
                        <TableCell style={{ width: "100%" }} key={idx}>
                          {header.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealCostrows.map((row, index) => (
                    <TableRow key={index}>
                      {row.columns.map((cell, idx) => (
                        <TableCell key={idx}>{cell.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ViewWrapper>
      </ViewWrapper>

      <DealFeeFormModal
        open={addDealFeeModalOpen}
        headerLabel="Add Fee"
        loading={addDealFeeLoading}
        formErrors={addDealFeeFormErrors}
        setFormErrors={setAddDealFeeFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseAddDealFeeModal}
        onConfirm={handleOnAddDealFee}
      />

      <DealFeeFormModal
        open={updateDealFeeModalOpen}
        loading={updateDealFeeLoading}
        headerLabel="Update Fee"
        formErrors={updateDealFeeFormErrors}
        setFormErrors={setUpdateDealFeeFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateDealFeeModal}
        onConfirm={handleOnUpdateDealFee}
      />
    </>
  );
}
