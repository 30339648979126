import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import FormControl from "@mui/material/FormControl";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DatePickerProps,
  LocalizationProvider,
  DateValidationError,
  PickerChangeHandlerContext,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers";

import useStyles from "./styles";
import Tooltip from "../tooltip";
import { IExtendedFormHelperTextProps } from "../../interfaces";

interface IProps extends DatePickerProps<Date> {
  error?: boolean;
  helperText?: string;
  format?: string;
  dataPw?: string;
  tooltip?: string;
  fullWidth?: boolean;
}

export default function DatePicker({
  error,
  helperText,
  format,
  onChange,
  dataPw,
  tooltip,
  fullWidth = true,
  ...datePicker
}: IProps): JSX.Element {
  const styles = useStyles({ fullWidth: fullWidth });

  const handleOnChange = (
    d: Date | null,
    c: PickerChangeHandlerContext<DateValidationError>,
  ) => {
    datePicker.onOpen && datePicker.onOpen(); // to clear the error when the user types the date
    if (onChange) {
      onChange(d, c);
    }
  };

  return (
    <Box className={styles.classes.container}>
      <FormControl classes={{ root: styles.classes.formControl }} fullWidth>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            {...datePicker}
            onChange={handleOnChange}
            className={styles.classes.input}
            format={format || "M / d / yyyy"}
            defaultValue={new Date()}
            minDate={new Date("01/01/2000")}
            slotProps={{
              openPickerButton: {
                "data-pw": dataPw || datePicker.label,
              } as { [key: string]: string },
              textField: {
                error,
                helperText,
                FormHelperTextProps: {
                  "data-pw": `helper-text-${dataPw || datePicker.label}`,
                } as IExtendedFormHelperTextProps,
                inputProps: {
                  "data-pw": dataPw || datePicker.label,
                },
              },
            }}
          />
        </LocalizationProvider>
      </FormControl>

      {tooltip && (
        <Tooltip title={tooltip} placement="top-end">
          <InfoIcon classes={{ root: styles.classes.infoIcon }} />
        </Tooltip>
      )}
    </Box>
  );
}
