import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { format, isValid } from "date-fns";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import AutocompleteField from "../autocomplete-field";
import DatePicker from "../date-picker";
import CurrencyInput from "../currency-input";
import { ISelectOption, SetStateAction } from "../../interfaces";
import {
  IGeneralAssumptionResponse,
  IGeneralAssumptionsForm,
  IGeneralAssumptionsFormErrors,
} from "../../interfaces/deal/construction-debt";
import {
  CLOSING_DATE_POINT_CHOICES_OPTIONS,
  DATE_SELECTION_TYPE_CHOICES_OPTION,
  FUNDING_METHODOLOGY_CHOICES_OPTIONS,
  GENERAL_ASSUMPTIONS_FORM_DEFAULT_STATE,
  LOC_FOR_CREDIT_SUPPORT_OPTIONS,
} from "../../constants/deal/construction-debt";
import { Check } from "@mui/icons-material";
import CheckboxInput from "../checkbox-input";

interface IProps {
  open: boolean;
  form: IGeneralAssumptionsForm;
  headerLabel: string;
  loading: boolean;
  formErrors?: IGeneralAssumptionsFormErrors;
  setFormErrors: SetStateAction<IGeneralAssumptionsFormErrors | undefined>;
  setForm: SetStateAction<IGeneralAssumptionsForm>;
  onClose: () => void;
  onConfirm: (
    form: IGeneralAssumptionsForm,
  ) => Promise<IGeneralAssumptionResponse | undefined>;
  ircCurves: ISelectOption[];
}

const NO_CURVES = [{ label: "No Curves", value: "", disabled: true }];

export default function GeneralAssumptionFormModal({
  open,
  form,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
  ircCurves,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const updatedForm: IGeneralAssumptionsForm = {
      ...form,
      loc_fee:
        form.loc_for_construction_credit_support === "YES"
          ? form.loc_fee
          : null,
      equity_letter_of_credit_fee:
        form.funding_methodology === "DF"
          ? form.equity_letter_of_credit_fee
          : null,
      debt_closing_date_point:
        form.debt_closing_date_type === "MD"
          ? form.debt_closing_date_point
          : "",
      debt_closing_date:
        form.debt_closing_date_type === "SPD" ? form.debt_closing_date : null,
    };
    const generalAssumption = await onConfirm(updatedForm);
    generalAssumption && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(GENERAL_ASSUMPTIONS_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <>
        <SelectInput
          required
          label="Date Selection Type"
          selected={form.debt_closing_date_type}
          items={DATE_SELECTION_TYPE_CHOICES_OPTION}
          onFocus={() => clearSelectErrorOnFocus("debt_closing_date_type")}
          onChange={(e) => handleSelectInputChange(e, "debt_closing_date_type")}
          error={Boolean(formErrors?.debt_closing_date_type)}
          helperText={formErrors?.debt_closing_date_type}
          disabled={loading}
        />
        {form.debt_closing_date_type === "MD" && (
          <SelectInput
            required
            label="Debt Closing Date Point"
            selected={form.debt_closing_date_point}
            items={CLOSING_DATE_POINT_CHOICES_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("debt_closing_date_point")}
            onChange={(e) =>
              handleSelectInputChange(e, "debt_closing_date_point")
            }
            error={Boolean(formErrors?.debt_closing_date_point)}
            helperText={formErrors?.debt_closing_date_point}
            disabled={loading}
          />
        )}
        {form.debt_closing_date_type === "SPD" && (
          <DatePicker
            label="Debt Closing Date"
            value={
              form.debt_closing_date ? new Date(form.debt_closing_date) : null
            }
            onChange={(v) => handleDateChange(v, "debt_closing_date")}
            onOpen={() => clearNonTextFieldErrorOnFocus("debt_closing_date")}
            error={Boolean(formErrors?.debt_closing_date)}
            helperText={formErrors?.debt_closing_date}
            disabled={loading}
          />
        )}
        <SelectInput
          required
          label="Funding Methodology"
          selected={form.funding_methodology}
          items={FUNDING_METHODOLOGY_CHOICES_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("funding_methodology")}
          onChange={(e) => handleSelectInputChange(e, "funding_methodology")}
          error={Boolean(formErrors?.funding_methodology)}
          helperText={formErrors?.funding_methodology}
          disabled={loading}
        />
        {form.funding_methodology === "DF" && (
          <TextInput
            required
            type="number"
            label="Equity Letter of Credit Fee"
            name="equity_letter_of_credit_fee"
            value={form.equity_letter_of_credit_fee}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.equity_letter_of_credit_fee)}
            helperText={formErrors?.equity_letter_of_credit_fee}
            endAdornment={<>%</>}
            disabled={loading}
          />
        )}
        <TextInput
          required
          type="number"
          label="Minimum Equity During Construction"
          name="min_equity_during_construction"
          value={form.min_equity_during_construction}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.min_equity_during_construction)}
          helperText={formErrors?.min_equity_during_construction}
          endAdornment={<>%</>}
          disabled={loading}
        />
        <SelectInput
          required
          label="Letter of Credit for Construction Credit support"
          selected={form.loc_for_construction_credit_support ?? ""}
          items={LOC_FOR_CREDIT_SUPPORT_OPTIONS}
          onFocus={() =>
            clearSelectErrorOnFocus("loc_for_construction_credit_support")
          }
          onChange={(e) =>
            handleSelectInputChange(e, "loc_for_construction_credit_support")
          }
          error={Boolean(formErrors?.loc_for_construction_credit_support)}
          helperText={formErrors?.loc_for_construction_credit_support}
          disabled={loading}
        />
        {form.loc_for_construction_credit_support === "YES" && (
          <TextInput
            required
            type="number"
            label="Letter of Credit Fee"
            name="loc_fee"
            value={form.loc_fee}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.loc_fee)}
            helperText={formErrors?.loc_fee}
            endAdornment={<>%</>}
            disabled={loading}
          />
        )}
        <AutocompleteField
          label="Interest Rate Curve"
          name="sofr_org_curve"
          onChange={handleSingleAutoCompleteChange}
          options={ircCurves?.length ? ircCurves : NO_CURVES}
          value={String(form.sofr_org_curve)}
          helperText={formErrors?.sofr_org_curve}
          onFocus={() => {
            clearSelectErrorOnFocus("sofr_org_curve");
          }}
          disabled={loading}
        />
        <CurrencyInput
          required
          label="Admin Agent Fees"
          name="admin_agent_fees"
          value={form.admin_agent_fees?.toString() || ""}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.admin_agent_fees)}
          helperText={formErrors?.admin_agent_fees}
          disabled={loading}
        />
        <CheckboxInput
          label="Iteratively update Deal FMV to solve for IDC and Construction Fees (will 2X Runtime)"
          checked={form.solve_iteratively}
          onChange={(e) =>
            setForm((prev) => ({
              ...prev,
              solve_iteratively: e.target.checked,
            }))
          }
          disabled={loading}
        />
      </>
    </Modal>
  );
}
