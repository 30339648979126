import React from "react";

export default function FormColumnLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length === 0) {
    return null;
  }

  if (childrenArray.length === 1) {
    return <div className="!grid !min-w-[450px]">{children}</div>;
  }

  return (
    <div className="!grid lg:!grid-cols-2 !gap-4 !w-full">
      {childrenArray.map((child, index) => (
        <div key={index} className="!min-w-[450px]">
          {child}
        </div>
      ))}
    </div>
  );
}
