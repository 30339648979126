import { cn } from "../../utils/helpers";

export type ChipProps = {
  label: string;
  color: string;
  variant: "outlined" | "filled";
  className?: string;
  filledBackgroundColor?: string;
};

const Chip = ({
  label,
  color,
  variant,
  className,
  filledBackgroundColor,
}: ChipProps) => {
  return (
    <div
      style={
        variant === "outlined"
          ? {
              border: `1px solid ${color}`,
              color: color,
            }
          : {
              backgroundColor: filledBackgroundColor ?? color,
              color: color,
            }
      }
      className={cn(
        "rounded-full px-2 py-1 text-sm flex items-center justify-center",
        className,
      )}
    >
      {label}
    </div>
  );
};

export default Chip;
