import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import Select from "../select-input";
import TextInput from "../text-input";
import AutocompleteField from "../autocomplete-field";
import MultiAutocompleteField from "../multi-autocomplete-field";
import {
  DEAL_STAGES_OPTIONS,
  DEAL_STRUCTURE_TYPE_OPTIONS,
  DEAL_DEVELOPER_HORIZON_TYPE_OPTIONS,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS,
  DEAL_FORM_DEFAULT_STATE,
  DEAL_STATUS_OPTIONS,
  CREATION_DEAL_STATUS_OPTIONS,
  DEAL_STATUS,
} from "../../constants";
import {
  IAddDealForm,
  ISelectOption,
  SetStateAction,
  IAddDealFormErrors,
  IAddDealResponse,
} from "../../interfaces";

interface IProps {
  open: boolean;
  loading: boolean;
  form: IAddDealForm;
  setForm: SetStateAction<IAddDealForm>;
  existingProjects: ISelectOption[];
  formErrors?: IAddDealFormErrors;
  setFormErrors: SetStateAction<IAddDealFormErrors | undefined>;
  onClose: () => void;
  onAdd: (form: IAddDealForm) => Promise<IAddDealResponse | undefined>;
  openAddProjectModal: () => void;
}

export default function AddDealFormModal({
  open,
  loading,
  form,
  existingProjects,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onAdd,
  openAddProjectModal,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleChangeDealInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | ISelectOption[] | null,
    name: string,
  ) => {
    if (Array.isArray(value)) {
      const v = value?.map((o: ISelectOption) => o.value);
      setForm((prev) => {
        return {
          ...prev,
          [name]: v as Array<string>,
        };
      });
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleSelectInputChangeChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleStatusChange = (e: SelectChangeEvent<unknown>) => {
    handleSelectInputChangeChange(e, "status");

    if (e.target.value === "ACT") {
      const filteredSelectedProducts = form.project_ids.filter(
        (project_id) =>
          existingProjects.findIndex(
            (project) => project.value == project_id && project.status == "ACT",
          ) !== -1,
      );

      setForm((prev) => {
        return {
          ...prev,
          project_ids: filteredSelectedProducts,
        };
      });
    }
  };

  const handleOnAddDeal = async () => {
    const deal = await onAdd(form);
    deal && handleOnClose();
  };

  const handleResetFprm = () => {
    setForm(DEAL_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  const filteredExistingProjects: ISelectOption[] = useMemo(() => {
    if (form.status === "ACT") {
      return existingProjects.filter((project) => project.status === "ACT");
    }
    return existingProjects;
  }, [form.status, existingProjects]);

  return (
    <Modal
      maxWidth="xs"
      heading="Add a new Deal"
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnAddDeal}
      resetForm={handleResetFprm}
    >
      <Box>
        <TextInput
          required
          label="Deal Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleChangeDealInfo}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
          fullWidth={false}
        />
        <AutocompleteField
          label="Deal Stage"
          name="stage"
          onChange={handleSingleAutoCompleteChange}
          options={DEAL_STAGES_OPTIONS}
          value={String(form.stage)}
          helperText={formErrors?.stage}
          onFocus={() => clearSelectErrorOnFocus("stage")}
          disabled={loading}
          fullWidth={false}
        />
        <Select
          required
          label="Deal Structure"
          selected={form.structure}
          items={DEAL_STRUCTURE_TYPE_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("structure")}
          onChange={(e) => handleSelectInputChangeChange(e, "structure")}
          error={Boolean(formErrors?.structure)}
          helperText={formErrors?.structure}
          disabled={loading}
          tooltip="<b>Tax Equity Partnership</b> = Tax Equity (Investor) + Sponsor Equity \n<b>Cash Equity Partnership</b> = Cash Equity (Investor) + Sponsor Equity \n<b>Equity Only</b> = Sponsor Equity \n<b>Sale-Leaseback</b> = Lessor Equity and Lessee Equity"
          fullWidth={false}
        />
        <Select
          required
          label="Deal Tax Credit Structure"
          selected={form.tax_credit_structure}
          items={DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("tax_credit_structure")}
          onChange={(e) =>
            handleSelectInputChangeChange(e, "tax_credit_structure")
          }
          error={Boolean(formErrors?.tax_credit_structure)}
          helperText={formErrors?.tax_credit_structure}
          disabled={loading}
          tooltip="<b>Regular</b> = No changes to tax credit election \n<b>Transfer</b> =  Section 6418 transferability \n<b>Direct Pay</b> =  Section 6417 elective pay"
          fullWidth={false}
        />
        <Select
          required
          label="Deal Developer Horizon"
          selected={form.developer_horizon}
          items={DEAL_DEVELOPER_HORIZON_TYPE_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("developer_horizon")}
          onChange={(e) =>
            handleSelectInputChangeChange(e, "developer_horizon")
          }
          error={Boolean(formErrors?.developer_horizon)}
          helperText={formErrors?.developer_horizon}
          disabled={loading}
          fullWidth={false}
        />
        <MultiAutocompleteField
          label="Select Projects"
          name="project_ids"
          onChange={handleAutoCompleteChange}
          options={filteredExistingProjects}
          value={form.project_ids}
          helperText={formErrors?.project_ids}
          firstItem={{
            label: "Add Project",
            onClick: openAddProjectModal,
            value: "add",
          }}
          onFocus={() => clearSelectErrorOnFocus("project_ids")}
          disabled={loading}
          fullWidth={false}
        />
        <Select
          required
          label="Status"
          selected={form.status}
          items={CREATION_DEAL_STATUS_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("status")}
          onChange={handleStatusChange}
          error={Boolean(formErrors?.status)}
          helperText={formErrors?.status}
          disabled={loading}
          fullWidth={false}
        />
      </Box>
    </Modal>
  );
}
