import DashboardPage from "../../pages/home/dashboard";
import RecentPage from "../../pages/home/recent";
import DealListPage from "../../pages/deal-list";
import ProjectListPage from "../../pages/project-list";

// configuration pages
import ConfigurationDataCurvesPage from "../../pages/configuration/data/curves";
import ConfigurationOrganizationProfilePage from "../../pages/configuration/organization/profile";
import ConfigurationOrganizationUsersPage from "../../pages/configuration/organization/users";
import ConfigurationOrganizationBillingAndUsagePage from "../../pages/configuration/organization/billing-and-usage";

import WorkflowDataRoomPage from "../../pages/workflows/data-room";
import WorkflowDocumentsPage from "../../pages/workflows/documents";
import WorkflowMarketingMaterialPage from "../../pages/workflows/marketing-material";
import WorkflowModelPage from "../../pages/workflows/model";
import WorkflowNDAPage from "../../pages/workflows/n-d-a";
import WorkflowThirdPartyReportsPage from "../../pages/workflows/third-party-reports";
import WorkflowTimelinePage from "../../pages/workflows/timeline";
import WorkflowWorkingGroupListPage from "../../pages/workflows/working-group-list";

import CozePage from "../../pages/co-ze";

// deal access page
import DealAccessPage from "../../pages/deal/access";

// deal cases pages
import DealCasesPage from "../../pages/deal/cases";
import DealCaseDetailsPage from "../../pages/deal/cases/details";

// deal output pages
import DealOutput from "../../pages/deal/output";

import DealOutputTransfer from "../../pages/deal/output/transfer";
import DealOutputTransferSummary from "../../pages/deal/output/transfer/summary";
import DealOutputTransferCash from "../../pages/deal/output/transfer/cash";

import DealOutputDebt from "../../pages/deal/output/debt";
import DealOutputDebtFacility from "../../pages/deal/output/debt/facility";
import DealOutputDebtCoverage from "../../pages/deal/output/debt/coverage";
import DealOutputDebtSummary from "../../pages/deal/output/debt/summary";
import DealOutputDebtCharts from "../../pages/deal/output/debt/charts";
import DealOutputDebtServiceCoverage from "../../pages/deal/output/debt/service-coverage";

import DealOutputPartnership from "../../pages/deal/output/partnership";
import DealOutputPartnershipCash from "../../pages/deal/output/partnership/cash";
import DealOutputPartnershipTax from "../../pages/deal/output/partnership/tax";
import DealOutputPartnershipSummary from "../../pages/deal/output/partnership/summary";
import DealOutputPartnershipSplits from "../../pages/deal/output/partnership/splits";

import DealOutputSponsorEquity from "../../pages/deal/output/sponsor-equity";
import DealOutputSponsorEquityCash from "../../pages/deal/output/sponsor-equity/cash";
import DealOutputSponsorEquitySummary from "../../pages/deal/output/sponsor-equity/summary";
import DealOutputSponsorEquityTax from "../../pages/deal/output/sponsor-equity/tax";
import DealOutputSponsorEquityAccounting from "../../pages/deal/output/sponsor-equity/accounting";
import DealOutputSponsorEquityCapitalAccounts from "../../pages/deal/output/sponsor-equity/capital-accounts";
import DealOutputSponsorEquityOutsideBasis from "../../pages/deal/output/sponsor-equity/outside-basis";
import DealOutputSponsorCharts from "../../pages/deal/output/sponsor-equity/charts";

import DealOutputSponsorEquityBOL_Page from "../../pages/deal/output/sponsor-equity-BOL";
import DealOutputSponsorEquityBOL_SummaryPage from "../../pages/deal/output/sponsor-equity-BOL/summary";
import DealOutputSponsorEquityBOL_CashPage from "../../pages/deal/output/sponsor-equity-BOL/cash";
import DealOutputSponsorEquityBOL_TaxPage from "../../pages/deal/output/sponsor-equity-BOL/tax";

import DealOutputDeveloperPage from "../../pages/deal/output/developer";
import DealOutputDeveloperSummaryPage from "../../pages/deal/output/developer/summary";
import DealOutputDeveloperCashPage from "../../pages/deal/output/developer/cash";
import DealOutputDeveloperTaxPage from "../../pages/deal/output/developer/tax";

import DealOutputTaxEquity from "../../pages/deal/output/tax-equity";
import DealOutputTaxEquityCash from "../../pages/deal/output/tax-equity/cash";
import DealOutputTaxEquitySummary from "../../pages/deal/output/tax-equity/summary";
import DealOutputTaxEquityTax from "../../pages/deal/output/tax-equity/tax";
import DealOutputTaxEquityCapitalAccounts from "../../pages/deal/output/tax-equity/capital-accounts";
import DealOutputTaxEquityOutsideBasis from "../../pages/deal/output/tax-equity/outside-basis";
import DealOutputTaxEquityAccounting from "../../pages/deal/output/tax-equity/accounting";
import DealOutputTaxCharts from "../../pages/deal/output/tax-equity/charts";

import DealOutputLeasePage from "../../pages/deal/output/lease";
import DealOutputLeaseSummaryPage from "../../pages/deal/output/lease/summary";
import DealOutputLessorPage from "../../pages/deal/output/lessor";
import DealOutputLessee from "../../pages/deal/output/lessee";
import DealOutputLessorSummary from "../../pages/deal/output/lessor/summary";
import DealOutputLessorCash from "../../pages/deal/output/lessor/cash";
import DealOutputLessorTax from "../../pages/deal/output/lessor/tax";
import DealOutputLesseeSummary from "../../pages/deal/output/lessee/summary";
import DealOutputLesseeCash from "../../pages/deal/output/lessee/cash";
import DealOutputLesseeTax from "../../pages/deal/output/lessee/tax";

import DealOutputConstructionDebtCashPage from "../../pages/deal/output/construction-debt/cash";
import DealOutputConstructionDebtChartPage from "../../pages/deal/output/construction-debt/chart";
import DealOutputConstructionDebtConstructionLoanPage from "../../pages/deal/output/construction-debt/construction-loan";
import DealOutputConstructionDebtEquityPage from "../../pages/deal/output/construction-debt/equity";
import DealOutputConstructionDebtInvestorBridgeLoanPage from "../../pages/deal/output/construction-debt/investor-bridge-loan";
import DealOutputConstructionDebtPage from "../../pages/deal/output/construction-debt";
import DealOutputConstructionDebtSummaryPage from "../../pages/deal/output/construction-debt/summary";
import DealOutputConstructionDebtTransferBridgeLoanPage from "../../pages/deal/output/construction-debt/transfer-bridge-loan";

import DealOutputPortfolioPage from "../../pages/deal/output/portfolio";
import DealOutputPortfolioSummaryPage from "../../pages/deal/output/portfolio/summary";

// deal analysis pages
import DealAnalysisPage from "../../pages/deal/analysis";
import DealCaseComparisonDetailsPage from "../../pages/deal/analysis/details";

// deal general pages
import DealGeneralPage from "../../pages/deal/general";

// deal timing pages
import DealTimingPage from "../../pages/deal/timing";

// deal components pages
import DealComponentCostPage from "../../pages/deal/components/cost";
import DealComponentDepreciationPage from "../../pages/deal/components/depreciation";
import DealTaxCredit from "../../pages/deal/components/tax-credits";
import DealComponentProforma from "../../pages/deal/components/pro-forma";

//  deal sizing pages
import DealSizingPage from "../../pages/deal/sizing/index";
import DealSizingTermDebtPage from "../../pages/deal/sizing/term-debt";
import DealSizingConstructionDebtPage from "../../pages/deal/sizing/construction-debt";
import DealSizingEquityPage from "../../pages/deal/sizing/cash-equity";
import DealSizingDirectPayPage from "../../pages/deal/sizing/direct-pay";
import DealSizingSponsorEquityPage from "../../pages/deal/sizing/sponsor-equity";
import DealSizingTaxEquityPage from "../../pages/deal/sizing/tax-equity";
import DealSizingTransferPage from "../../pages/deal/sizing/transfer";
import DealSizingSaleLeasebackPage from "../../pages/deal/sizing/sale-leaseback";
import DealSizingOtherPage from "../../pages/deal/sizing/other";

// deal comparison pages
import DealComparisonListPage from "../../pages/analysis/deal-comparison-list";
import DealComparisonDetailPage from "../../pages/analysis/deal-comparison-detail";

// project pages
import ProjectCostPage from "../../pages/project/cost/dashboard";
import ProjectCostInputsPage from "../../pages/project/cost/cost-inputs";
import ProjectCostInputDetailPage from "../../pages/project/cost/cost-inputs/detail";
//import ProjectValuationPage from "../../pages/project/valuation";

import ProjectDepreciationListPage from "../../pages/project/tax/depreciation/list";
import ProjectDepreciationDetailsPage from "../../pages/project/tax/depreciation/details";
import ProjectExpenseTablePage from "../../pages/project/pro-forma/expenses/table";
import ProjectExpenseDetailsPage from "../../pages/project/pro-forma/expenses/details";
import ProjectGeneralPage from "../../pages/project/general";
import ProjectEbitdaPage from "../../pages/project/pro-forma/revenue/ebitda";
import ProjectOtherFlowPage from "../../pages/project/pro-forma/other-flows/list";
import ProjectOtherFlowDetailPage from "../../pages/project/pro-forma/other-flows/details";
import ProjectProductionPage from "../../pages/project/pro-forma/production";
import ProjectRevenueListPage from "../../pages/project/pro-forma/revenue/revenue-contract/list";
import ProjectRevenueDetailsPage from "../../pages/project/pro-forma/revenue/revenue-contract/details";
import ProjectMerchantRevenueListPage from "../../pages/project/pro-forma/revenue/merchant-revenue/list";
import ProjectMerchantRevenueDetailsPage from "../../pages/project/pro-forma/revenue/merchant-revenue/merchant-revenue-details";
import ProjectTaxCreditPage from "../../pages/project/tax/tax-credit";
import ProjectValuationSummaryPage from "../../pages/project/valuation/summary";
import ProjectValuationCalculationPage from "../../pages/project/valuation/calculation";
import ProjectTimingPage from "../../pages/project/timing";
import ProjectProFormaDashboardPage from "../../pages/project/pro-forma/dashboard";

// sign-in
import SignInPage from "../../pages/sign-in";

// 404
import NotFound404 from "../../pages/not-fount-404";
import DealFeePage from "../../pages/deal/sizing/deal-fee/table";
import DealFeeDetails from "../../pages/deal/sizing/deal-fee/details";
import ProjectCostCreditSupportPage from "../../pages/project/cost/credit-support/list";
import ProjectCostCreditSupportDetailPage from "../../pages/project/cost/credit-support/details";

export const privateRoutes = [
  { path: "/", element: DashboardPage },
  { path: "/recent", element: RecentPage },
  { path: "/deal-list", element: DealListPage },
  { path: "/project-list", element: ProjectListPage },
  { path: "/workflow/data-room", element: WorkflowDataRoomPage },
  { path: "/workflow/documents", element: WorkflowDocumentsPage },
  {
    path: "/workflow/marketing-material",
    element: WorkflowMarketingMaterialPage,
  },
  { path: "/workflow/model", element: WorkflowModelPage },
  { path: "/workflow/n-d-a", element: WorkflowNDAPage },
  {
    path: "/workflow/third-party-reports",
    element: WorkflowThirdPartyReportsPage,
  },
  { path: "/workflow/timeline", element: WorkflowTimelinePage },
  {
    path: "/workflow/working-group-list",
    element: WorkflowWorkingGroupListPage,
  },
  { path: "/coze", element: CozePage },

  // configuration pages
  {
    path: "/configuration/data/curves",
    element: ConfigurationDataCurvesPage,
  },
  {
    path: "/configuration/data/curves/:curveGroupId/:curveId",
    element: ConfigurationDataCurvesPage,
  },
  {
    path: "/configuration/organization/profile",
    element: ConfigurationOrganizationProfilePage,
  },
  {
    path: "/configuration/organization/users",
    element: ConfigurationOrganizationUsersPage,
  },
  {
    path: "/configuration/organization/billing-and-usage",
    element: ConfigurationOrganizationBillingAndUsagePage,
  },

  // deal general pages
  {
    path: "/deal/:dealId/case-deal/:caseDealId/general",
    element: DealGeneralPage,
  },

  // deal timing pages
  {
    path: "/deal/:dealId/case-deal/:caseDealId/timing",
    element: DealTimingPage,
  },

  // deal components pages
  {
    path: "/deal/:dealId/case-deal/:caseDealId/component/cost",
    element: DealComponentCostPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/component/depreciation",
    element: DealComponentDepreciationPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/component/tax-credits",
    element: DealTaxCredit,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/component/pro-forma",
    element: DealComponentProforma,
  },

  // deal sizing pages
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing",
    element: DealSizingPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/tax-equity",
    element: DealSizingTaxEquityPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/transfer",
    element: DealSizingTransferPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/term-debt",
    element: DealSizingTermDebtPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/construction-debt",
    element: DealSizingConstructionDebtPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/cash-equity",
    element: DealSizingEquityPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/sale-leaseback",
    element: DealSizingSaleLeasebackPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/other",
    element: DealSizingOtherPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/deal-fee",
    element: DealFeePage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/deal-fee/:dealFeeId",
    element: DealFeeDetails,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/direct-pay",
    element: DealSizingDirectPayPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/sizing/sponsor-equity",
    element: DealSizingSponsorEquityPage,
  },

  // deal output pages
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output",
    element: DealOutput,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/transfer",
    element: DealOutputTransfer,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/transfer/cash",
    element: DealOutputTransferCash,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/transfer/summary",
    element: DealOutputTransferSummary,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/term-debt",
    element: DealOutputDebt,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/term-debt/facility",
    element: DealOutputDebtFacility,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/term-debt/coverage",
    element: DealOutputDebtCoverage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/term-debt/summary",
    element: DealOutputDebtSummary,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/term-debt/charts",
    element: DealOutputDebtCharts,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/term-debt/service-coverage",
    element: DealOutputDebtServiceCoverage,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/partnership",
    element: DealOutputPartnership,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/partnership/summary",
    element: DealOutputPartnershipSummary,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/partnership/cash",
    element: DealOutputPartnershipCash,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/partnership/tax",
    element: DealOutputPartnershipTax,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/partnership/splits",
    element: DealOutputPartnershipSplits,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity",
    element: DealOutputSponsorEquity,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity/cash",
    element: DealOutputSponsorEquityCash,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity/summary",
    element: DealOutputSponsorEquitySummary,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity/tax",
    element: DealOutputSponsorEquityTax,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity/capital-accounts",
    element: DealOutputSponsorEquityCapitalAccounts,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity/outside-basis",
    element: DealOutputSponsorEquityOutsideBasis,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity/accounting",
    element: DealOutputSponsorEquityAccounting,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity/charts",
    element: DealOutputSponsorCharts,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity-bol",
    element: DealOutputSponsorEquityBOL_Page,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity-bol/summary",
    element: DealOutputSponsorEquityBOL_SummaryPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity-bol/cash",
    element: DealOutputSponsorEquityBOL_CashPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity-bol/tax",
    element: DealOutputSponsorEquityBOL_TaxPage,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/developer",
    element: DealOutputDeveloperPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/developer/summary",
    element: DealOutputDeveloperSummaryPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/developer/cash",
    element: DealOutputDeveloperCashPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/developer/tax",
    element: DealOutputDeveloperTaxPage,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity",
    element: DealOutputTaxEquity,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity/cash",
    element: DealOutputTaxEquityCash,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity/summary",
    element: DealOutputTaxEquitySummary,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity/tax",
    element: DealOutputTaxEquityTax,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity/capital-accounts",
    element: DealOutputTaxEquityCapitalAccounts,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity/outside-basis",
    element: DealOutputTaxEquityOutsideBasis,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity/accounting",
    element: DealOutputTaxEquityAccounting,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity/charts",
    element: DealOutputTaxCharts,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lease",
    element: DealOutputLeasePage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lease/summary",
    element: DealOutputLeaseSummaryPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lessor",
    element: DealOutputLessorPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lessor/summary",
    element: DealOutputLessorSummary,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lessor/cash",
    element: DealOutputLessorCash,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lessor/tax",
    element: DealOutputLessorTax,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lessee",
    element: DealOutputLessee,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lessee/summary",
    element: DealOutputLesseeSummary,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lessee/cash",
    element: DealOutputLesseeCash,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/lessee/tax",
    element: DealOutputLesseeTax,
  },

  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt",
    element: DealOutputConstructionDebtPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt/summary",
    element: DealOutputConstructionDebtSummaryPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt/cash",
    element: DealOutputConstructionDebtCashPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt/equity",
    element: DealOutputConstructionDebtEquityPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt/construction-loan",
    element: DealOutputConstructionDebtConstructionLoanPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt/investor-bridge-loan",
    element: DealOutputConstructionDebtInvestorBridgeLoanPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt/transfer-bridge-loan",
    element: DealOutputConstructionDebtTransferBridgeLoanPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt/charts",
    element: DealOutputConstructionDebtChartPage,
  },

  // portfolio pages
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/portfolio",
    element: DealOutputPortfolioPage,
  },
  {
    path: "/deal/:dealId/case-deal/:caseDealId/output/portfolio/summary",
    element: DealOutputPortfolioSummaryPage,
  },

  // deal access page
  { path: "/deal/:dealId/access", element: DealAccessPage },

  // deal cases pages
  { path: "/deal/:dealId/cases", element: DealCasesPage },
  { path: "/deal/:dealId/case/:caseId", element: DealCaseDetailsPage },

  // deal analysis pages
  { path: "/deal/:dealId/analysis", element: DealAnalysisPage },
  {
    path: "/deal/:dealId/analysis/:id",
    element: DealCaseComparisonDetailsPage,
  },

  // deal comparison pages
  { path: "/analysis/deal-comparison-list", element: DealComparisonListPage },
  { path: "/analysis/deal-comparison/:id", element: DealComparisonDetailPage },

  // project pages
  { path: "/project/:projectId/general", element: ProjectGeneralPage },
  { path: "/project/:projectId/timing", element: ProjectTimingPage },
  { path: "/project/:projectId/cost", element: ProjectCostPage },
  {
    path: "/project/:projectId/cost-credit-support",
    element: ProjectCostCreditSupportPage,
  },
  {
    path: "/project/:projectId/cost-credit-support/:creditSupportId",
    element: ProjectCostCreditSupportDetailPage,
  },
  { path: "/project/:projectId/cost-inputs", element: ProjectCostInputsPage },
  {
    path: "/project/:projectId/cost-inputs/:costId/:costItemId",
    element: ProjectCostInputDetailPage,
  },

  {
    path: "/project/:projectId/pro-forma",
    element: ProjectProFormaDashboardPage,
  },
  {
    path: "/project/:projectId/pro-forma/production",
    element: ProjectProductionPage,
  },
  {
    path: "/project/:projectId/pro-forma/revenue",
    element: ProjectRevenueListPage,
  },
  {
    path: "/project/:projectId/pro-forma/revenue/:revenueId",
    element: ProjectRevenueDetailsPage,
  },
  {
    path: "/project/:projectId/pro-forma/revenue/merchant",
    element: ProjectMerchantRevenueListPage,
  },
  {
    path: "/project/:projectId/pro-forma/revenue/merchant/:revenueId",
    element: ProjectMerchantRevenueDetailsPage,
  },
  {
    path: "/project/:projectId/pro-forma/revenue/ebitda",
    element: ProjectEbitdaPage,
  },
  {
    path: "/project/:projectId/pro-forma/expenses",
    element: ProjectExpenseTablePage,
  },
  {
    path: "/project/:projectId/pro-forma/expenses/:expenseId",
    element: ProjectExpenseDetailsPage,
  },
  {
    path: "/project/:projectId/pro-forma/other-flows",
    element: ProjectOtherFlowPage,
  },
  {
    path: "/project/:projectId/pro-forma/other-flows/:id",
    element: ProjectOtherFlowDetailPage,
  },
  {
    path: "/project/:projectId/tax/depreciation",
    element: ProjectDepreciationListPage,
  },
  {
    path: "/project/:projectId/tax/depreciation/:depreciationId/stream/:streamId",
    element: ProjectDepreciationDetailsPage,
  },
  { path: "/project/:projectId/tax/tax-credit", element: ProjectTaxCreditPage },
  {
    path: "/project/:projectId/valuation",
    element: ProjectValuationSummaryPage,
  },
  {
    path: "/project/:projectId/valuation/calculation",
    element: ProjectValuationCalculationPage,
  },

  // 404
  { path: "*", element: NotFound404 },

  //sign-in
  { path: "/sign-in", element: SignInPage },
];
