import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import SchedulerInput from "../scheduler-input";
import CurrencyInput from "../currency-input";
import AutocompleteField from "../autocomplete-field";
import { cn } from "../../utils/helpers";
import { useAppSelector } from "../../utils/hooks";
import {
  PROJECT_ESCALATOR_BASE_YEAR_OPTIONS,
  PROJECT_EXPENSE_MONTHLY_ALLOCATION_TYPE_OPTIONS,
  PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES_OPTIONS,
  PROJECT_MERCHANT_PRICE_INPUT_TYPES_OPTIONS,
  PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE,
  PROJECT_MERCHANT_REVENUE_TYPE,
  PROJECT_MERCHANT_REVENUE_TYPE_OPTIONS,
  PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES_OPTIONS,
} from "../../constants";
import {
  ISelectOption,
  SetStateAction,
  IProjectMerchantRevenue,
  IProjectMerchantRevenueForm,
  IProjectMerchantRevenueFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  dateSchedule: string[];
  formErrors?: IProjectMerchantRevenueFormErrors;
  setFormErrors: SetStateAction<IProjectMerchantRevenueFormErrors | undefined>;
  form: IProjectMerchantRevenueForm;
  setForm: SetStateAction<IProjectMerchantRevenueForm>;
  onClose: () => void;
  onConfirm: (
    form: IProjectMerchantRevenueForm,
  ) => Promise<IProjectMerchantRevenue | undefined>;
  priceCurves: ISelectOption[];
  elccCurves: ISelectOption[];
}

const NO_CURVES = [{ label: "No Curves", value: "", disabled: true }];

export default function ProjectMerchantRevenueFormModal({
  open,
  headerLabel,
  loading,
  dateSchedule,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  priceCurves,
  elccCurves,
}: IProps): JSX.Element {
  const { currentProject } = useAppSelector((s) => s.project);

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field === "price_input_type") {
      form.price = null;
      form.price_curve = null;
    }
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleCurveChange = (
    value: (number | string | null)[],
    field: string,
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const merchantRevenue = await onConfirm(form);
    merchantRevenue && handleOnClose();
  };

  const getAllowedTypes = () => {
    const allowedTypesForBAST = [
      PROJECT_MERCHANT_REVENUE_TYPE.OTHER,
      PROJECT_MERCHANT_REVENUE_TYPE.CAPACITY,
    ];
    return currentProject?.type === "BAST"
      ? PROJECT_MERCHANT_REVENUE_TYPE_OPTIONS.filter((item) =>
          allowedTypesForBAST.includes(
            item.label as PROJECT_MERCHANT_REVENUE_TYPE,
          ),
        ).map((item) => {
          // Change label for Capacity to Merchant Capacity for Battery Storage
          if (item.label === PROJECT_MERCHANT_REVENUE_TYPE.CAPACITY) {
            return { ...item, label: "Merchant Capacity" };
          }
          return item;
        })
      : PROJECT_MERCHANT_REVENUE_TYPE_OPTIONS;
  };

  const handleResetForm = () => {
    setForm(PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
      classes={{ paper: cn("min-w-[500px]") }}
    >
      <Box>
        <SelectInput
          required
          label="Type"
          selected={form.type}
          items={getAllowedTypes()}
          onFocus={() => clearNonTextFieldErrorOnFocus("type")}
          onChange={(e) => handleSelectInputChange(e, "type")}
          error={Boolean(formErrors?.type)}
          helperText={formErrors?.type}
          disabled={loading}
          fullWidth={false}
        />
        <TextInput
          type="string"
          label="Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
          fullWidth={false}
        />

        {form.type !== "OTHER" ? (
          <>
            {form.type === "CAPACITY" ? (
              <SelectInput
                required
                label="Eligible Capacity Input Type"
                selected={form.eligible_capacity_input_type}
                items={PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("eligible_capacity_input_type")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "eligible_capacity_input_type")
                }
                error={Boolean(formErrors?.eligible_capacity_input_type)}
                helperText={formErrors?.eligible_capacity_input_type}
                disabled={loading}
                fullWidth={false}
              />
            ) : (
              ""
            )}
            {form.eligible_capacity_input_type === "FIXED" ? (
              <TextInput
                required
                isNumeric
                label="Eligible Capacity Percentage"
                name="eligible_capacity_percentage"
                endAdornment={<>%</>}
                value={form.eligible_capacity_percentage}
                onFocus={clearErrorOnFocus}
                onChange={handleTextChange}
                error={Boolean(formErrors?.eligible_capacity_percentage)}
                helperText={formErrors?.eligible_capacity_percentage}
                disabled={loading}
                tooltip="This is the eligible capacity of the project that is used for merchant revenue calculations"
                fullWidth={false}
              />
            ) : (
              ""
            )}
            {form.eligible_capacity_input_type === "CURVE" ? (
              <SchedulerInput
                label="Eligible Capacity Percentage Curve"
                name="eligible_capacity_percentage_curve"
                dateSchedule={dateSchedule || []}
                value={form?.eligible_capacity_percentage_curve || []}
                error={formErrors?.eligible_capacity_percentage_curve || ""}
                clearErrorOnFocus={clearErrorOnFocus}
                onChange={(v) =>
                  handleCurveChange(v, "eligible_capacity_percentage_curve")
                }
                disabled={loading}
              />
            ) : (
              ""
            )}
            {form.eligible_capacity_input_type === "OC" && (
              <AutocompleteField
                label="Organization Curve"
                name="eligible_capacity_org_curve"
                onChange={handleSingleAutoCompleteChange}
                options={elccCurves?.length ? elccCurves : NO_CURVES}
                value={String(form.eligible_capacity_org_curve)}
                helperText={formErrors?.eligible_capacity_org_curve}
                onFocus={() => {
                  clearSelectErrorOnFocus("eligible_capacity_org_curve");
                }}
                disabled={loading}
                fullWidth={false}
                tooltip={
                  <Link
                    target="_blank"
                    className={cn("underline")}
                    to="/configuration/data/curves"
                  >
                    Go to curves list
                  </Link>
                }
              />
            )}

            <SelectInput
              required
              label="Price Input Type"
              selected={form.price_input_type}
              items={PROJECT_MERCHANT_PRICE_INPUT_TYPES_OPTIONS}
              onFocus={() => clearNonTextFieldErrorOnFocus("price_input_type")}
              onChange={(e) => handleSelectInputChange(e, "price_input_type")}
              error={Boolean(formErrors?.price_input_type)}
              helperText={formErrors?.price_input_type}
              disabled={loading}
              fullWidth={false}
            />

            {form.price_input_type === "FIXED" && (
              <CurrencyInput
                required
                label="Price"
                name="price"
                endAdornment={
                  form.type === "CAPACITY" ? <> /kW-Month</> : <>/MWh</>
                }
                value={form.price?.toString() || ""}
                onFocus={clearErrorOnFocus}
                onChange={handleTextChange}
                error={Boolean(formErrors?.price)}
                helperText={formErrors?.price}
                disabled={loading}
                fullWidth={false}
              />
            )}

            {form.price_input_type === "OC" && (
              <AutocompleteField
                label="Organization Curve"
                name="org_curve"
                onChange={handleSingleAutoCompleteChange}
                options={priceCurves?.length ? priceCurves : NO_CURVES}
                value={String(form.org_curve)}
                helperText={formErrors?.org_curve}
                onFocus={() => {
                  clearSelectErrorOnFocus("org_curve");
                }}
                disabled={loading}
                fullWidth={false}
                tooltip={
                  <Link
                    target="_blank"
                    className={cn("underline")}
                    to="/configuration/data/curves"
                  >
                    Go to curves list
                  </Link>
                }
              />
            )}

            <TextInput
              required
              isNumeric
              label="Escalator (Calendar Year)"
              name="escalator"
              endAdornment={<>%</>}
              value={form.escalator}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.escalator)}
              helperText={formErrors?.escalator}
              disabled={loading}
              fullWidth={false}
            />
            <SelectInput
              required
              label="Escalator Base Year"
              selected={String(form.base_year)}
              items={PROJECT_ESCALATOR_BASE_YEAR_OPTIONS}
              onFocus={() => clearNonTextFieldErrorOnFocus("base_year")}
              onChange={(e) => handleSelectInputChange(e, "base_year")}
              error={Boolean(formErrors?.base_year)}
              helperText={formErrors?.base_year}
              disabled={loading}
              fullWidth={false}
            />
            <TextInput
              required
              isNumeric
              label="Price Haircut"
              name="price_haircut"
              endAdornment={<>%</>}
              value={form.price_haircut}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.price_haircut)}
              helperText={formErrors?.price_haircut}
              disabled={loading}
              fullWidth={false}
            />

            <TextInput
              required
              isNumeric
              label="Cash Lag"
              name="cash_basis_lag"
              endAdornment={<>Months</>}
              value={form.cash_basis_lag}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.cash_basis_lag)}
              helperText={formErrors?.cash_basis_lag}
              disabled={loading}
              fullWidth={false}
            />

            {form.price_input_type === "CURVE" ? (
              <SchedulerInput
                label={`Price Curve ${
                  form.type === "CAPACITY" ? "$/kW-Month" : "$/MWh"
                }`}
                name="price_curve"
                dateSchedule={dateSchedule || []}
                value={form?.price_curve || []}
                error={formErrors?.price_curve || ""}
                clearErrorOnFocus={clearErrorOnFocus}
                onChange={(v) => handleCurveChange(v, "price_curve")}
                formatValue
                disabled={loading}
                startAdornment="$"
                endAdornment={`${
                  form.type === "CAPACITY" ? " /kW-Month" : "/MWh"
                }`}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <TextInput
              required
              isNumeric
              label="Price Haircut"
              name="price_haircut"
              endAdornment={<>%</>}
              value={form.price_haircut}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.price_haircut)}
              helperText={formErrors?.price_haircut}
              disabled={loading}
              fullWidth={false}
            />
            <TextInput
              required
              isNumeric
              label="Cash Lag"
              name="cash_basis_lag"
              endAdornment={<>Months</>}
              value={form.cash_basis_lag}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.cash_basis_lag)}
              helperText={formErrors?.cash_basis_lag}
              disabled={loading}
              fullWidth={false}
            />
            <SchedulerInput
              label="Revenue Schedule"
              name="revenue_curve"
              dateSchedule={dateSchedule || []}
              value={form?.revenue_curve || []}
              error={formErrors?.revenue_curve || ""}
              clearErrorOnFocus={clearErrorOnFocus}
              onChange={(v) => handleCurveChange(v, "revenue_curve")}
              disabled={loading}
              formatValue
            />
          </>
        )}
        <SelectInput
          required
          label="Monthly Allocation Type"
          selected={String(form.monthly_allocation_type)}
          items={PROJECT_EXPENSE_MONTHLY_ALLOCATION_TYPE_OPTIONS}
          onFocus={() =>
            clearNonTextFieldErrorOnFocus("monthly_allocation_type")
          }
          onChange={(e) =>
            handleSelectInputChange(e, "monthly_allocation_type")
          }
          error={Boolean(formErrors?.monthly_allocation_type)}
          helperText={formErrors?.monthly_allocation_type}
          disabled={loading}
          fullWidth={false}
        />
        {form.type === "ENERGY" && (
          <>
            <SelectInput
              required
              label="Basis Differential Method"
              selected={form.differential_method || ""}
              items={PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES_OPTIONS}
              onFocus={() =>
                clearNonTextFieldErrorOnFocus("differential_method")
              }
              onChange={(e) =>
                handleSelectInputChange(e, "differential_method")
              }
              error={Boolean(formErrors?.differential_method)}
              helperText={formErrors?.differential_method}
              disabled={loading}
              fullWidth={false}
            />
            {form.differential_method === "BDP" && (
              <CurrencyInput
                required
                label="Basis Differential"
                name="basis_differential"
                endAdornment={<>/MWh</>}
                value={form.basis_differential?.toString() || ""}
                onFocus={clearErrorOnFocus}
                onChange={handleTextChange}
                error={Boolean(formErrors?.basis_differential)}
                helperText={formErrors?.basis_differential}
                disabled={loading}
                fullWidth={false}
              />
            )}
            {form.differential_method === "BDC" && (
              <SchedulerInput
                label="Basis Curve"
                name="basis_curve"
                dateSchedule={dateSchedule || []}
                value={form?.basis_curve || []}
                error={formErrors?.basis_curve || ""}
                clearErrorOnFocus={clearErrorOnFocus}
                onChange={(v) => handleCurveChange(v, "basis_curve")}
                disabled={loading}
                startAdornment="$"
                endAdornment=" / MWh"
              />
            )}
          </>
        )}
      </Box>
    </Modal>
  );
}
