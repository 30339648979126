import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    emptyContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 345px)",
      textAlign: "center",
    },
    createBtn: {
      height: 50,
      width: 185,
      fontSize: 16,
    },
    createInfo: {
      display: "block",
      marginTop: 20,
      backgroundColor: selectedTheme.color.aboveSurface,
      padding: theme.spacing(1, 2),
      borderRadius: 20,
      color: "#666",
    },
  };
});
