import React from "react";
import { Link } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";

import TextInput from "../text-input";
import SelectInput from "../select-input";
import DatePicker from "../date-picker";
import AutocompleteField from "../autocomplete-field";
import { cn } from "../../utils/helpers";
import {
  ISelectOption,
  IDealTermDebtForm,
  IDealTermDebtFormErrors,
} from "../../interfaces";
import {
  DEAL_TERM_DEBT_BASE_INPUT_TYPES_OPTIONS,
  DEAL_TERM_DEBT_PERIODICITY_OPTIONS,
  DEAL_TERM_DEBT_SIZING_TIMING_OPTIONS,
  DEAL_TERM_DEBT_SOLVE_FORS_OPTIONS,
  DEAL_TERM_TERM_DEBT_TYPES_OPTIONS,
} from "../../constants";

interface IParams {
  form: IDealTermDebtForm;
  formErrors?: IDealTermDebtFormErrors;
  clearNonTextFieldErrorOnFocus: (fieldName: string) => void;
  onSelectInputChange: (e: SelectChangeEvent<unknown>, value: string) => void;
  clearErrorOnFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDateChange: (v: Date | null, field: string) => void;
  onSingleAutoCompleteChange: (
    e: React.SyntheticEvent<Element, Event>,
    val: ISelectOption | null,
    name: string,
  ) => void;
  loading: boolean;
  ircCurves: ISelectOption[];
  debtFundingDate: {
    first_cod: string;
    last_cod: string;
  };
}

interface IElement {
  name: keyof IDealTermDebtForm;
  element: JSX.Element;
}

export interface IFields {
  base: IElement[];
  keyParameters: (IElement | null)[];
  additionalParameters: IElement[];
}

const NO_CURVES = [{ label: "No Curves", value: "", disabled: true }];

export const getDealDebtFields = ({
  form,
  formErrors,
  clearErrorOnFocus,
  clearNonTextFieldErrorOnFocus,
  onTextChange,
  onSelectInputChange,
  onDateChange,
  onSingleAutoCompleteChange,
  loading,
  ircCurves,
  debtFundingDate,
}: IParams): IFields => {
  const getSizingMethodOptions = () => {
    switch (form.type) {
      default: {
        return DEAL_TERM_DEBT_SOLVE_FORS_OPTIONS;
      }
    }
  };

  const getFundingDate = () => {
    switch (form.sizing_timing) {
      case "FCOD":
        return new Date(debtFundingDate.first_cod);
      case "LCOD":
        return new Date(debtFundingDate.last_cod);
      default:
        return new Date(form.funding_date);
    }
  };

  return {
    base: [
      {
        name: "type",
        element: (
          <SelectInput
            required
            label="Type"
            selected={form.type}
            items={DEAL_TERM_TERM_DEBT_TYPES_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("type")}
            onChange={(e) => onSelectInputChange(e, "type")}
            error={Boolean(formErrors?.type)}
            helperText={formErrors?.type}
            disabled={loading}
            tooltip="<b>Back-Leverage</b> = Sponsor Debt \n<b>Front-Leverage</b> = Project Debt"
            fullWidth={false}
          />
        ),
      },
      {
        name: "solve_for",
        element: (
          <SelectInput
            required
            label="Solve for"
            selected={form.solve_for}
            items={getSizingMethodOptions()}
            onFocus={() => clearNonTextFieldErrorOnFocus("solve_for")}
            onChange={(e) => onSelectInputChange(e, "solve_for")}
            error={Boolean(formErrors?.solve_for)}
            helperText={formErrors?.solve_for}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
    ],
    keyParameters: [
      {
        name: "sizing_timing",
        element: (
          <SelectInput
            required
            label="Sizing Timing"
            name="sizing_timing"
            selected={form.sizing_timing}
            items={DEAL_TERM_DEBT_SIZING_TIMING_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("sizing_timing")}
            onChange={(e) => onSelectInputChange(e, "sizing_timing")}
            error={Boolean(formErrors?.sizing_timing)}
            helperText={formErrors?.sizing_timing}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "funding_date",
        element: (
          <DatePicker
            label="Debt Funding Date"
            value={getFundingDate()}
            onChange={(v) => onDateChange(v, "funding_date")}
            onOpen={() => clearNonTextFieldErrorOnFocus("funding_date")}
            error={Boolean(formErrors?.funding_date)}
            helperText={formErrors?.funding_date}
            disabled
            fullWidth={false}
          />
        ),
      },
      {
        name: "debt_term",
        element: (
          <TextInput
            required
            isNumeric
            label="Amortization Tenor"
            name="debt_term"
            endAdornment={<>Yrs</>}
            value={form.debt_term ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.debt_term)}
            helperText={formErrors?.debt_term}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "payment_periodicity",
        element: (
          <SelectInput
            required
            label="Payment Periodicity"
            name="payment_periodicity"
            selected={form.payment_periodicity}
            items={DEAL_TERM_DEBT_PERIODICITY_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("payment_periodicity")}
            onChange={(e) => onSelectInputChange(e, "payment_periodicity")}
            error={Boolean(formErrors?.payment_periodicity)}
            helperText={formErrors?.payment_periodicity}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "base_input_type",
        element: (
          <SelectInput
            required
            label="Base Rate Type"
            name="base_input_type"
            selected={form.base_input_type}
            items={DEAL_TERM_DEBT_BASE_INPUT_TYPES_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("base_input_type")}
            onChange={(e) => onSelectInputChange(e, "base_input_type")}
            error={Boolean(formErrors?.base_input_type)}
            helperText={formErrors?.base_input_type}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      form.base_input_type === "RATE"
        ? {
            name: "base_rate",
            element: (
              <TextInput
                required
                isNumeric
                label="Base"
                name="base_rate"
                endAdornment={<>%</>}
                value={form.base_rate ?? ""}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.base_rate)}
                helperText={formErrors?.base_rate}
                disabled={loading}
                fullWidth={false}
              />
            ),
          }
        : null,
      form.base_input_type === "OC"
        ? {
            name: "base_rate_org_curve",
            element: (
              <AutocompleteField
                label="Interest Rate Curve"
                name="base_rate_org_curve"
                onChange={onSingleAutoCompleteChange}
                options={ircCurves?.length ? ircCurves : NO_CURVES}
                value={String(form.base_rate_org_curve)}
                helperText={formErrors?.base_rate_org_curve}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("base_rate_org_curve")
                }
                disabled={loading}
                fullWidth={false}
                tooltip={
                  <Link
                    target="_blank"
                    className={cn("underline")}
                    to="/configuration/data/curves"
                  >
                    Go to curves list
                  </Link>
                }
              />
            ),
          }
        : null,
      {
        name: "spread",
        element: (
          <TextInput
            required
            isNumeric
            label="Spread"
            name="spread"
            endAdornment={<>%</>}
            value={form.spread ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.spread)}
            helperText={formErrors?.spread}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "spread_step_up",
        element: (
          <TextInput
            required
            isNumeric
            label="Spread Step Up"
            name="spread_step_up"
            endAdornment={<>%</>}
            value={form.spread_step_up ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.spread_step_up)}
            helperText={formErrors?.spread_step_up}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "spread_step_up_frequency",
        element: (
          <TextInput
            required
            isNumeric
            label="Spread Step Up Frequency (Yrs)"
            name="spread_step_up_frequency"
            endAdornment={<>Yrs</>}
            value={form.spread_step_up_frequency ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.spread_step_up_frequency)}
            helperText={formErrors?.spread_step_up_frequency}
            disabled={loading}
            fullWidth={false}
            tooltip="Number of (operating) years before the spread steps up. It steps up then every $n years. If 0 then there is no step up."
          />
        ),
      },
      {
        name: "hedged_percentage",
        element: (
          <TextInput
            required
            isNumeric
            label="Hedged %"
            name="hedged_percentage"
            endAdornment={<>%</>}
            value={form.hedged_percentage ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.hedged_percentage)}
            helperText={formErrors?.hedged_percentage}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "swap_rate",
        element: (
          <TextInput
            required
            isNumeric
            label="Swap Rate"
            name="swap_rate"
            endAdornment={<>%</>}
            value={form.swap_rate ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.swap_rate)}
            helperText={formErrors?.swap_rate}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "swap_rate_credit_spread",
        element: (
          <TextInput
            required
            isNumeric
            label="Swap Rate Credit Spread"
            name="swap_rate_credit_spread"
            endAdornment={<>%</>}
            value={form.swap_rate_credit_spread ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.swap_rate_credit_spread)}
            helperText={formErrors?.swap_rate_credit_spread}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      // all fees hidden for now.
      // {
      //   name: "upfront_fees_percentage",
      //   element: (
      //     <TextInput
      //       required
      //       isNumeric
      //       label="Upfront Fee"
      //       name="upfront_fees_percentage"
      //       endAdornment={<>%</>}
      //       value={form.upfront_fees_percentage ?? ""}
      //       onFocus={clearErrorOnFocus}
      //       onChange={onTextChange}
      //       error={Boolean(formErrors?.upfront_fees_percentage)}
      //       helperText={formErrors?.upfront_fees_percentage}
      //       disabled={loading}
      //       fullWidth={false}
      //     />
      //   ),
      // },
      // {
      //   name: "lc_fees_percentage",
      //   element: (
      //     <TextInput
      //       required
      //       isNumeric
      //       label="LC Fees"
      //       name="lc_fees_percentage"
      //       endAdornment={<>%</>}
      //       value={form.lc_fees_percentage ?? ""}
      //       onFocus={clearErrorOnFocus}
      //       onChange={onTextChange}
      //       error={Boolean(formErrors?.lc_fees_percentage)}
      //       helperText={formErrors?.lc_fees_percentage}
      //       disabled={loading}
      //       fullWidth={false}
      //     />
      //   ),
      // },
      // {
      //   name: "admin_fees",
      //   element: (
      //     <TextInput
      //       required
      //       isNumeric
      //       label="Admin Fees"
      //       name="admin_fees"
      //       endAdornment={<>$</>}
      //       value={form.admin_fees ?? ""}
      //       onFocus={clearErrorOnFocus}
      //       onChange={onTextChange}
      //       error={Boolean(formErrors?.admin_fees)}
      //       helperText={formErrors?.admin_fees}
      //       disabled={loading}
      //       fullWidth={false}
      //     />
      //   ),
      // },
    ],
    additionalParameters: [
      {
        name: "contracted_cash_dscr",
        element: (
          <TextInput
            required
            isNumeric
            label="Contracted Cash DSCR"
            name="contracted_cash_dscr"
            endAdornment={<>x</>}
            value={form.contracted_cash_dscr ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.contracted_cash_dscr)}
            helperText={formErrors?.contracted_cash_dscr}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "uncontracted_cash_dscr",
        element: (
          <TextInput
            required
            isNumeric
            label="Uncontracted Cash DSCR"
            name="uncontracted_cash_dscr"
            endAdornment={<>x</>}
            value={form.uncontracted_cash_dscr ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.uncontracted_cash_dscr)}
            helperText={formErrors?.uncontracted_cash_dscr}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "special_cash_dscr",
        element: (
          <TextInput
            required
            isNumeric
            label="Special Cash DSCR"
            name="special_cash_dscr"
            endAdornment={<>x</>}
            value={form.special_cash_dscr ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.special_cash_dscr)}
            helperText={formErrors?.special_cash_dscr}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "transfer_cash_dscr",
        element: (
          <TextInput
            required
            isNumeric
            label="Transfer Cash DSCR"
            name="transfer_cash_dscr"
            endAdornment={<>x</>}
            value={form.transfer_cash_dscr ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.transfer_cash_dscr)}
            helperText={formErrors?.transfer_cash_dscr}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
    ],
  };
};
