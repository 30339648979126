import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";
import { format, isValid, startOfYear } from "date-fns";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import DatePicker from "../date-picker";
import SchedulerInput from "../scheduler-input";
import { cn } from "../../utils/helpers";
import {
  CURVE_TYPE,
  CURVE_TYPE_OPTIONS,
  GLOBAL_CURVE_TIMING_PERIODICITY_OPTIONS,
  PRICE_TERM_TYPE_OPTIONS,
} from "../../constants";
import {
  SetStateAction,
  IOrganizationCurve,
  ISelectOption,
  ICurveFormErrors,
  ICurveForm,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: ICurveFormErrors;
  setFormErrors: SetStateAction<ICurveFormErrors | undefined>;
  form: ICurveForm;
  setForm: SetStateAction<ICurveForm>;
  onClose: () => void;
  onConfirm: (form: ICurveForm) => Promise<IOrganizationCurve | undefined>;
  org_groups: ISelectOption[];
  date_schedule: string[] | null;
  loadingDateSchedule: boolean;
  allowEdit?: boolean;
  onAddGroup?: () => void;
}

const NO_GROUPS = [{ label: "No Groups", value: "", disabled: true }];

export default function CurveFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  org_groups,
  date_schedule,
  loadingDateSchedule,
  onAddGroup,
  allowEdit,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "term_years" && isValidForDateSchedule()) {
      toast.warn(
        "Changing this value will affect the dates for the Price Curve.",
        { toastId: "term-years-warning" },
      );
    }
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field === "periodicity" && isValidForDateSchedule()) {
      toast.warn(
        "Changing this value will affect the dates for the Price Curve.",
        { toastId: "periodicity-warning" },
      );
    }
    setForm((prev) => {
      return {
        ...prev,
        [field]: e.target.value,
      };
    });
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (field === "start_date" && isValidForDateSchedule()) {
      toast.warn(
        "Changing this value will affect the dates for the Price Curve.",
        { toastId: "start-date-warning" },
      );
    }
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const handleSchedulerInputChange = (value: (number | string | null)[]) => {
    setForm((prevState) => ({
      ...prevState,
      curve_data: value?.slice(0, date_schedule?.length), // truncating the curve if it is greater than date schedule length
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const updatedForm = { ...form };
    // setting price term as Real since we hide this field and it's a tag anyway
    if (updatedForm.curve_type === "IRC") {
      updatedForm.price_term = "RL";
    }
    if (form.periodicity === "AN") {
      updatedForm.start_date = format(
        startOfYear(new Date(updatedForm.start_date)),
        "MM/dd/yyyy",
      );
    }
    const curve = await onConfirm(updatedForm);
    curve && handleOnClose();
  };

  // truncating the price curve when date schedule changes
  React.useEffect(() => {
    if (
      form.curve_data &&
      date_schedule &&
      form.curve_data?.length > date_schedule?.length
    ) {
      handleSchedulerInputChange(form.curve_data);
    }
  }, [date_schedule]);

  const isValidForDateSchedule = () => {
    return (
      Boolean(form.periodicity) &&
      Boolean(form.start_date) &&
      Boolean(form.term_years.toString())
    );
  };

  const handleResetForm = () => {
    setForm({
      curve_group: "",
      curve_type: "",
      name: "",
      periodicity: "",
      price_term: "",
      start_date: "",
      term_years: "",
      curve_data: null,
    });
    setFormErrors({});
  };

  const hidePriceTerm = ["IRC", "ELCC"].includes(form.curve_type);

  return (
    <Modal
      open={open}
      form={form}
      heading={headerLabel}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      classes={{
        paper: cn("min-w-[50%]"), // wider
      }}
      resetForm={handleResetForm}
    >
      <Box className={cn("grid grid-cols-2 gap-4")}>
        <Box>
          {/* If not allowEdit then show the info message with icon */}
          {!allowEdit && (
            <Box className={cn("mb-4")}>
              <Alert severity="info">
                This curve is being used in one or more projects or deals, and
                hence only some fields are editable.
              </Alert>
            </Box>
          )}
          <TextInput
            required
            label="Name"
            name="name"
            value={form.name}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
          />
          <SelectInput
            required
            label="Curve Group"
            selected={String(form.curve_group)}
            items={org_groups?.length ? org_groups : NO_GROUPS}
            onFocus={() => clearNonTextFieldErrorOnFocus("curve_group")}
            onChange={(e) => handleSelectInputChange(e, "curve_group")}
            error={Boolean(formErrors?.curve_group)}
            helperText={formErrors?.curve_group}
            disabled={loading}
            firstItem={
              onAddGroup
                ? {
                    label: "Add Group",
                    value: "add-group",
                    onClick: onAddGroup,
                  }
                : undefined
            }
          />
          <SelectInput
            required
            label="Curve Type"
            selected={String(form.curve_type)}
            items={CURVE_TYPE_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("curve_type")}
            onChange={(e) => handleSelectInputChange(e, "curve_type")}
            error={Boolean(formErrors?.curve_type)}
            helperText={formErrors?.curve_type}
            disabled={loading || Boolean(!allowEdit)}
          />
          <SelectInput
            required
            label="Periodicity"
            selected={form.periodicity}
            items={GLOBAL_CURVE_TIMING_PERIODICITY_OPTIONS}
            onChange={(e) => handleSelectInputChange(e, "periodicity")}
            onFocus={() => clearNonTextFieldErrorOnFocus("periodicity")}
            error={Boolean(formErrors?.periodicity)}
            helperText={formErrors?.periodicity}
            disabled={loading || Boolean(!allowEdit)}
          />
          {!hidePriceTerm && (
            <SelectInput
              required
              label={form.curve_type === "PC" ? "Price Terms" : "Price Term"}
              selected={form.price_term}
              items={PRICE_TERM_TYPE_OPTIONS}
              onChange={(e) => handleSelectInputChange(e, "price_term")}
              onFocus={() => clearNonTextFieldErrorOnFocus("price_term")}
              error={Boolean(formErrors?.price_term)}
              helperText={formErrors?.price_term}
              disabled={loading || Boolean(!allowEdit)}
            />
          )}
          <DatePicker
            label={form.periodicity === "AN" ? "Start Year" : "Start Date"}
            value={form.start_date ? new Date(form.start_date) : null}
            onChange={(v) => handleDateChange(v, "start_date")}
            onOpen={() => clearNonTextFieldErrorOnFocus("start_date")}
            error={Boolean(formErrors?.start_date)}
            helperText={formErrors?.start_date}
            disabled={loading || Boolean(!allowEdit)}
            views={form.periodicity === "AN" ? ["year"] : ["year", "month"]}
            format={form.periodicity === "AN" ? "yyyy" : "M / yyyy"}
          />
          <TextInput
            required
            isNumeric
            label="Term"
            name="term_years"
            endAdornment={<>Yrs</>}
            value={form.term_years}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.term_years)}
            helperText={formErrors?.term_years}
            disabled={loading || Boolean(!allowEdit)}
          />
        </Box>
        <Box>
          {/* showing only when date_schedule is valid */}
          {date_schedule && date_schedule?.length !== 0 && (
            <SchedulerInput
              label={CURVE_TYPE[form.curve_type as keyof typeof CURVE_TYPE]}
              name="curve_data"
              dateSchedule={date_schedule || []}
              value={form?.curve_data || []}
              error={formErrors?.curve_data || ""}
              clearErrorOnFocus={clearErrorOnFocus}
              onChange={handleSchedulerInputChange}
              disabled={loading || loadingDateSchedule}
              fullWidth
              startAdornment={form.curve_type === "PC" ? "$" : ""}
              endAdornment={
                form.curve_type === "IRC" || form.curve_type === "ELCC"
                  ? "%"
                  : ""
              }
              showOnlyYear={form.periodicity === "AN"}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
}
