import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTableV2 from "../../../../../components/report-table-v2";
import { useAPI } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import {
  IPortfolioCostScheduleReport,
  IPortfolioCostSummaryReport,
} from "../../../../../interfaces";
import {
  cn,
  getPortfolioCostScheduleUrl,
  formatNumberWithDecimals,
  getPortfolioCostSummaryUrl,
} from "../../../../../utils/helpers";

interface IProps {
  getPortfolioCostScheduleData: (
    dealId: number,
  ) => Promise<IPortfolioCostScheduleReport>;
  getPortfolioCostSummaryData: (
    dealId: number,
  ) => Promise<IPortfolioCostSummaryReport>;
  downloadPortfolioCostScheduleData: (dealId: number) => Promise<void>;
}

export default function DealOutputPortfolioSummaryView({
  getPortfolioCostScheduleData,
  getPortfolioCostSummaryData,
  downloadPortfolioCostScheduleData,
}: IProps): JSX.Element {
  const { caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const {
    callAPI: getCostScheduleDataCallAPI,
    loading: loadingCostScheduleData,
    errored: getCostScheduleDataFailed,
    response: costScheduleData,
    showUsageLimitHitScreen,
  } = useAPI((id) => getPortfolioCostScheduleData(id));

  const {
    callAPI: getPortfolioCostSummaryDataCallAPI,
    loading: loadingCostSummaryData,
    errored: getCostSummaryDataFailed,
    response: portfolioCostSummaryData,
  } = useAPI((id) => getPortfolioCostSummaryData(id), {
    initialLoading: true,
  });

  React.useEffect(() => {
    getPortfolioCostSummaryDataCallAPI(Number(caseDealId));
  }, [caseDealId]);

  React.useEffect(() => {
    if (portfolioCostSummaryData) {
      getCostScheduleDataCallAPI(Number(caseDealId));
    }
  }, [caseDealId, portfolioCostSummaryData]);

  const { callAPI: downloadCostScheduleDataCallAPI } = useAPI(() =>
    downloadPortfolioCostScheduleData(Number(caseDealId)),
  );

  const ProjectCostSummary = ({
    costSummaryData,
  }: {
    costSummaryData: IPortfolioCostSummaryReport;
  }) => {
    // Extract unique project names to create columns, using project_id for mapping
    const projectMap = new Map<number, string>();
    costSummaryData.data.items.forEach((item) => {
      item.projects.forEach((project) => {
        if (!projectMap.has(project.project_id)) {
          projectMap.set(project.project_id, project.project_name || "");
        }
      });
    });

    const projectNames = Array.from(projectMap.values());

    return (
      <Paper classes={{ root: cn("p-4 flex flex-col gap-4 my-4") }}>
        <Typography variant="h6" fontWeight={"bold"}>
          Project Cost Summary
        </Typography>
        {costSummaryData?.data.items.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">Item</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Total</Typography>
                </TableCell>
                {projectNames.map((projectName, idx) => (
                  <TableCell key={idx}>
                    <Typography fontWeight="bold">{projectName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {costSummaryData.data.items.map((item, idx) => (
                <TableRow key={idx}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {formatNumberWithDecimals(item.total, 2)}
                  </TableCell>
                  {Array.from(projectMap.keys()).map((projectId, pIdx) => {
                    const project = item.projects.find(
                      (p) => p.project_id === projectId,
                    );
                    return (
                      <TableCell key={pIdx}>
                        {project
                          ? formatNumberWithDecimals(project.value, 2)
                          : "N/A"}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </Paper>
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingCostSummaryData}
        error={getCostSummaryDataFailed}
        showLimitHitView={showUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[getPortfolioCostSummaryUrl(Number(caseDealId))]
        }
      >
        {portfolioCostSummaryData ? (
          <ProjectCostSummary costSummaryData={portfolioCostSummaryData} />
        ) : (
          <Typography>No data available</Typography>
        )}
      </ViewWrapper>

      {portfolioCostSummaryData && (
        <ViewWrapper
          loading={loadingCostScheduleData}
          error={getCostScheduleDataFailed}
          showLimitHitView={showUsageLimitHitScreen}
          loadingPercentage={
            urlLoadingPercentages[
              getPortfolioCostScheduleUrl(Number(caseDealId))
            ]
          }
        >
          <Paper classes={{ root: cn("p-4") }}>
            {costScheduleData?.data.rows.length ? (
              <Box className={cn("flex flex-col gap-4")}>
                <Typography fontWeight={600}>Cost Schedule</Typography>
                <ReportTableV2
                  data={costScheduleData.data}
                  groupExpandedDepth={-1}
                  download={downloadCostScheduleDataCallAPI}
                  heightInPixels={400}
                />
              </Box>
            ) : null}
          </Paper>
        </ViewWrapper>
      )}
    </>
  );
}
