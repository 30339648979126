import { IDealFeeForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_FEE_TYPES {
  LEG = "Legal",
  AD = "Advisory",
  CON = "Consultant",
  UF = "Upfront",
  AC = "Acquisition",
  OT = "Other",
}

export const DEAL_FEE_TYPES_OPTIONS = enumToOptions(DEAL_FEE_TYPES);

export enum DEAL_FEE_ALLOCATION_METHODS {
  DAC = "Deal Allocated Cost",
  DFE = "Deal Fees Expensed",
  DFA = "Deal Fees Amortized",
  DFEX = "Deal Fee Excluded",
  SEF = "Sponsor Equity Fee",
  IF = "Investor Fee",
}

export const DEAL_FEE_ALLOCATION_METHODS_OPTIONS = enumToOptions(
  DEAL_FEE_ALLOCATION_METHODS,
  ["DFEX", "SEF", "IF"],
);

export enum DEAL_FEE_COST_WEIGHTING_METHODS {
  WBMW = "Weight by MW (AC)",
  SE = "Split Equally",
}

export enum DEAL_FEE_INPUT_TYPES {
  DL = "$",
  DWDC = "$/Wdc",
}

export enum DEAL_FEE_SPEND_METHODS {
  MD = "Milestone Date",
  SC = "Schedule",
}

export enum DEAL_FEE_MILESTONES {
  PBOL = "Project BOL",
  PNTP = "Project NTP",
  PCOD = "Project COD",
}

export const DEAL_FEE_MILESTONES_OPTIONS = enumToOptions(DEAL_FEE_MILESTONES);

export const DEAL_FEE_SPEND_METHODS_OPTIONS = enumToOptions(
  DEAL_FEE_SPEND_METHODS,
  ["SC"],
);

export const DEAL_FEE_INPUT_TYPES_OPTIONS = enumToOptions(DEAL_FEE_INPUT_TYPES);

export const DEAL_FEE_COST_WEIGHTING_METHODS_OPTIONS = enumToOptions(
  DEAL_FEE_COST_WEIGHTING_METHODS,
);

export const DEAL_FEE_FORM_DEFAULT_STATE: IDealFeeForm = {
  fee_type: "",
  name: "",
  amount: null,
  allocation_method: "",
  amortization_term: null,
  cost_weighting_method: "",
  input_type: "DL",
  spend_method: "",
  milestone: "",
  fee_multiplier: 100,
};
