import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputPortfolioSummaryView from "../../../../views/deal/output/portfolio/summary";
import {
  getDealOutputPortfolioCostSchedule,
  getDealOutputPortfolioCostSummary,
  downloadPortfolioCostScheduleData,
} from "../../../../apis/report/deal";

export default function DealOutputPortfolioSummaryPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputPortfolioSummaryView
          getPortfolioCostScheduleData={getDealOutputPortfolioCostSchedule}
          getPortfolioCostSummaryData={getDealOutputPortfolioCostSummary}
          downloadPortfolioCostScheduleData={downloadPortfolioCostScheduleData}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
