import ProjectPagesLayout from "../../../components/project-pages-layout";
import TabbedLayout from "../../../components/tabbed-layout";
import ProjectValuationCalculationView from "../../../views/project/valuation/calculation";
import {
  getProjectValuationCalculations,
  downloadProjectValuationCalculations_Report,
} from "../../../apis/project/valuation";

export default function ValuationCalculationPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Valuation">
        <ProjectValuationCalculationView
          getProjectValuationCalculations={getProjectValuationCalculations}
          downloadProjectValuationCalculations_Report={
            downloadProjectValuationCalculations_Report
          }
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
