import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { isNumber } from "lodash";
import { useParams } from "react-router-dom";

import useStyle from "./styles";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import DealSaleLeasebackFormModal from "../../../../components/deal-sale-leaseback-form-modal";
import Tabs from "../../../../components/tabs";
import PopoverMenu from "../../../../components/popover-menu";
import ConditionalProtect from "../../../../components/conditional-protect";
import { useAPI } from "../../../../utils/hooks";
import { cn, numberToUSD, trimString } from "../../../../utils/helpers";
import {
  IDealSaleLeaseback,
  IDealSaleLeasebackList,
  IDealSaleLeasebackSizingForm,
  IDealSaleLeasebackSizingFormErrors,
} from "../../../../interfaces";
import {
  DEAL_SLB_PREPAY_INPUT_TYPES,
  DEAL_SLB_RESERVE_MODE_TYPES,
  DEAL_SLB_RESIDUAL_SIZING_MODE_TYPES,
  DEAL_SLB_SOLVE_FORS,
  DEAL_SLB_LEASE_TYPES,
  USER_PERMISSIONS,
  DEAL_SALES_LEASEBACK_FORM_DEFAULT_STATE,
} from "../../../../constants";

interface IProps {
  getDealSaleLeaseback: (dealId: number) => Promise<IDealSaleLeasebackList[]>;
  getDealSaleLeasebackDetails: (
    dealId: number,
    sizingId: number,
  ) => Promise<IDealSaleLeaseback>;
  editDealSaleLeaseback: (
    dealId: number,
    id: number,
    form: IDealSaleLeasebackSizingForm,
  ) => Promise<IDealSaleLeaseback>;
}

export default function SaleLeasebackView({
  getDealSaleLeaseback,
  getDealSaleLeasebackDetails,
  editDealSaleLeaseback,
}: IProps) {
  const { dealId, caseDealId } = useParams();
  const styles = useStyle();

  const [saleLeasebackList, setSaleLeasebackList] =
    React.useState<IDealSaleLeasebackList[]>();
  const [openSLBFormEditModal, setOpenSLBFormEditModal] =
    React.useState<boolean>(false);
  const [currentSlbProject, setCurrentSlbProject] = React.useState<string>();
  const [currentSlbProjectDetails, setCurrentSlbProjectDetails] =
    React.useState<IDealSaleLeaseback>();
  const [editSaleLeasebackForm, setEditSaleLeasebackForm] =
    React.useState<IDealSaleLeasebackSizingForm>(
      DEAL_SALES_LEASEBACK_FORM_DEFAULT_STATE,
    );

  const isOnCase = React.useMemo(
    () => caseDealId !== dealId,
    [dealId, caseDealId],
  );

  const {
    callAPI: getSaleLeasebackListCallAPI,
    loading: getSaleLeasebackListLoading,
    errored: getSaleLeasebackListErrored,
  } = useAPI((dealId: number) => getDealSaleLeaseback(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: getSaleLeasebackDetailsCallAPI,
    loading: getSaleLeasebackDetailsLoading,
    errored: getSaleLeasebackDetailsErrored,
  } = useAPI(
    (dealId: number, sizingId: number) =>
      getDealSaleLeasebackDetails(dealId, sizingId),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: editDealSaleLeasebackCallAPI,
    loading: editDealSaleLeasebackLoading,
    fieldErrors: editDealSaleLeasebackFormErrors,
    setFieldErrors: setEditDealSaleLeasebackFormErrors,
  } = useAPI<IDealSaleLeaseback, IDealSaleLeasebackSizingFormErrors>(
    (dealId: number, sizingId: number, form: IDealSaleLeasebackSizingForm) =>
      editDealSaleLeaseback(dealId, sizingId, form),
  );

  React.useEffect(() => {
    getSaleLeasebackListCallAPI(Number(caseDealId)).then((res) => {
      if (res) {
        res && setSaleLeasebackList(res);
        res && setCurrentSlbProject(String(res[0]?.slb_sizing_id));
      }
    });
  }, [caseDealId]);

  React.useEffect(() => {
    if (currentSlbProject) {
      getSaleLeasebackDetailsCallAPI(
        Number(caseDealId),
        currentSlbProject,
      ).then((res) => {
        res && setCurrentSlbProjectDetails(res);
      });
    }
  }, [currentSlbProject]);

  const handleOpenSLBFormEditModal = () => {
    const {
      id,
      deal,
      created,
      modified,
      ebo_date,
      created_by,
      ...formDetails
    } = currentSlbProjectDetails!;
    setEditSaleLeasebackForm({
      ...formDetails,
    });
    setOpenSLBFormEditModal(true);
  };

  const onEditSaleLeaseback = async (form: IDealSaleLeasebackSizingForm) => {
    const editedSaleLeaseback = await editDealSaleLeasebackCallAPI(
      caseDealId,
      currentSlbProject,
      form,
    );
    editedSaleLeaseback && setCurrentSlbProjectDetails(editedSaleLeaseback);
    return editedSaleLeaseback;
  };

  const handleCloseSLBFormEditModal = () => {
    setOpenSLBFormEditModal(false);
  };

  return (
    <>
      <ViewWrapper
        loading={getSaleLeasebackListLoading}
        error={getSaleLeasebackListErrored}
      >
        <Box>
          {!saleLeasebackList && (
            <Box className={styles.classes.emptyContainer}>
              <Box>
                <Typography variant="body1" className={styles.classes.info}>
                  Sale-Leaseback on this deal does not exist.
                </Typography>
              </Box>
            </Box>
          )}

          {saleLeasebackList && (
            <Box>
              <Box className={styles.classes.content}>
                <Box className={cn("flex justify-between")}>
                  <Tabs
                    tabs={saleLeasebackList?.map((slb) => ({
                      label: slb.project_name,
                      value: String(slb.slb_sizing_id),
                    }))}
                    onClick={(tab) => setCurrentSlbProject(tab.value)}
                    selectedTab={currentSlbProject}
                  />
                  {saleLeasebackList.length > 5 && (
                    <PopoverMenu
                      uniqueId={123}
                      icon={<DoubleArrowRightIcon />}
                      items={saleLeasebackList.slice(5).map((slb) => ({
                        label: String(trimString(slb.project_name, 20)),
                        onClick: () =>
                          setCurrentSlbProject(String(slb.slb_sizing_id)),
                      }))}
                    />
                  )}
                </Box>
                <Box className={styles.classes.editContainer}>
                  {!isOnCase && (
                    <ConditionalProtect
                      type="deal"
                      permission={USER_PERMISSIONS.DEALS_CRUD}
                    >
                      <Button
                        startIcon={<EditIcon />}
                        label="Edit"
                        btnType="primary"
                        onClick={handleOpenSLBFormEditModal}
                      />
                    </ConditionalProtect>
                  )}
                </Box>
                <ViewWrapper
                  loading={getSaleLeasebackDetailsLoading}
                  error={getSaleLeasebackDetailsErrored}
                >
                  <Grid container spacing={2} rowSpacing="none">
                    <Grid item xs={12} md={6}>
                      <Stack gap={2}>
                        <DetailsCard
                          heading="Lease Type"
                          sections={[
                            {
                              fields: [
                                {
                                  label: "Lease Type",
                                  value: {
                                    text:
                                      DEAL_SLB_LEASE_TYPES[
                                        currentSlbProjectDetails?.lease_type as keyof typeof DEAL_SLB_LEASE_TYPES
                                      ] ?? "N/A",
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                        <DetailsCard
                          heading="Structure"
                          sections={[
                            {
                              fields: [
                                {
                                  label: "Solve for",
                                  value: {
                                    text:
                                      DEAL_SLB_SOLVE_FORS[
                                        currentSlbProjectDetails?.solve_for as keyof typeof DEAL_SLB_SOLVE_FORS
                                      ] ?? "N/A",
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                        <DetailsCard
                          heading="Key Parameters"
                          sections={[
                            {
                              fields: [
                                {
                                  label: "Purchase Price",
                                  value: {
                                    text: numberToUSD.format(
                                      currentSlbProjectDetails?.purchase_amount ??
                                        0,
                                    ),
                                  },
                                },
                                {
                                  label: "Lessor Yield",
                                  value: {
                                    text:
                                      (currentSlbProjectDetails?.target_yield ??
                                        0) + "%",
                                  },
                                },
                                {
                                  label: "Lease Start Date",
                                  value: {
                                    text:
                                      currentSlbProjectDetails?.lease_start_date ??
                                      "N/A",
                                  },
                                },
                                {
                                  label: "Lease Term (Years)",
                                  value: {
                                    text:
                                      (currentSlbProjectDetails?.lease_term ??
                                        0) + " Yrs",
                                  },
                                },
                                {
                                  label: "Lease End Date",
                                  value: {
                                    text:
                                      currentSlbProjectDetails?.lease_end_date ??
                                      "N/A",
                                  },
                                },
                                {
                                  label: "Prepayment Input Method",
                                  value: {
                                    text:
                                      DEAL_SLB_PREPAY_INPUT_TYPES[
                                        currentSlbProjectDetails?.prepayment_input_mode as keyof typeof DEAL_SLB_PREPAY_INPUT_TYPES
                                      ] ?? "N/A",
                                  },
                                },
                                {
                                  label: "Prepayment Limit ($,%)",
                                  value: {
                                    text:
                                      currentSlbProjectDetails?.prepayment_input_mode ===
                                      "DOLLAR"
                                        ? numberToUSD.format(
                                            currentSlbProjectDetails?.prepayment_limit ??
                                              0,
                                          )
                                        : (currentSlbProjectDetails?.prepayment_limit ??
                                            0) + "%",
                                  },
                                },
                                {
                                  label: "467 Loan Rate",
                                  value: {
                                    text:
                                      (currentSlbProjectDetails?.loan_rate ??
                                        0) + "%",
                                  },
                                },
                                {
                                  label: "Coverage Ratio: Contracted",
                                  value: {
                                    text: isNumber(
                                      currentSlbProjectDetails?.coverage_ratio_cnt,
                                    )
                                      ? String(
                                          currentSlbProjectDetails?.coverage_ratio_cnt,
                                        )
                                      : "N/A",
                                  },
                                },
                                {
                                  label: "Coverage Ratio: Uncontracted",
                                  value: {
                                    text: isNumber(
                                      currentSlbProjectDetails?.coverage_ratio_unc,
                                    )
                                      ? String(
                                          currentSlbProjectDetails?.coverage_ratio_unc,
                                        )
                                      : "N/A",
                                  },
                                },
                                {
                                  label: "Coverage Ratio: Special",
                                  value: {
                                    text: isNumber(
                                      currentSlbProjectDetails?.coverage_ratio_spc,
                                    )
                                      ? String(
                                          currentSlbProjectDetails?.coverage_ratio_spc,
                                        )
                                      : "N/A",
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                        <DetailsCard
                          heading="Buyout"
                          sections={[
                            {
                              fields: [
                                {
                                  label: "EBO Yield Premium",
                                  value: {
                                    text:
                                      (currentSlbProjectDetails?.ebo_yield_premium ??
                                        0) + "%",
                                  },
                                },
                                {
                                  label: "EBO Term (Years)",
                                  value: {
                                    text:
                                      (currentSlbProjectDetails?.ebo_term ??
                                        0) + " Yrs",
                                  },
                                },
                                {
                                  label: "EBO Date",
                                  value: {
                                    text:
                                      currentSlbProjectDetails?.ebo_date ??
                                      "N/A",
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack gap={2}>
                        <DetailsCard
                          heading="Reserves"
                          sections={[
                            {
                              fields: [
                                {
                                  label: "Reserve Sizing Method",
                                  value: {
                                    text:
                                      DEAL_SLB_RESERVE_MODE_TYPES[
                                        currentSlbProjectDetails?.reserve_mode as keyof typeof DEAL_SLB_RESERVE_MODE_TYPES
                                      ] ?? "N/A",
                                  },
                                },
                                // show if saleLeaseback.reserve_mode === "SPEC"
                                ...(currentSlbProjectDetails &&
                                ["SPEC", "PCTPUR"].includes(
                                  currentSlbProjectDetails.reserve_mode,
                                )
                                  ? [
                                      {
                                        label: "Rent Reserve Amount",
                                        value: {
                                          text:
                                            currentSlbProjectDetails?.reserve_mode ===
                                            "SPEC"
                                              ? numberToUSD.format(
                                                  currentSlbProjectDetails?.reserve_amount ??
                                                    0,
                                                )
                                              : (currentSlbProjectDetails?.reserve_amount ??
                                                  0) + "%",
                                        },
                                      },
                                    ]
                                  : []),
                                ...(currentSlbProjectDetails?.reserve_mode ===
                                "AVGRT"
                                  ? [
                                      {
                                        label: "Rent Multiple", // AKA "Months of Reserve"
                                        value: {
                                          text: isNumber(
                                            currentSlbProjectDetails?.months_of_reserve,
                                          )
                                            ? String(
                                                currentSlbProjectDetails?.months_of_reserve,
                                              )
                                            : "N/A",
                                        },
                                      },
                                    ]
                                  : []),

                                ...(currentSlbProjectDetails?.reserve_mode ===
                                "HGHRT"
                                  ? [
                                      {
                                        label: "Consecutive Periods",
                                        value: {
                                          text: isNumber(
                                            currentSlbProjectDetails?.consecutive_periods_of_highest_rent,
                                          )
                                            ? String(
                                                currentSlbProjectDetails?.consecutive_periods_of_highest_rent,
                                              )
                                            : "N/A",
                                        },
                                      },
                                    ]
                                  : []),
                              ],
                            },
                          ]}
                        />

                        <DetailsCard
                          heading="Residual Value"
                          sections={[
                            {
                              fields: [
                                {
                                  label: "Residual Sizing Method",
                                  value: {
                                    text:
                                      DEAL_SLB_RESIDUAL_SIZING_MODE_TYPES[
                                        currentSlbProjectDetails?.residual_sizing_mode as keyof typeof DEAL_SLB_RESIDUAL_SIZING_MODE_TYPES
                                      ] ?? "N/A",
                                  },
                                },
                                ...(currentSlbProjectDetails &&
                                ["SPECD", "SPECP"].includes(
                                  currentSlbProjectDetails?.residual_sizing_mode,
                                )
                                  ? [
                                      {
                                        label: "Residual Value ($,%)",
                                        value: {
                                          text: `${
                                            currentSlbProjectDetails?.residual_sizing_mode ===
                                            "SPECD"
                                              ? numberToUSD.format(
                                                  currentSlbProjectDetails?.residual_value ??
                                                    0,
                                                )
                                              : (currentSlbProjectDetails?.residual_value ??
                                                  0) + "%"
                                          }`,
                                        },
                                      },
                                    ]
                                  : []),

                                ...(currentSlbProjectDetails &&
                                !["SPECD", "SPECP"].includes(
                                  currentSlbProjectDetails?.residual_sizing_mode,
                                )
                                  ? [
                                      {
                                        label: "Residual Value Discount Rate",
                                        value: {
                                          text:
                                            (currentSlbProjectDetails?.residual_pv_rate ??
                                              0) + "%",
                                        },
                                      },
                                    ]
                                  : []),
                              ],
                            },
                          ]}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </ViewWrapper>
              </Box>
            </Box>
          )}
        </Box>
        <DealSaleLeasebackFormModal
          headerLabel="Edit Lessor"
          form={editSaleLeasebackForm}
          setForm={setEditSaleLeasebackForm}
          loading={editDealSaleLeasebackLoading}
          onClose={handleCloseSLBFormEditModal}
          onConfirm={onEditSaleLeaseback}
          open={openSLBFormEditModal}
          setFormErrors={setEditDealSaleLeasebackFormErrors}
          formErrors={editDealSaleLeasebackFormErrors}
        />
      </ViewWrapper>
    </>
  );
}
