import { makeStyles } from "tss-react/mui";

export default makeStyles<{
  heightOptions?: {
    isFilterOpen?: boolean;
    fixedHeight?: boolean;
  };
}>()((theme, { heightOptions }) => ({
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  tableContainer: {
    maxHeight: heightOptions?.isFilterOpen
      ? "calc(100vh - 316px)"
      : heightOptions?.fixedHeight
        ? "calc(100vh - 264px)"
        : "auto",
  },
}));
