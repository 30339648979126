import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Protect } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import TransferBridgeFormModal from "../../../../components/transfer-bridge-form-modal";
import ConditionalProtect from "../../../../components/conditional-protect";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";
import {
  setDeleteModalPropsAction,
  updateSelectedDealOutputPageTabAction,
} from "../../../../utils/redux/slices";
import {
  DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
  USER_PERMISSIONS,
} from "../../../../constants";
import {
  addTransferLoan,
  deleteTransferLoan,
  getTransferLoan,
  updateTransferLoan,
} from "../../../../apis/deal/construction-debt";
import {
  CONSTRUCTION_DEBT_BASE_RATE_TYPE,
  TRANSFER_BRIDGE_LOAN_FORM_DEFAULT_STATE,
  YEAR_FRAC_CONVENTION_TYPE,
} from "../../../../constants/deal/construction-debt";
import {
  ITransferBridgeLoanForm,
  ITransferBridgeLoanFormErrors,
  ITransferBridgeLoanResponse,
} from "../../../../interfaces/deal/construction-debt";

export default function TransferBridgeLoan(): JSX.Element {
  const { dealId, caseDealId } = useParams();

  const dispatch = useDispatch();
  const { selectedDealOutputPageTab } = useAppSelector((s) => s.deal);

  const [transferBridgeLoan, setTransferBridgeLoan] =
    React.useState<ITransferBridgeLoanResponse | null>(null);
  const [addTransferBridgeLoanModalOpen, setAddTransferBridgeLoanModalOpen] =
    React.useState<boolean>(false);
  const [editTransferBridgeLoanModalOpen, setEditTransferBridgeLoanModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<ITransferBridgeLoanForm>(
    TRANSFER_BRIDGE_LOAN_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getTransferBridgeLoanCallAPI(Number(caseDealId)).then((response) => {
      if (Array.isArray(response)) {
        setTransferBridgeLoan(response[0]);
      }
    });
  }, [caseDealId]);

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const { currentDeal } = useAppSelector((s) => s.deal);

  const {
    callAPI: getTransferBridgeLoanCallAPI,
    errored: getTransferBridgeLoanErrored,
    loading: loadingGetTransferBridgeLoan,
  } = useAPI((dealId: number) => getTransferLoan(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: addTransferBridgeLoanCallAPI,
    fieldErrors: addTransferBridgeLoanFieldErrors,
    setFieldErrors: setAddTransferBridgeLoanFieldErrors,
    loading: addTransferBridgeLoanLoading,
  } = useAPI<ITransferBridgeLoanResponse, ITransferBridgeLoanFormErrors>(
    (dealId: number, form: ITransferBridgeLoanForm) =>
      addTransferLoan(dealId, form),
  );

  const {
    callAPI: updateInvestorBridgeLoanCallAPI,
    fieldErrors: updateTransferrBridgeLoanFormErrors,
    setFieldErrors: setUpdateTransferBridgeLoanFormErrors,
    loading: updateTransferBridgeLoanLoading,
  } = useAPI<ITransferBridgeLoanResponse, ITransferBridgeLoanFormErrors>(
    (dealId: number, loanId: number, form: ITransferBridgeLoanForm) =>
      updateTransferLoan(dealId, loanId, form),
  );

  const handleOnAddTransferBridgeLoan = async (
    form: ITransferBridgeLoanForm,
  ) => {
    const loan = await addTransferBridgeLoanCallAPI(Number(caseDealId), form);
    loan && setTransferBridgeLoan(loan);

    return loan;
  };

  const handleOnEditInvestorBridgeLoan = async (
    form: ITransferBridgeLoanForm,
  ) => {
    const updatedLoan = await updateInvestorBridgeLoanCallAPI(
      Number(caseDealId),
      transferBridgeLoan?.id,
      form,
    );
    updatedLoan && setTransferBridgeLoan(updatedLoan);

    return updatedLoan;
  };

  const handleOpenAddInvestorBridgeLoanModal = async () => {
    setAddTransferBridgeLoanModalOpen(true);
  };

  const handleCloseAddInvestorBridgeLoanModal = () => {
    setAddTransferBridgeLoanModalOpen(false);
  };

  const handleOpenEditTransferBridgeLoanModal = async () => {
    const {
      base_rate_type,
      base_rate,
      spread,
      hedge,
      swap_rate,
      swap_credit_spread,
      commitment_fee,
      yearfrac_convention,
      upfront_fee,
      sizing_type,
      advance_rate,
    } = transferBridgeLoan!;
    setForm({
      base_rate_type,
      base_rate,
      spread,
      hedge,
      swap_rate,
      swap_credit_spread,
      commitment_fee,
      yearfrac_convention,
      upfront_fee,
      sizing_type,
      advance_rate,
    });
    setEditTransferBridgeLoanModalOpen(true);
  };

  const handleCloseEditTransferBridgeLoanModal = () => {
    setEditTransferBridgeLoanModalOpen(false);
  };

  const { callAPI: deleteConstructionLoanCallAPI } = useAPI(
    (id: number, loanId: number) => deleteTransferLoan(id, loanId),
    { setConfirmModalLoading: true },
  );

  const handleDeleteTransferBridgeLoan = async () => {
    const deleted = await deleteConstructionLoanCallAPI(
      caseDealId,
      transferBridgeLoan?.id,
    );

    if (deleted) {
      setTransferBridgeLoan(null);
      // clearing if it is remembered.
      if (
        selectedDealOutputPageTab["construction-debt"] ===
        "transfer-bridge-loan"
      ) {
        dispatch(
          updateSelectedDealOutputPageTabAction({
            ...selectedDealOutputPageTab,
            "construction-debt":
              DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS["construction-debt"],
          }),
        );
      }
    }
  };

  const handleOnDelete = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Transfer Bridge Loan",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteTransferBridgeLoan(),
      }),
    );
  };

  const transferBridgeLoanCardData = React.useMemo(() => {
    if (!transferBridgeLoan) return [];
    return [
      {
        label: "Type",
        value: { text: "Transfer" },
      },
      {
        label: "Advance Rate",
        value: { text: transferBridgeLoan.advance_rate.toString() + "%" },
      },
      {
        label: "Base Rate Type",
        value: {
          text: CONSTRUCTION_DEBT_BASE_RATE_TYPE[
            transferBridgeLoan.base_rate_type as keyof typeof CONSTRUCTION_DEBT_BASE_RATE_TYPE
          ],
        },
      },
      ...(transferBridgeLoan.base_rate_type === "FR"
        ? [
            {
              label: "Base Rate",
              value: { text: transferBridgeLoan.base_rate.toString() + "%" },
            },
          ]
        : []),
      {
        label: "Spread",
        value: { text: transferBridgeLoan.spread.toString() + "%" },
      },
      {
        label: "Hedge %",
        value: { text: transferBridgeLoan.hedge.toString() + "%" },
      },
      {
        label: "Swap Rate",
        value: { text: transferBridgeLoan.swap_rate.toString() + "%" },
      },
      {
        label: "Swap Credit Spread",
        value: { text: transferBridgeLoan.swap_credit_spread.toString() + "%" },
      },
      {
        label: "Upfront Fee",
        value: { text: transferBridgeLoan.upfront_fee.toString() + "%" },
      },
      {
        label: "Commitment Fee",
        value: { text: transferBridgeLoan.commitment_fee.toString() + "%" },
      },
      {
        label: "Yearfrac Convention",
        value: {
          text: YEAR_FRAC_CONVENTION_TYPE[
            transferBridgeLoan.yearfrac_convention
          ],
        },
      },
    ];
  }, [transferBridgeLoan]);

  return (
    <ViewWrapper
      loading={loadingGetTransferBridgeLoan}
      error={getTransferBridgeLoanErrored}
    >
      {!transferBridgeLoan && (
        <Paper sx={{ padding: 1 }}>
          <Typography fontWeight="bold">Transfer Bridge Loan</Typography>
          <Box className={cn("min-h-96 flex justify-center items-center")}>
            <Box>
              {!isOnCase && (
                <ConditionalProtect
                  type="deal"
                  permission={USER_PERMISSIONS.DEALS_CRUD}
                >
                  <Button
                    canOpenUpgrade
                    startIcon={<AddIcon />}
                    label="Add Transfer Bridge Loan"
                    onClick={handleOpenAddInvestorBridgeLoanModal}
                    btnType="primary"
                    disabled // Disable transfer debt always (for now)
                  />
                </ConditionalProtect>
              )}

              {currentDeal?.status === "ARCH" && (
                <Typography variant="body1">This deal is archived!</Typography>
              )}
            </Box>
          </Box>
        </Paper>
      )}

      {transferBridgeLoan && (
        <>
          <DetailsCard
            heading="Transfer Bridge Loan"
            sections={[
              {
                fields: transferBridgeLoanCardData,
              },
            ]}
            onEditClick={
              !isOnCase ? handleOpenEditTransferBridgeLoanModal : undefined
            }
            onDeleteClick={!isOnCase ? handleOnDelete : undefined}
            autoHeight
          />
        </>
      )}

      <TransferBridgeFormModal
        open={addTransferBridgeLoanModalOpen}
        form={form}
        setForm={setForm}
        headerLabel="Add Transfer Bridge Loan"
        loading={addTransferBridgeLoanLoading}
        formErrors={addTransferBridgeLoanFieldErrors}
        setFormErrors={setAddTransferBridgeLoanFieldErrors}
        onClose={handleCloseAddInvestorBridgeLoanModal}
        onConfirm={handleOnAddTransferBridgeLoan}
      />

      <TransferBridgeFormModal
        open={editTransferBridgeLoanModalOpen}
        form={form}
        setForm={setForm}
        headerLabel="Edit Transfer Bridge Loan"
        loading={updateTransferBridgeLoanLoading}
        formErrors={updateTransferrBridgeLoanFormErrors}
        setFormErrors={setUpdateTransferBridgeLoanFormErrors}
        onClose={handleCloseEditTransferBridgeLoanModal}
        onConfirm={handleOnEditInvestorBridgeLoan}
      />
    </ViewWrapper>
  );
}
