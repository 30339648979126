import { Protect } from "@clerk/clerk-react";

import { useAppSelector } from "../../utils/hooks/redux.hooks";
import { TUserPermission } from "../../interfaces";

interface IProps {
  permission: TUserPermission;
  type: "project" | "deal";
  children: React.ReactNode;
}

export default function ConditionalProtect({
  children,
  permission,
  type,
}: IProps) {
  const { currentProject } = useAppSelector((state) => state.project);
  const { currentDeal } = useAppSelector((state) => state.deal);

  if (type === "project" && currentProject?.status === "ARCH") return null;
  if (type === "deal" && currentDeal?.status === "ARCH") return null;

  return <Protect permission={permission}>{children}</Protect>;
}
