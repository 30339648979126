import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => {
  return {
    container: {
      height: "100%",
    },
    content: {
      marginTop: theme.spacing(2),
      display: "flex",
      width: "100%",
      gap: theme.spacing(2),
      "& > *": {
        flex: 0.5,
      },
    },
  };
});
