import { enumToOptions } from "../../utils/helpers";
import { IAddProjectDepreciationStreamForm } from "../../interfaces";

export enum PROJECT_DEPRECIATION_STREAM_TYPE {
  "3YMACRS" = "3-Yr MACRS",
  "5YMACRS" = "5-Yr MACRS",
  "7YMACRS" = "7-Yr MACRS",
  "15YMACRS" = "15-Yr MACRS",
  "20YMACRS" = "20-Yr MACRS",
  "39YMACRS" = "39-Yr MACRS",
  "5YSL" = "5-Yr SL",
  "10YSL" = "10-Yr SL",
  "12YSL" = "12-Yr SL",
  "15YSL" = "15-Yr SL",
  "20YSL" = "20-Yr SL",
  "25YSL" = "25-Yr SL",
  "30YSL" = "30-Yr SL",
  "35YSL" = "35-Yr SL",
  "39YSL" = "39-Yr SL",
  "50YSL" = "50-Yr SL",
  "NONDEP" = "Non-Depreciable",
}

export const PROJECT_DEPRECIATION_STREAM_TYPE_OPTIONS = enumToOptions(
  PROJECT_DEPRECIATION_STREAM_TYPE,
);

export enum PROJECT_DEPRECIATION_SYSTEM {
  REG = "Regular",
  BON = "Bonus",
  ADS = "12yr SL ADS",
}

export const PROJECT_DEPRECIATION_SYSTEM_OPTIONS = enumToOptions(
  PROJECT_DEPRECIATION_SYSTEM,
);

export const DEFAULT_PROJECT_DEPRECIATION_STREAM_FORM: IAddProjectDepreciationStreamForm =
  {
    type: "",
    percentage: "",
    itc_eligibility: "",
  };
