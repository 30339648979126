export const FONT_FAMILY = "Visby";

export const PRODUCT_PLANS = [
  {
    heading: "Trial Plan",
    code: "TRIAL",
    users: 3,
    subHeading: "For people just getting started with",
    bgColor: "#FAFAFA",
    features: ["Up to 3 users", "100 report calls"],
  },
  {
    heading: "Base Plan",
    code: "BASE",
    users: 30,
    subHeading: "Everything in Trial, and:",
    bgColor: "#F5F8FD",
    features: [
      "Up to 30 users",
      "100,000 report calls",
      "4 hours of training",
      "Model validation for 3 deals",
    ],
  },
  {
    heading: "Premium Plan",
    code: "PREMIUM",
    users: 50,
    subHeading: "Everything in Base, and:",
    bgColor: "#EBFBF1",
    features: [
      "Up to 50 users",
      "200,000 report calls",
      "10 external connections",
      "8 hours of training",
      "Model validation for 5 deals",
    ],
  },
  {
    heading: "Pro Plan",
    code: "PRO",
    subHeading: "Everything Custom",
    users: NaN,
    bgColor: "#FBF5EB",
    features: [
      "Custom users",
      "Custom report calls",
      "Custom external connections",
      "Custom training",
      "Custom model validation",
    ],
  },
];

export const PLAN_ADDITIONAL_FEES = [
  "For each additional user",
  "For each additional report call",
  "For each additional external organization connection",
  "For training and transaction support",
];

export const APP_ENVIRONMENTS = {
  dev: "Development",
  staging: "Staging",
  qa: "QA",
  miramar: "Miramar",
  uat: "UAT",
  test: "Test",
  prod: "",
};
