import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link, useParams } from "react-router-dom";

import useStyles from "./styles";
import useCommonStyles from "../common-styles";
import ViewWrapper from "../../../../components/view-wrapper";
import ReportTableV2 from "../../../../components/report-table-v2";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import {
  IDealCostCell,
  IDealCostRow,
  IDealTaxCredit,
  IReportTable,
} from "../../../../interfaces";
import {
  generateDealTaxCreditRows,
  generateDealTaxCreditHeaders,
  cn,
} from "../../../../utils/helpers";

interface IProps {
  getTaxCredit: (dealId: number) => Promise<IDealTaxCredit[]>;
  getTaxCreditPTC_Data: (dealId: number) => Promise<IReportTable>;
  downloadTaxCreditPTC_Data: (dealId: number) => Promise<void>;
}

export default function DealTaxCreditsView({
  getTaxCredit,
  getTaxCreditPTC_Data,
  downloadTaxCreditPTC_Data,
}: IProps): JSX.Element {
  const styles = useStyles();
  const commonStyles = useCommonStyles({ columnToHide: 2 }); // hide the second column

  const { caseDealId } = useParams();

  const { currentDeal } = useAppSelector((s) => s.deal);

  React.useEffect(() => {
    getDealTaxCreditCallAPI(Number(caseDealId));
  }, [caseDealId]);

  const {
    callAPI: getDealTaxCreditCallAPI,
    loading: getDealTaxCreditLoading,
    errored: getDealTaxCreditFailed,
    response: dealTaxCredit,
  } = useAPI((id) => getTaxCredit(id), { initialLoading: true });

  React.useEffect(() => {
    const ptcProjectExist = dealTaxCredit?.some((proj) => proj.type === "PTC");

    if (ptcProjectExist) {
      getTaxCreditPTC_DataCallAPI(Number(caseDealId));
    }
  }, [dealTaxCredit]);

  const {
    callAPI: getTaxCreditPTC_DataCallAPI,
    loading: loadingPTC_Data,
    errored: getPTC_DataFiled,
    response: PTC_Data,
  } = useAPI((id) => getTaxCreditPTC_Data(id));

  const { callAPI: downloadTaxCreditPTC_DataCallAPI } = useAPI(() =>
    downloadTaxCreditPTC_Data(Number(caseDealId)),
  );

  const commonTable = React.useMemo(() => {
    const data: {
      headers: Array<{ label: string; key: string; id: string }>;
      rows: IDealCostRow[];
    } = {
      headers: [],
      rows: [],
    };
    if (dealTaxCredit) {
      data.headers = generateDealTaxCreditHeaders(dealTaxCredit);
      data.rows = generateDealTaxCreditRows(dealTaxCredit);
    }
    return data;
  }, [dealTaxCredit]);

  // When only ITC projects in a deal, remove PTC and ITC/PTC rows. Example.
  const rowsToIgnoreWhenITC = [
    "PTC",
    "PTC Adder",
    "PTC Total",
    "ITC/PTC",
    "ITC/PTC Adder",
    "ITC/PTC Total",
  ];

  // When only PTC projects in a deal, remove ITC and ITC/PTC rows
  const rowsToIgnoreWhenPTC = [
    "ITC",
    "ITC Adder",
    "ITC Total",
    "ITC/PTC",
    "ITC/PTC Adder",
    "ITC/PTC Total",
  ];

  // When NONE - hide the PTC and ITC rows. Only show ITC/PTC (they will be 0 anyway)
  const rowsToIgnoreWhenNone = [
    "PTC",
    "ITC",
    "PTC Adder",
    "PTC Total",
    "ITC Adder",
    "ITC Total",
  ];

  return (
    <ViewWrapper
      loading={getDealTaxCreditLoading}
      error={getDealTaxCreditFailed}
    >
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer classes={{ root: styles.classes.tableContainer }}>
          <Table
            stickyHeader
            classes={{ root: commonStyles.classes.tableWithHideableColumn }}
            data-hide-total={
              (currentDeal?.projects?.length ?? 0) <= 1 // set this attribute true to hide the total column when only one project is present
            }
          >
            <TableHead className={styles.classes.header}>
              <TableRow>
                {commonTable.headers.map((header, idx) => {
                  if (idx > 1) {
                    return (
                      <TableCell key={idx} align="right">
                        <Link
                          to={`/project/${header.id}/tax/tax-credit`}
                          target="_blank"
                        >
                          {header.label}
                        </Link>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={idx} align="right">
                        {header.label}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {commonTable.rows.map((row: IDealCostRow, idx: number) => {
                if (
                  currentDeal?.tax_credit_type === "ITC" &&
                  rowsToIgnoreWhenITC.includes(row.columns[0]?.value.trim())
                ) {
                  return undefined;
                } else if (
                  currentDeal?.tax_credit_type === "PTC" &&
                  rowsToIgnoreWhenPTC.includes(row.columns[0]?.value.trim())
                ) {
                  return undefined;
                } else if (
                  currentDeal?.tax_credit_type === "NONE" &&
                  rowsToIgnoreWhenNone.includes(row.columns[0]?.value.trim())
                ) {
                  return undefined;
                }
                return (
                  <TableRow
                    key={idx}
                    className={row.is_bold ? styles.classes.boldRow : ""}
                  >
                    {row.columns.map((cell: IDealCostCell, iIdx: number) => (
                      <TableCell key={iIdx} align="right">
                        {cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <ViewWrapper loading={loadingPTC_Data} error={getPTC_DataFiled}>
        {PTC_Data?.rows.length ? (
          <Box className={cn("mt-4")}>
            <Typography fontWeight={600} sx={{ margin: 2 }}>
              PTC Generated ($)
            </Typography>
            <ReportTableV2
              data={PTC_Data}
              groupExpandedDepth={-1}
              download={downloadTaxCreditPTC_DataCallAPI}
            />
          </Box>
        ) : null}
      </ViewWrapper>
    </ViewWrapper>
  );
}
