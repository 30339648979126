import Typography from "@mui/material/Typography";

import Modal from "../modal";
import { ButtonType } from "../../interfaces";
import { useDispatch } from "react-redux";
import { resetConfirmModalPropsAction } from "../../utils/redux/slices";

export interface IConfirmDeleteModalProps {
  open: boolean;
  title: string;
  loading?: boolean;
  description: string;
  onClose?: () => void;
  onConfirm: () => Promise<void>;
  confirmBtnLabel?: string;
  confirmBtnType?: ButtonType;
}

export default function ConfirmModal({
  open,
  title,
  loading,
  description,
  onClose,
  onConfirm,
  confirmBtnLabel = "Confirm",
  confirmBtnType = "primary",
}: IConfirmDeleteModalProps): JSX.Element {
  const dispatch = useDispatch();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(resetConfirmModalPropsAction());
  };

  return (
    <Modal
      open={open}
      maxWidth="xs"
      loading={loading}
      heading={title}
      onClose={handleOnClose}
      onConfirm={onConfirm}
      confirmBtnType={confirmBtnType}
      confirmBtnLabel={confirmBtnLabel}
    >
      <Typography>{description}</Typography>
    </Modal>
  );
}
