import ProjectPagesLayout from "../../../../components/project-pages-layout";
import {
  editProjectCreditSupport,
  deleteProjectCreditSupport,
  getDetailsCreditSupport,
} from "../../../../apis/project/cost";
import TabbedLayout from "../../../../components/tabbed-layout";
import CreditSupportDetailView from "../../../../views/project/cost/credit-support/details";

export default function ProjectCostCreditSupportDetailPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Cost">
        <CreditSupportDetailView
          getDetailsCreditSupport={getDetailsCreditSupport}
          editProjectCreditSupport={editProjectCreditSupport}
          deleteProjectCreditSupport={deleteProjectCreditSupport}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
