import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Protect } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import InvestorBridgeFormModal from "../../../../components/investor-bridge-form-modal";
import ConditionalProtect from "../../../../components/conditional-protect";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";
import { USER_PERMISSIONS } from "../../../../constants";
import {
  addInvestorLoan,
  deleteInvestorLoan,
  getInvestorLoan,
  updateInvestorLoan,
} from "../../../../apis/deal/construction-debt";
import {
  CONSTRUCTION_DEBT_BASE_RATE_TYPE,
  INVESTOR_BRIDGE_LOAN_FORM_DEFAULT_STATE,
  YEAR_FRAC_CONVENTION_TYPE,
} from "../../../../constants/deal/construction-debt";
import {
  IInvestorBridgeLoanForm,
  IInvestorBridgeLoanFormErrors,
  IInvestorBridgeLoanResponse,
} from "../../../../interfaces/deal/construction-debt";
import {
  setCurrentDealAction,
  setDeleteModalPropsAction,
} from "../../../../utils/redux/slices";

export default function InvestorBridgeLoan(): JSX.Element {
  const { dealId, caseDealId } = useParams();

  const dispatch = useDispatch();
  const { currentDeal } = useAppSelector((s) => s.deal);

  const [investorBridgeLoan, setInvestorBridgeLoan] =
    React.useState<IInvestorBridgeLoanResponse | null>(null);
  const [addInvestorBridgeLoanModalOpen, setAddInvestorBridgeLoanModalOpen] =
    React.useState<boolean>(false);
  const [editInvestorBridgeLoanModalOpen, setEditInvestorBridgeLoanModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IInvestorBridgeLoanForm>(
    INVESTOR_BRIDGE_LOAN_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getInvestorBridgeLoanCallAPI(Number(caseDealId)).then((response) => {
      if (Array.isArray(response)) {
        setInvestorBridgeLoan(response[0]);
      }
    });
  }, [caseDealId]);

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const {
    callAPI: getInvestorBridgeLoanCallAPI,
    errored: getInvestorBridgeLoanErrored,
    loading: loadingGetInvestorBridgeLoan,
  } = useAPI((dealId: number) => getInvestorLoan(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: addInvestorBridgeLoanCallAPI,
    fieldErrors: addInvestorBridgeLoanFieldErrors,
    setFieldErrors: setAddInvestorBridgeLoanFieldErrors,
    loading: addInvestorBridgeLoanLoading,
  } = useAPI<IInvestorBridgeLoanResponse, IInvestorBridgeLoanFormErrors>(
    (dealId: number, form: IInvestorBridgeLoanForm) =>
      addInvestorLoan(dealId, form),
  );

  const {
    callAPI: updateInvestorBridgeLoanCallAPI,
    fieldErrors: updateInvestorBridgeLoanFormErrors,
    setFieldErrors: setUpdateInvestorBridgeLoanFormErrors,
    loading: updateInvestorBridgeLoanLoading,
  } = useAPI<IInvestorBridgeLoanResponse, IInvestorBridgeLoanFormErrors>(
    (dealId: number, loanId: number, form: IInvestorBridgeLoanForm) =>
      updateInvestorLoan(dealId, loanId, form),
  );

  const handleOnAddInvestorBridgeLoan = async (
    form: IInvestorBridgeLoanForm,
  ) => {
    const loan = await addInvestorBridgeLoanCallAPI(Number(caseDealId), form);

    if (loan && currentDeal) {
      dispatch(
        setCurrentDealAction({
          ...currentDeal,
          has_investor_bridge_loan: true,
        }),
      );
      setInvestorBridgeLoan(loan);
    }

    return loan;
  };

  const handleOnEditInvestorBridgeLoan = async (
    form: IInvestorBridgeLoanForm,
  ) => {
    const updatedLoan = await updateInvestorBridgeLoanCallAPI(
      Number(caseDealId),
      investorBridgeLoan?.id,
      form,
    );
    updatedLoan && setInvestorBridgeLoan(updatedLoan);

    return updatedLoan;
  };

  const handleOpenAddInvestorBridgeLoanModal = async () => {
    setAddInvestorBridgeLoanModalOpen(true);
  };

  const handleCloseAddInvestorBridgeLoanModal = () => {
    setAddInvestorBridgeLoanModalOpen(false);
  };

  const handleOpenEditInvestorBridgeLoanModal = async () => {
    const {
      base_rate_type,
      base_rate,
      spread,
      hedge,
      swap_rate,
      swap_credit_spread,
      commitment_fee,
      yearfrac_convention,
      advance_rate,
      repay_with_investor_investment,
      upfront_fee,
    } = investorBridgeLoan!;
    setForm({
      base_rate_type,
      base_rate,
      spread,
      hedge,
      swap_rate,
      swap_credit_spread,
      commitment_fee,
      yearfrac_convention,
      advance_rate,
      repay_with_investor_investment,
      upfront_fee,
    });
    setEditInvestorBridgeLoanModalOpen(true);
  };

  const handleCloseEditInvestorBridgeLoanModal = () => {
    setEditInvestorBridgeLoanModalOpen(false);
  };

  const { callAPI: deleteConstructionLoanCallAPI } = useAPI(
    (id: number, loanId: number) => deleteInvestorLoan(id, loanId),
    { setConfirmModalLoading: true },
  );

  const handleDeleteTransferBridgeLoan = async () => {
    const deleted = await deleteConstructionLoanCallAPI(
      caseDealId,
      investorBridgeLoan?.id,
    );

    if (deleted && currentDeal) {
      dispatch(
        setCurrentDealAction({
          ...currentDeal,
          has_investor_bridge_loan: false,
        }),
      );

      setInvestorBridgeLoan(null);
    }
  };

  const handleOnDelete = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Investor Bridge Loan",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteTransferBridgeLoan(),
      }),
    );
  };

  const investorBridgeLoanCardData = React.useMemo(() => {
    if (!investorBridgeLoan) return [];
    return [
      {
        label: "Type",
        value: { text: "Investor" },
      },
      {
        label: "Advance Rate",
        value: { text: investorBridgeLoan.advance_rate.toString() + "%" },
      },
      {
        label: "Base Rate Type",
        value: {
          text: CONSTRUCTION_DEBT_BASE_RATE_TYPE[
            investorBridgeLoan.base_rate_type as keyof typeof CONSTRUCTION_DEBT_BASE_RATE_TYPE
          ],
        },
      },
      ...(investorBridgeLoan.base_rate_type === "FR"
        ? [
            {
              label: "Base Rate",
              value: { text: investorBridgeLoan.base_rate.toString() + "%" },
            },
          ]
        : []),
      {
        label: "Spread",
        value: { text: investorBridgeLoan.spread.toString() + "%" },
      },
      {
        label: "Hedge %",
        value: { text: investorBridgeLoan.hedge.toString() + "%" },
      },
      {
        label: "Swap Rate",
        value: { text: investorBridgeLoan.swap_rate.toString() + "%" },
      },
      {
        label: "Swap Credit Spread",
        value: { text: investorBridgeLoan.swap_credit_spread.toString() + "%" },
      },
      {
        label: "Upfront Fee",
        value: { text: investorBridgeLoan.upfront_fee.toString() + "%" },
      },
      {
        label: "Commitment Fee",
        value: { text: investorBridgeLoan.commitment_fee.toString() + "%" },
      },
      {
        label: "Yearfrac Convention",
        value: {
          text: YEAR_FRAC_CONVENTION_TYPE[
            investorBridgeLoan.yearfrac_convention
          ],
        },
      },
      // Note - we will later add this back in some way when we have two stage funding for the investor bridge loan
      // {
      //   label: "Repay with Investor Investment",
      //   value: {
      //     text: investorBridgeLoan.repay_with_investor_investment
      //       ? "Yes"
      //       : "No",
      //   },
      // },
    ];
  }, [investorBridgeLoan]);

  return (
    <ViewWrapper
      loading={loadingGetInvestorBridgeLoan}
      error={getInvestorBridgeLoanErrored}
    >
      {!investorBridgeLoan && (
        <Paper sx={{ padding: 1 }}>
          <Typography fontWeight="bold">Investor Bridge Loan</Typography>
          <Box className={cn("min-h-96 flex justify-center items-center")}>
            <Box>
              {!isOnCase && (
                <ConditionalProtect
                  type="deal"
                  permission={USER_PERMISSIONS.DEALS_CRUD}
                >
                  <Button
                    canOpenUpgrade
                    startIcon={<AddIcon />}
                    label="Add Investor Bridge Loan"
                    onClick={handleOpenAddInvestorBridgeLoanModal}
                    btnType="primary"
                    dataPw="add-investor-bridge-loan-button"
                  />
                </ConditionalProtect>
              )}

              {currentDeal?.status === "ARCH" && (
                <Typography variant="body1">This deal is archived!</Typography>
              )}
            </Box>
          </Box>
        </Paper>
      )}

      {investorBridgeLoan && (
        <>
          <DetailsCard
            dataPw="investor-bridge-loan-details-card"
            heading="Investor Bridge Loan"
            sections={[
              {
                fields: investorBridgeLoanCardData,
              },
            ]}
            onEditClick={
              !isOnCase ? handleOpenEditInvestorBridgeLoanModal : undefined
            }
            onDeleteClick={!isOnCase ? handleOnDelete : undefined}
            autoHeight
          />
        </>
      )}

      <InvestorBridgeFormModal
        open={addInvestorBridgeLoanModalOpen}
        form={form}
        setForm={setForm}
        headerLabel="Add Investor Bridge Loan"
        loading={addInvestorBridgeLoanLoading}
        formErrors={addInvestorBridgeLoanFieldErrors}
        setFormErrors={setAddInvestorBridgeLoanFieldErrors}
        onClose={handleCloseAddInvestorBridgeLoanModal}
        onConfirm={handleOnAddInvestorBridgeLoan}
      />

      <InvestorBridgeFormModal
        open={editInvestorBridgeLoanModalOpen}
        form={form}
        setForm={setForm}
        headerLabel="Edit Investor Bridge Loan"
        loading={updateInvestorBridgeLoanLoading}
        formErrors={updateInvestorBridgeLoanFormErrors}
        setFormErrors={setUpdateInvestorBridgeLoanFormErrors}
        onClose={handleCloseEditInvestorBridgeLoanModal}
        onConfirm={handleOnEditInvestorBridgeLoan}
      />
    </ViewWrapper>
  );
}
