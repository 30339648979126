import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { format, isValid } from "date-fns";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import useStyles from "./styles";
import { cn, filterFormFields } from "../../utils/helpers";
import { getDealTaxEquityFields, IFields } from "./fields";
import {
  DEAL_TAX_EQUITY_FORM_DEFAULT_STATE,
  DEAL_TAX_EQUITY_FORM_FIELDS_TO_EXCLUDE,
  DEAL_TAX_EQUITY_TYPES,
} from "../../constants";
import {
  ISelectOption,
  SetStateAction,
  IDealTaxEquity,
  IDealTaxEquityForm,
  IDealTaxEquityFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IDealTaxEquityFormErrors;
  setFormErrors: SetStateAction<IDealTaxEquityFormErrors | undefined>;
  form: IDealTaxEquityForm;
  setForm: SetStateAction<IDealTaxEquityForm>;
  onClose: () => void;
  onConfirm: (form: IDealTaxEquityForm) => Promise<IDealTaxEquity | undefined>;
}

export default function DealPartnershipInvestorConfigurationFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleTaxEquityInputValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = parseFloat(e.target.value);
    if (value >= 0 && value <= 100) {
      setForm((prevState) => ({
        ...prevState,
        tax_equity_input_value: value,
        cash_tax_split_percent: value,
      }));
    }
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field === "type") {
      setForm((prev) => ({
        ...prev,
        [field]: e.target.value as keyof typeof DEAL_TAX_EQUITY_TYPES,
        solve_for: "",
      }));
      if (e.target.value === "FF") {
        setForm((prev) => ({
          ...prev,
          solve_for: "IRR",
          tax_equity_input_method: "PCTFMV",
          tax_equity_input_value: 50.0,
          pre_flip_cash_split_percent: 99,
          pre_flip_tax_split_percent: 99,
          preferred_return_type: "PCTINV",
          preferred_return_percent: 2,
          sponsor_return_of_capital: 95,
          buyout_calculation_method: "DISCRT",
          buyout_amount_discount_rate: "",
          target_flip_cash_split: "",
          roi_floor_buyout_term: "",
          roi_floor_full_term: "",
          tax_allocation_method: "REG",
        }));
      }
      if (e.target.value === "YBF") {
        setForm((prev) => ({
          ...prev,
          solve_for: "TEQAMT",
          pre_flip_cash_split_percent: 20,
          pre_flip_tax_split_percent: 99,
          preferred_return_type: "NONE",
          tax_allocation_method: "REG",
        }));
      }
      if (e.target.value === "PR") {
        // if prorata being selected, set a bunch of defaults for the user
        setForm((prev) => ({
          ...prev,
          solve_for: "IRR",
          tax_allocation_method: "PRORATA",
          tax_equity_input_method: "PCTFMV",
          tax_equity_input_value: 50,
          cash_tax_split_percent: 50, // keep in sync with pct fmv
          tax_credits_in_pre_tax_book_income: "NO",
          suspend_pre_tax_book_loss: "NO",
        }));
      } else {
        if (form.tax_allocation_method === "PRORATA") {
          // can't let non-prorata use Percentage Interest/ Prorata tax alloc method
          setForm((prev) => ({
            ...prev,
            tax_allocation_method: "",
          }));
        }
      }
      return;
    }
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const onYearChange = (v: Date | null, field: string) => {
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "yyyy"),
      }));
    }
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    if (form.type && form.solve_for) {
      const fieldsToSetNull =
        DEAL_TAX_EQUITY_FORM_FIELDS_TO_EXCLUDE[form.type][form.solve_for];
      Object.keys(form).forEach((k) => {
        if (fieldsToSetNull?.includes(k as keyof IDealTaxEquityForm)) {
          form[k as keyof IDealTaxEquityForm] = null;
        }
      });
    }

    if (form.buyout_type !== "FLDATE") {
      form.buyout_irr = null;
      form.buyout_amount_discount_rate = null;
      form.buyout_date = "";
    }

    if (form.itc_recognition_method !== "SD") {
      form.itc_recognition_lag = null;
    }

    if (form.type === "PR") {
      // needed if cash_tax_split_percent is unedited by user
      // if cash_tax_split_percent is null and set it to post_flip_cash_split_percent (since cash_tax_split_percent is not an acutal field in the db)
      if (!form.cash_tax_split_percent) {
        form.cash_tax_split_percent = form.post_flip_cash_split_percent;
      }
    }

    const taxEquity = await onConfirm(form);
    taxEquity && handleOnClose();
  };

  const formFields = React.useMemo(() => {
    return getDealTaxEquityFields({
      form,
      formErrors,
      loading,
      clearErrorOnFocus,
      clearNonTextFieldErrorOnFocus,
      onTextChange: handleTextChange,
      onSelectInputChange: handleSelectInputChange,
      onDateChange: handleDateChange,
      clearSelectErrorOnFocus: clearSelectErrorOnFocus,
      handleSingleAutoCompleteChange,
      onYearChange,
      onTaxEquityInputValueChange: handleTaxEquityInputValueChange,
    });
  }, [form, formErrors]);

  const renderFields = (type: keyof IFields) => {
    const requestedFields = formFields[type];
    return filterFormFields(requestedFields, {
      type: form.type || "",
      solve_for: form.solve_for || "",
      tax_equity_input_method: form.tax_equity_input_method || "",
      buyout_type: form.buyout_type || "",
      preferred_return_type: form.preferred_return_type || "",
      buyout_irr_input_type: form.buyout_irr_input_type || "",
      buyout_calculation_method: form.buyout_calculation_method || "",
      change_in_federal_tax_rate: form.change_in_federal_tax_rate || "",
      tax_allocation_method: form.tax_allocation_method || "",
      accounting_method: form.accounting_method || "",
      itc_recognition_method: form.itc_recognition_method || "",
      has_itc_projects: form.has_itc_projects ?? false,
    }).map((f, i) => <React.Fragment key={i}>{f.element}</React.Fragment>);
  };

  React.useEffect(() => {
    if (form.change_in_federal_tax_rate === "N") {
      setForm((form) => {
        return {
          ...form,
          new_federal_tax_rate: "",
          federal_tax_rate_change_year: null,
        };
      });
    }
  }, [form.change_in_federal_tax_rate]);

  const handleResetform = () => {
    setForm(DEAL_TAX_EQUITY_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      open={open}
      maxWidth="md"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      classes={{ paper: cn("!w-[80%] !max-w-[1200px]") }}
      resetForm={handleResetform}
    >
      <Box className={cn("flex flex-col md:flex-row md:*:flex-1 gap-4")}>
        <Box>
          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Structure
          </Divider>
          {renderFields("base")}

          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Key Parameters
          </Divider>
          {renderFields("keyParameters")}
        </Box>

        {form.type !== "PR" && (
          <Box>
            <React.Fragment>
              <Divider
                classes={{ root: styles.classes.divider }}
                textAlign="left"
              >
                Additional Parameters
              </Divider>
              {renderFields("additionalParameters")}
              <Divider
                classes={{ root: styles.classes.divider }}
                textAlign="left"
              >
                Constraints
              </Divider>
              {renderFields("constraints")}
            </React.Fragment>
          </Box>
        )}

        {/* if prorata we still want to show some additional params */}
        {form.type === "PR" && (
          <Box>
            <React.Fragment>
              <Divider
                classes={{ root: styles.classes.divider }}
                textAlign="left"
              >
                Additional Parameters
              </Divider>
              {renderFields("additionalParameters")}
            </React.Fragment>
          </Box>
        )}

        <Box>
          {form.type !== "PR" && (
            <React.Fragment>
              <Divider
                classes={{ root: styles.classes.divider }}
                textAlign="left"
              >
                Buyout
              </Divider>
              <Box>{renderFields("buyout")}</Box>
            </React.Fragment>
          )}

          <React.Fragment>
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Accounting
            </Divider>
            <Box>{renderFields("accounting")}</Box>
          </React.Fragment>
        </Box>
      </Box>
    </Modal>
  );
}
