import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";

import useStyles from "./styles";
import useCommonStyles from "../common-styles";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import ConditionalProtect from "../../../../components/conditional-protect";
import DealDepreciationElectionEditModal from "../../../../components/deal-depreciation-election-edit-form-modal";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import {
  USER_PERMISSIONS,
  UPDATE_DEAL_DEPRECIATION_ELECTION_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  IDealProjectDepreciationByYear,
  IUpdateDealDepreciationResponse,
  IUpdateDealDepreciationElectionForm,
  IUpdateDealDepreciationElectionFormErrors,
} from "../../../../interfaces";
import {
  generateDealProjectDepreciationRows,
  generateDealProjectDepreciationHeaders,
} from "../../../../utils/helpers";

interface IProps {
  getDealProjectDepreciation: (
    dealId: number,
  ) => Promise<IDealProjectDepreciationByYear>;
  editDealDepreciation: (
    dealId: number,
    form: IUpdateDealDepreciationElectionForm,
  ) => Promise<IUpdateDealDepreciationResponse>;
}

export default function DealDepreciation({
  getDealProjectDepreciation,
  editDealDepreciation,
}: IProps): JSX.Element {
  const styles = useStyles();
  const commonStyles = useCommonStyles({ columnToHide: 3 }); // hide the third column

  const { currentDeal } = useAppSelector((s) => s.deal);

  const { dealId, caseDealId } = useParams();

  const [editDepreciationModalOpen, setEditDepreciationModalOpen] =
    React.useState<boolean>(false);

  const [form, setForm] = React.useState<IUpdateDealDepreciationElectionForm>(
    UPDATE_DEAL_DEPRECIATION_ELECTION_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getDealProjectDepreciationCallAPI(Number(caseDealId));
  }, [caseDealId]);

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const {
    callAPI: getDealProjectDepreciationCallAPI,
    loading: getDealProjectDepreciationLoading,
    errored: getDealProjectDepreciationFailed,
    response: deal_project_depreciation,
  } = useAPI((id) => getDealProjectDepreciation(id), { initialLoading: true });

  const {
    callAPI: editDealDepreciationCallAPI,
    fieldErrors: editDealDepreciationFormErrors,
    setFieldErrors: setEditDealDepreciationFormErrors,
    loading: editDealDepreciationLoading,
  } = useAPI<
    IUpdateDealDepreciationElectionForm,
    IUpdateDealDepreciationElectionFormErrors
  >((dealId: number, form: IUpdateDealDepreciationElectionForm) =>
    editDealDepreciation(Number(dealId), form),
  );

  const handleOnEditDealDepreciation = async (
    form: IUpdateDealDepreciationElectionForm,
  ) => {
    const updatedDealDepreciation = await editDealDepreciationCallAPI(
      Number(caseDealId),
      form,
    );

    if (updatedDealDepreciation) {
      setEditDepreciationModalOpen(false);
    }
    getDealProjectDepreciationCallAPI(Number(caseDealId));

    return updatedDealDepreciation;
  };

  const handleOpenEditDealDepreciationModal = async (
    year: string,
    macrsElection: string,
  ) => {
    setForm((prevForm) => ({
      ...prevForm,
      year, // Set the year in the form state
      macrs_election: macrsElection, // Set the macrs_election in the form state
    }));
    setEditDepreciationModalOpen(true);
  };

  const handleCloseDepreciationElectionModal = () => {
    setEditDepreciationModalOpen(false);
  };

  return (
    <>
      <ViewWrapper
        loading={getDealProjectDepreciationLoading}
        error={getDealProjectDepreciationFailed}
      >
        {deal_project_depreciation &&
          Object.entries(deal_project_depreciation).map(([year, data], idx) => {
            const headers = generateDealProjectDepreciationHeaders(data);
            const rows = generateDealProjectDepreciationRows(data);

            return (
              <Box key={idx} style={{ marginTop: idx === 0 ? 0 : 16 }}>
                {!isOnCase && (
                  <Box className={styles.classes.tableContainerAction}>
                    <ConditionalProtect
                      type="deal"
                      permission={USER_PERMISSIONS.DEALS_CRUD}
                    >
                      <Button
                        canOpenUpgrade
                        startIcon={<EditIcon />}
                        label="Edit Depreciation Election"
                        btnType="primary"
                        onClick={() =>
                          handleOpenEditDealDepreciationModal(
                            year,
                            data[0].macrs_election || "",
                          )
                        }
                      />
                    </ConditionalProtect>
                  </Box>
                )}

                <Typography variant="h6">{year} Projects</Typography>

                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer
                    classes={{ root: styles.classes.tableContainer }}
                  >
                    <Table
                      stickyHeader
                      classes={{
                        root: commonStyles.classes.tableWithHideableColumn,
                      }}
                      data-hide-total={
                        (currentDeal?.projects?.length ?? 0) <= 1 // set this attribute true to hide the total column when only one project is present
                      }
                    >
                      <TableHead className={styles.classes.header}>
                        <TableRow>
                          {headers.map((header, idx) => {
                            if (idx > 2) {
                              return (
                                <TableCell key={idx} align="right">
                                  <Link
                                    to={`/project/${header.id}/tax/depreciation`}
                                    target="_blank"
                                  >
                                    {header.label}
                                  </Link>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={idx} align="right">
                                  {header.label}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index: number) => (
                          <TableRow
                            key={index}
                            className={
                              index === rows.length - 1
                                ? styles.classes.boldRow
                                : ""
                            }
                          >
                            {row.map((cell, idx: number) => (
                              <TableCell key={idx} align="right">
                                {cell?.value}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            );
          })}
      </ViewWrapper>

      <DealDepreciationElectionEditModal
        open={editDepreciationModalOpen}
        headerLabel="Edit Depreciation Election"
        loading={editDealDepreciationLoading}
        formErrors={editDealDepreciationFormErrors}
        setFormErrors={setEditDealDepreciationFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseDepreciationElectionModal}
        onConfirm={handleOnEditDealDepreciation}
      />
    </>
  );
}
