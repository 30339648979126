import {
  AxiosHelper,
  replaceParams,
  transformBlobToFile,
} from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import {
  IProjectProduction,
  IReportTable,
  IUpdateProjectProductionForm,
} from "../../interfaces";

export const getProjectProduction = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.get, { projectId });
  return await AxiosHelper.get<IProjectProduction[]>(url);
};

export const updateProjectProduction = async (
  projectId: number,
  productionId: number,
  form: IUpdateProjectProductionForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.update, {
    projectId,
    productionId,
  });
  return await AxiosHelper.patch<
    IUpdateProjectProductionForm,
    IProjectProduction
  >(url, form);
};

export const getProjectProductionComponents = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.components, {
    projectId,
  });

  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadProjectProductionComponents = async (
  projectId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.downloadComponents, {
    projectId,
  });

  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(
      response,
      `project-${projectId}-production-components-report.xlsx`,
    );
  });
};
