import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import LogsIcon from "@mui/icons-material/Restore";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import useStyles from "./styles";
import Button from "../../../components/button";
import DetailsCard from "../../../components/details-card";
import ProjectFormModal from "../../../components/project-form-modal";
import ViewWrapper from "../../../components/view-wrapper";
import TextAsLink from "../../../components/text-as-link";
import Chip from "../../../components/general/Chip";
import AddDealFormModal from "../../../components/add-deal-form-modal";
import ProjectDuplicateModal from "../../../components/project-duplicate-modal";
import Logs from "../../../components/logs";
import LogsWrapper from "../../../components/logs-wrapper";
import ChartWrapper from "../../../components/chart-wrapper";
import CheckboxInput from "../../../components/checkbox-input";
import ActionButton from "../../../components/action-button";
import ConditionalProtect from "../../../components/conditional-protect";
import Chart from "../pro-forma/dashboard/chart";
import TimingChart, {
  ITimingChartProps,
} from "../../../components/timing-chart";
import { deleteProject } from "../../../apis/project/base";
import {
  setCurrentProjectAction,
  updateShowUsageLimitHitModalAction,
  setDeleteModalPropsAction,
} from "../../../utils/redux/slices";
import {
  useAPI,
  useLogs,
  useDrawer,
  useAppSelector,
} from "../../../utils/hooks";
import {
  cn,
  getFilledChipColor,
  numberToUSD,
  numberWithCommas,
  sortArrayOfObjects,
  trimString,
} from "../../../utils/helpers";
import {
  PROJECT_TYPES,
  PROJECT_STAGES,
  US_STATES,
  PROJECT_REVENUE_CONTRACT_TYPE,
  PROJECT_TAX_CREDIT_TYPE,
  DEAL_STAGES,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  ADD_DEAL_FORM_DEFAULT_VAULES,
  USER_PERMISSIONS,
  MARKETS,
  PROFORMA_TABLE_CONFIG,
  PROJECT_FORM_DEFAULT_STATE,
  PROFORMA_DEFAULT_STATE,
  DEAL_FORM_DEFAULT_STATE,
  DEAL_STATUS,
  FORM_TYPES,
} from "../../../constants";
import {
  IProject,
  IProjectProduction,
  IProjectTiming,
  IProjectRevenue,
  ServerPaginatedResponse,
  ITableSort,
  IAddDealForm,
  IAddDealFormErrors,
  ISelectOption,
  IProjectForm,
  IProjectFormErrors,
  IAddUpdateProjectResponse,
  IDuplicateProjectForm,
  IDuplicateProjectFormErrors,
  IGetProjectsParams,
  IProjectProforma,
  ProformaReportType,
  IProjectProformaResponse,
  IAddDealResponse,
  IDuplicateProjectResponse,
  IDealDetailsInProjectAPI_Call,
  IProjectCostDataResponse,
  ILogsConfiguration,
} from "../../../interfaces";

const dealsOfProjectColumns = [
  { id: "name", label: "Deal Name", minWidth: 100, align: "left" },
  { id: "stage", label: "Stage", minWidth: 20, align: "left" },
  { id: "structure", label: "Structure", minWidth: 20, align: "left" },
  {
    id: "tax_credit_structure",
    label: "Tax Credit Structure",
    minWidth: 20,
    align: "left",
  },
  {
    id: "type_string",
    label: "Project Type(s)",
    minWidth: 20,
    align: "left",
  },
  { id: "capacity_ac", label: "Capacity (AC)", minWidth: 20, align: "left" },
  { id: "status", label: "Status", minWidth: 20, align: "left" },
  { id: "modified", label: "Last Modified", minWidth: 20, align: "left" },
];

interface IProps {
  getProjectProduction: (id: number) => Promise<IProjectProduction[] | null>;
  getProjectCost: (
    projectId: number,
  ) => Promise<IProjectCostDataResponse | null>;
  getProjectRevenueContracts: (
    projectId: number,
  ) => Promise<ServerPaginatedResponse<IProjectRevenue[]> | null>;
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[] | null>;
  duplicateProject: (
    id: number,
    form: IDuplicateProjectForm,
  ) => Promise<IDuplicateProjectResponse>;
  updateProject: (
    id: number,
    form: IProjectForm,
  ) => Promise<IAddUpdateProjectResponse>;
  addDeal: (form: IAddDealForm) => Promise<IAddDealResponse>;
  addProject: (form: IProjectForm) => Promise<IAddUpdateProjectResponse>;
  getProjects: (
    params: IGetProjectsParams,
  ) => Promise<ServerPaginatedResponse<IProject[]>>;
  getProjectProformaDetails: (
    id: number,
    periodicity: ProformaReportType,
    cash_basis: "cash" | "accrual",
  ) => Promise<IProjectProformaResponse | null>;
}

export default function ProjectGeneralView({
  getProjectCost,
  getProjectRevenueContracts,
  getProjectProduction,
  getProjectTiming,
  duplicateProject,
  updateProject,
  addDeal,
  addProject,
  getProjects,
  getProjectProformaDetails,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { productUsageDetails } = useAppSelector((s) => s.org);
  const { currentProject: project, currentProjectStatus: projectStatus } =
    useAppSelector((s) => s.project);

  const navigate = useNavigate();
  const { projectId } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [showLoader, setShowLoader] = React.useState<boolean>(true);
  const [openUpdateProjectModal, setOpenUpdateProjectModal] =
    React.useState<boolean>(false);
  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();
  const [projectCost, setProjectCost] =
    React.useState<IProjectCostDataResponse>();
  const [revenueContracts, setRevenueContracts] = React.useState<
    IProjectRevenue[]
  >([]);
  const [projectProduction, setProjectProduction] =
    React.useState<IProjectProduction>();

  const [openDuplicateProjectModal, setOpenDuplicateProjectModal] =
    React.useState<boolean>(false);
  const [projectDuplicateForm, setDuplicateProjectForm] =
    React.useState<IDuplicateProjectForm>({
      name: "",
    });
  const [projectForm, setProjectForm] = React.useState<IProjectForm>(
    PROJECT_FORM_DEFAULT_STATE,
  );

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const [projectOptions, setProjectOptions] = React.useState<ISelectOption[]>(
    [],
  );

  const [addDealFormData, setAddDealFormData] = React.useState<IAddDealForm>(
    DEAL_FORM_DEFAULT_STATE,
  );

  const [openAddDealModal, setOpenAddDealModal] =
    React.useState<boolean>(false);
  const [addProjectModalOpen, setAddProjectModalOpen] =
    React.useState<boolean>(false);

  const [projectProforma, setProjectProforma] =
    React.useState<IProjectProforma>(PROFORMA_DEFAULT_STATE);
  const [showOperationPeriod, setShowOperationPeriod] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if ((!projectStatus.loading && project) || projectStatus.errored) {
      setShowLoader(false);
    }
  }, [projectStatus]);

  React.useEffect(() => {
    getProjectsCallAPI().then((response) => {
      if (response) {
        setProjectOptions(
          response.results.map((p) => ({
            label: p.name,
            value: String(p.id),
            status: p.status,
          })),
        );
      }
    });
    getProjectProformaDetailsCallAPI().then((response) => {
      response && setProjectProforma(response.data);
    });
  }, [projectId]);

  React.useEffect(() => {
    getSummaryDataCallAPI(projectId);
  }, [projectId]);

  const { callAPI: getProjectsCallAPI } = useAPI(() => getProjects({}));
  const { callAPI: deleteProjectCallAPI } = useAPI(() =>
    deleteProject(Number(projectId)),
  );

  const {
    callAPI: addDealCallAPI,
    fieldErrors: addDealFormErrors,
    setFieldErrors: setAddDealFormErrors,
    loading: addDealLoading,
  } = useAPI<IAddDealResponse, IAddDealFormErrors>((form: IAddDealForm) =>
    addDeal(form),
  );

  const {
    callAPI: updateProjectCallAPI,
    fieldErrors: updateProjectFormErrors,
    setFieldErrors: setUpdateProjectFormErrors,
    loading: updateProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (projectId: number, form: IProjectForm) => updateProject(projectId, form),
  );

  const {
    callAPI: duplicateProjectCallAPI,
    fieldErrors: duplicateProjectFormErrors,
    setFieldErrors: setDuplicateProjectFormErrors,
    loading: duplicateProjectLoading,
  } = useAPI<IDuplicateProjectResponse, IDuplicateProjectFormErrors>(
    (projectId: number, form: IDuplicateProjectForm) =>
      duplicateProject(projectId, form),
  );

  const {
    callAPI: getSummaryDataCallAPI,
    loading: loadingSummary,
    errored: getSummaryErrored,
  } = useAPI((projectId) => getSummaryData(Number(projectId)), {
    initialLoading: true,
  });

  const {
    callAPI: getProjectProformaDetailsCallAPI,
    errored: getProFormaFailed,
    loading: loadingProForma,
  } = useAPI(
    () => getProjectProformaDetails(Number(projectId), "AN", "accrual"),
    { initialLoading: true },
  );

  const {
    callAPI: addProjectCallAPI,
    fieldErrors: addProjectFormErrors,
    setFieldErrors: setAddProjectFormErrors,
    loading: addProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (form: IProjectForm) => addProject(form),
  );

  const getSummaryData = async (projectId: number) => {
    await Promise.all([
      handleGetProjectCost(projectId),
      handleGetProjectTiming(projectId),
      handleGetProjectProduction(projectId),
      handleGetProjectRevenueContracts(projectId),
    ]).then(() => {});
  };

  const handleGetProjectRevenueContracts = async (projectId: number) => {
    const response = await getProjectRevenueContracts(projectId).catch(
      () => null,
    );

    if (response) {
      setRevenueContracts(response.results);
    }
    return response;
  };

  const handleGetProjectCost = async (projectId: number) => {
    const response = await getProjectCost(projectId).catch(() => null);

    if (response) {
      setProjectCost(response);
    }
    return response;
  };

  const handleGetProjectProduction = async (id: number) => {
    const response = await getProjectProduction(id).catch(() => null);

    if (response) {
      setProjectProduction(response[0]);
    }
    return response;
  };

  const handleGetProjectTiming = async (projectId: number) => {
    const response = await getProjectTiming(projectId).catch(() => null);
    if (response) {
      setProjectTiming(response[0]);
      if (response[0]?.horizon === "OS") {
        setShowOperationPeriod(true);
      }
    }
    return response;
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const goToDeal = (dealId: number) => {
    window.open(`/deal/${dealId}/case-deal/${dealId}/general`, "_blank");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleUpdateProject = async (form: IProjectForm) => {
    const updatedProject = await updateProjectCallAPI(Number(projectId), form);

    if (project && updatedProject) {
      dispatch(
        setCurrentProjectAction({
          ...project,
          ...updatedProject,
        }),
      );
    }

    return updatedProject;
  };

  const handleCloseDuplicateProjectModal = () => {
    setOpenDuplicateProjectModal(false);
  };

  const handleCloseUpdateProjectModal = () => {
    setOpenUpdateProjectModal(false);
  };

  const handleDuplicateProject = async (form: IDuplicateProjectForm) => {
    const project = await duplicateProjectCallAPI(Number(projectId), form);

    if (project) {
      navigate(`/project/${project.id}/general`);
      toast.success("Project duplicated successfully!", {
        toastId: "project-duplication-success",
      });
    }

    return project;
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        project?.deals || [],
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [project?.deals, sortTable, page, rowsPerPage],
  );

  const handleOpenAddDealModal = () => {
    if (productUsageDetails?.strawman_plan === "TRIAL") {
      dispatch(updateShowUsageLimitHitModalAction(true));
      return;
    }
    setAddDealFormData((prev) => ({
      ...ADD_DEAL_FORM_DEFAULT_VAULES,
      project_ids: [String(projectId)],
    }));
    setOpenAddDealModal(true);
  };

  const handleAddDeal = async (form: IAddDealForm) => {
    const deal = await addDealCallAPI(form);

    if (deal) {
      const { project_count, projects, ...dealDetails } = deal;
      const dealData: IDealDetailsInProjectAPI_Call = {
        ...dealDetails,
        // TODO - Fix this, assuming these fields in forntend
        abc_file_path: "",
        capacity_ac: 0,
        case_id: null,
        parent_deal_id: null,
        tax_credit_type: "NONE",
        type_string: "",
        status: "ACT",
      };
      if (project) {
        dispatch(
          setCurrentProjectAction({
            ...project,
            deals: [...project.deals, { ...dealData }],
          }),
        );
      }
    }

    deal && goToDeal(deal.id);
    return deal;
  };

  const handleOpenAddProjectModal = () => {
    if (productUsageDetails?.strawman_plan === "TRIAL") {
      dispatch(updateShowUsageLimitHitModalAction(true));
      return;
    }
    setAddProjectModalOpen(true);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModalOpen(false);
  };

  const handleShowOperationPeriodToggle = () => {
    setShowOperationPeriod((s) => !s);
  };

  const handleOpenDuplicateProjectModal = () => {
    const { id, modified, created, ...formDetails } = project!;
    setDuplicateProjectForm({ ...formDetails });
    setOpenDuplicateProjectModal(true);
  };

  const handleOpenUpdateProjectModal = () => {
    if (project) {
      const { id, modified, created, ...formDetails } = project;
      setProjectForm({ ...formDetails });
      setOpenUpdateProjectModal(true);
    }
  };

  const goToProject = (projectId: number) => {
    if (ctrlPressed) {
      window.open(`/project/${projectId}/general/`, "_blank");
      return;
    }
    navigate(`/project/${projectId}/general/`);
  };

  const handleAddProject = async (form: IProjectForm) => {
    const project = await addProjectCallAPI(form);
    if (project) {
      if (openAddDealModal) {
        setAddDealFormData((prev) => ({
          ...prev,
          project_ids: [...prev.project_ids, String(project.id)],
        }));
        setProjectOptions((prev) => [
          ...prev,
          {
            label: project.name,
            value: String(project.id),
            status: project.status,
          },
        ]);
        return project;
      }
      goToProject(project.id);
    }
    return project;
  };

  const timingChartData: ITimingChartProps | undefined = React.useMemo(() => {
    if (projectTiming) {
      const {
        horizon,
        cod,
        start_date,
        end_date,
        ntp_date,
        mechanical_completion_date,
        placed_in_service_date,
        substantial_completion_date,
      } = projectTiming;

      const dataPoints = [
        // {
        //   date: new Date(start_date),
        //   color: "#FFA700",
        //   symbol: "rect",
        //   tooltip: "Start Date",
        //   id: "start",
        // },
        {
          date: new Date(ntp_date),
          color: "#F44336",
          symbol: "rectRot",
          tooltip: "Notice to Proceed",
          id: "ntp",
        },
        {
          date: new Date(mechanical_completion_date),
          color: "#00B7C3",
          symbol: "triangle",
          tooltip: "Mechanical Completion Date",
          rotation: 180,
          id: "mc",
        },
        {
          date: new Date(substantial_completion_date),
          color: "#007EC5",
          symbol: "triangle",
          tooltip: "Substantial Completion Date",
          id: "sc",
        },
        {
          date: new Date(placed_in_service_date),
          color: "#AB47BC",
          symbol: "circle",
          tooltip: "Placed In Service Date",
          id: "pis",
        },
        {
          date: new Date(cod),
          color: "#00B96A",
          symbol: "rect",
          tooltip: "COD",
          id: "cod",
        },
        ...(showOperationPeriod
          ? [
              {
                date: new Date(end_date),
                color: "#F44336",
                symbol: "circle",
                tooltip: "End Date",
                id: "end",
              },
            ]
          : []),
      ];

      const filteredDataPoints = dataPoints.filter((d) => {
        return horizon === "OS" ? d.id === "cod" || d.id === "end" : true;
      });

      return {
        startDate: new Date(start_date),
        endDate: new Date(end_date),
        data: filteredDataPoints,
      };
    }
    return undefined;
  }, [projectTiming, showOperationPeriod]);

  const navigateActionButton = (url: string) => {
    return (
      <IconButton onClick={() => navigate(url)}>
        <ArrowCircleRightIcon />
      </IconButton>
    );
  };

  const projectLogConfiguration: ILogsConfiguration = {
    id: Number(projectId),
    type: "project",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(projectLogConfiguration.type, projectLogConfiguration.id);
  };

  const handleDeleteProjectConfirm = async () => {
    const response = await deleteProjectCallAPI(Number(projectId));
    if (response) {
      toast.success("Project deleted successfully!", {
        toastId: "project-deletion-success",
      });
      navigate("/project-list");
    }
  };

  const handleDeleteProject = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Project",
        description: "Are you sure you want to delete this project?",
        onConfirm: handleDeleteProjectConfirm,
      }),
    );
  };

  return (
    <ViewWrapper loading={showLoader} error={projectStatus.errored}>
      <Box className={cn("flex justify-end mb-4")}>
        <Box className={cn("flex gap-4 items-center")}>
          <IconButton title="Open Logs" onClick={handleOnOpenLogs}>
            <LogsIcon />
          </IconButton>
          <ConditionalProtect
            type="project"
            permission={USER_PERMISSIONS.DEALS_CRUD}
          >
            <ActionButton
              canOpenUpgrade
              actionType="add"
              onClick={handleOpenAddDealModal}
            >
              Add to new Deal
            </ActionButton>
          </ConditionalProtect>
          <ConditionalProtect
            type="project"
            permission={USER_PERMISSIONS.PROJECTS_CRUD}
          >
            <ActionButton
              canOpenUpgrade
              actionType="duplicate"
              dataPw="duplicate"
              onClick={handleOpenDuplicateProjectModal}
            >
              Duplicate
            </ActionButton>
            <ActionButton
              canOpenUpgrade
              actionType="edit"
              dataPw="edit"
              onClick={handleOpenUpdateProjectModal}
            >
              Edit
            </ActionButton>
          </ConditionalProtect>

          {project?.status === "ARCH" && (
            <Protect permission={USER_PERMISSIONS.DATA_DELETE}>
              <ActionButton
                canOpenUpgrade
                actionType="delete"
                onClick={handleDeleteProject}
              >
                Delete Project
              </ActionButton>
            </Protect>
          )}
        </Box>
      </Box>

      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={6}>
          <ViewWrapper
            loading={projectStatus.loading}
            error={projectStatus.errored}
          >
            <DetailsCard
              heading="Summary"
              sections={[
                {
                  fields: [
                    {
                      label: "Sponsor Name",
                      value: {
                        text: project?.sponsor_name || "",
                      },
                    },
                    {
                      label: "Project Name",
                      value: {
                        text: project?.name || "",
                      },
                    },
                    {
                      label: "Project Type",
                      value: {
                        text: PROJECT_TYPES[
                          project?.type as keyof typeof PROJECT_TYPES
                        ],
                      },
                    },
                    {
                      label: "Project State",
                      value: {
                        text: US_STATES[
                          project?.state as keyof typeof US_STATES
                        ],
                      },
                    },
                    {
                      label: "Power Market",
                      value: {
                        text: MARKETS[
                          project?.power_market as keyof typeof MARKETS
                        ],
                      },
                    },
                    {
                      label: "Project Stage",
                      value: {
                        text:
                          project?.stage +
                          ": " +
                          PROJECT_STAGES[
                            project?.sub_stage as keyof typeof PROJECT_STAGES
                          ],
                      },
                    },
                  ],
                },
              ]}
            />
          </ViewWrapper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ViewWrapper
            loading={projectStatus.loading || loadingSummary}
            error={projectStatus.errored || getSummaryErrored}
          >
            <DetailsCard
              heading="Other Key Information"
              sections={[
                {
                  fields: [
                    {
                      label:
                        project?.type === "BAST"
                          ? "Storage Power Capacity"
                          : "Project Capacity (AC)",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/project/${projectId}/pro-forma/production`}
                            text={String(
                              projectProduction?.capacity_ac + " MW",
                            )}
                          />
                        ),
                      },
                    },
                    ...(project?.type === "BAST"
                      ? [
                          {
                            label: "Storage Energy Capacity",
                            value: {
                              text: (
                                <TextAsLink
                                  path={`/project/${projectId}/pro-forma/production`}
                                  text={String(
                                    projectProduction?.capacity_energy_mwh +
                                      " MWh",
                                  )}
                                />
                              ),
                            },
                          },
                        ]
                      : []),
                    // Hide below fields for Battery Storage projects
                    ...(project?.type !== "BAST"
                      ? [
                          {
                            label: "Project Capacity (DC)",
                            value: {
                              text: (
                                <TextAsLink
                                  path={`/project/${projectId}/pro-forma/production`}
                                  text={String(
                                    projectProduction?.capacity_dc + " MW",
                                  )}
                                />
                              ),
                            },
                          },
                          {
                            label: "Gross NCF",
                            value: {
                              text: (
                                <TextAsLink
                                  path={`/project/${projectId}/pro-forma/production`}
                                  text={String(
                                    projectProduction?.ncf_percentage + "%",
                                  )}
                                />
                              ),
                            },
                          },
                          {
                            label: "Gross Production",
                            value: {
                              text: (
                                <TextAsLink
                                  path={`/project/${projectId}/pro-forma/production`}
                                  text={
                                    numberWithCommas.format(
                                      projectProduction?.gross_production_mwh ||
                                        0,
                                    ) + " MWh"
                                  }
                                />
                              ),
                            },
                          },
                        ]
                      : []),

                    {
                      label: "Commercial Operations Date (COD)",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/project/${projectId}/timing`}
                            text={projectTiming?.cod || ""}
                          />
                        ),
                      },
                    },
                    {
                      label: "Project Operating Life",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/project/${projectId}/timing`}
                            text={projectTiming?.operating_life_years + " Yrs"}
                          />
                        ),
                      },
                    },
                    {
                      label: "FMV",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/project/${projectId}/cost`}
                            text={numberToUSD.format(
                              projectCost?.data.project_cost.fmv || 0,
                            )}
                          />
                        ),
                      },
                    },
                    {
                      label: "Revenue Contracts",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/project/${projectId}/pro-forma/revenue`}
                            text={
                              revenueContracts.length === 0
                                ? "None"
                                : revenueContracts
                                    .map(
                                      (r) =>
                                        PROJECT_REVENUE_CONTRACT_TYPE[r.type],
                                    )
                                    .join(", ")
                            }
                          />
                        ),
                      },
                    },
                    {
                      label: "Tax Credit Type",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/project/${projectId}/tax/tax-credit`}
                            text={
                              PROJECT_TAX_CREDIT_TYPE[
                                project?.tax_credit_type as keyof typeof PROJECT_TAX_CREDIT_TYPE
                              ]
                            }
                          />
                        ),
                      },
                    },
                  ],
                },
              ]}
            />
          </ViewWrapper>
        </Grid>
      </Grid>

      <ViewWrapper loading={loadingSummary} error={getSummaryErrored}>
        {timingChartData && (
          <ChartWrapper
            title="Timing"
            primaryButton={navigateActionButton(`/project/${projectId}/timing`)}
          >
            <Box>
              <CheckboxInput
                checked={showOperationPeriod}
                label="Show Operations Period"
                onChange={handleShowOperationPeriodToggle}
              />
              <TimingChart {...timingChartData} />
            </Box>
          </ChartWrapper>
        )}
      </ViewWrapper>

      <ViewWrapper loading={loadingProForma} error={getProFormaFailed}>
        <Chart
          chartData={projectProforma.proforma_data}
          chartFormat="summary"
          chartElementConfig={PROFORMA_TABLE_CONFIG}
          primaryButton={navigateActionButton(
            `/project/${projectId}/pro-forma`,
          )}
          selectedBasis="accrual"
          hideMwAxis={project?.type === "BAST"} // hide MW axis for Battery Storage Project
        />
      </ViewWrapper>

      <ViewWrapper
        loading={projectStatus.loading}
        error={projectStatus.errored}
      >
        <Box
          id="project-deals"
          className={cn("flex justify-between items-center")}
        >
          <Typography variant="h6">
            Deals associated with this Project
          </Typography>
          <ConditionalProtect
            type="project"
            permission={USER_PERMISSIONS.DEALS_CRUD}
          >
            <Button
              canOpenUpgrade
              startIcon={<AddIcon />}
              btnType="primary"
              label="Add to new Deal"
              onClick={handleOpenAddDealModal}
            />
          </ConditionalProtect>
        </Box>
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className={cn("mt-4")}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={styles.classes.tableHead}>
                <TableRow>
                  {dealsOfProjectColumns.map((column, idx) => {
                    return (
                      <TableCell
                        key={idx}
                        align={column?.align as "left" | "right"}
                        style={{ minWidth: column?.minWidth }}
                      >
                        <TableSortLabel
                          active={sortTable.orderBy === column?.id}
                          direction={
                            sortTable.orderBy === column?.id
                              ? sortTable.order
                              : "asc"
                          }
                          onClick={() => sortRows(column?.id)}
                        >
                          {column?.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {project?.deals?.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      There are no Deals.
                      <br />
                      <ConditionalProtect
                        type="project"
                        permission={USER_PERMISSIONS.DEALS_CRUD}
                      >
                        <Link
                          component="button"
                          onClick={handleOpenAddDealModal}
                        >
                          Add to new Deal
                        </Link>
                      </ConditionalProtect>
                    </TableCell>
                  </TableRow>
                )}
                {visibleRows?.map((deal, idx) => {
                  return (
                    <TableRow
                      hover
                      key={idx}
                      tabIndex={-1}
                      className={cn("cursor-pointer")}
                      onClick={() => goToDeal(deal?.id)}
                    >
                      <TableCell align="left">
                        {trimString(deal?.name, 40)}
                      </TableCell>
                      <TableCell align="left">
                        {DEAL_STAGES[deal?.stage as keyof typeof DEAL_STAGES]}
                      </TableCell>
                      <TableCell align="left">
                        {
                          DEAL_STRUCTURE_TYPE[
                            deal?.structure as keyof typeof DEAL_STRUCTURE_TYPE
                          ]
                        }
                      </TableCell>
                      <TableCell align="left">
                        {
                          DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                            deal?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                          ]
                        }
                      </TableCell>
                      <TableCell align="left">{deal?.type_string}</TableCell>
                      <TableCell align="left">{deal?.capacity_ac} MW</TableCell>
                      <TableCell align="left">
                        <Chip
                          label={DEAL_STATUS[deal.status]}
                          color={getFilledChipColor(deal.status).color}
                          filledBackgroundColor={
                            getFilledChipColor(deal.status).backgroundColor
                          }
                          variant="filled"
                        />
                      </TableCell>
                      <TableCell align="left">
                        {format(new Date(deal?.modified), "M/d/yyyy")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={project?.deals?.length || 0}
            className={styles.classes.paginationRow}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </ViewWrapper>

      <ProjectFormModal
        open={openUpdateProjectModal}
        loading={updateProjectLoading}
        onClose={handleCloseUpdateProjectModal}
        formErrors={updateProjectFormErrors}
        setFormErrors={setUpdateProjectFormErrors}
        form={projectForm}
        setForm={setProjectForm}
        headerLabel="Edit project details"
        onConfirm={handleUpdateProject}
        hide={["type"]}
      />

      <ProjectDuplicateModal
        open={openDuplicateProjectModal}
        loading={duplicateProjectLoading}
        onClose={handleCloseDuplicateProjectModal}
        formErrors={duplicateProjectFormErrors}
        setFormErrors={setDuplicateProjectFormErrors}
        form={projectDuplicateForm}
        setForm={setDuplicateProjectForm}
        headerLabel="Duplicate Project"
        onConfirm={handleDuplicateProject}
      />

      <AddDealFormModal
        open={openAddDealModal}
        formErrors={addDealFormErrors}
        loading={addDealLoading}
        setFormErrors={setAddDealFormErrors}
        form={addDealFormData}
        setForm={setAddDealFormData}
        onClose={() => setOpenAddDealModal(false)}
        existingProjects={projectOptions}
        onAdd={handleAddDeal}
        openAddProjectModal={handleOpenAddProjectModal}
      />

      <ProjectFormModal
        open={addProjectModalOpen}
        headerLabel="Add a new Project"
        form={projectForm}
        loading={addProjectLoading}
        setForm={setProjectForm}
        formErrors={addProjectFormErrors}
        setFormErrors={setAddProjectFormErrors}
        onClose={handleCloseAddProjectModal}
        onConfirm={handleAddProject}
        formType={FORM_TYPES.CREATE}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectLogConfiguration.id}
        />
      </LogsWrapper>
    </ViewWrapper>
  );
}
