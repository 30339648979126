import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import LogsIcon from "@mui/icons-material/Restore";
import { IconButton } from "@mui/material";
import { isNumber } from "lodash";
import { Protect } from "@clerk/clerk-react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";

import useStyles from "./styles";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard, {
  IDetailsCardSection,
} from "../../../../components/details-card";
import EditTaxCreditTransfer from "../../../../components/edit-tax-credit-transfer-form-modal";
import EditDealTaxCreditTransfer from "../../../../components/edit-deal-tax-credit-transfer-form-modal";
import DealTimingFormModal from "../../../../components/deal-timing-form-modal";
import LogsWrapper from "../../../../components/logs-wrapper";
import Logs from "../../../../components/logs";
import ConditionalProtect from "../../../../components/conditional-protect";
import { getDealTiming } from "../../../../apis/deal/timing";
import {
  cn,
  getAbbreviatedMonthName,
  numberToUSD,
} from "../../../../utils/helpers";
import {
  useAPI,
  useAppSelector,
  useDrawer,
  useLogs,
} from "../../../../utils/hooks";
import {
  USER_PERMISSIONS,
  DEAL_TAX_CREDIT_TRANSFER_PERIODICITY,
  DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
  UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  IDealTaxCreditTransfer,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferResults,
  IDealTaxCreditTransferFormErrors,
  ILogsConfiguration,
  IDealTaxCreditTransferPortfolio,
  IDealTaxCreditTransferPortfolioForm,
  IDealTaxCreditTransferPortfolioFormErrors,
  IDealTiming,
  IUpdateDealTimingForm,
  IUpdateDealTimingFormErrors,
} from "../../../../interfaces";

interface IProps {
  getDealTaxCreditTransferDetails: (
    id: number,
  ) => Promise<IDealTaxCreditTransfer[]>;
  updateDealTaxCreditTransferDetails: (
    dealId: number,
    transferSizingId: number,
    form: IDealTaxCreditTransferForm,
  ) => Promise<IDealTaxCreditTransferResults>;
  updateDealTransferPortfolio: (
    dealId: number,
    transferConfigId: number,
    form: IDealTaxCreditTransferPortfolioForm,
  ) => Promise<IDealTaxCreditTransferPortfolio>;
  updateDealTiming: (
    dealId: number,
    timingId: number,
    form: IUpdateDealTimingForm,
  ) => Promise<IDealTiming>;
  getDealTransferPortfolio: (
    dealId: number,
  ) => Promise<IDealTaxCreditTransferPortfolio[]>;
}

export default function DealTaxCreditTransferView({
  getDealTaxCreditTransferDetails,
  updateDealTaxCreditTransferDetails,
  updateDealTransferPortfolio,
  updateDealTiming,
  getDealTransferPortfolio,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealId, caseDealId } = useParams();
  const { currentDeal } = useAppSelector((s) => s.deal);

  const [dealTransferPortfolio, setDealTransferPortfolio] =
    React.useState<IDealTaxCreditTransferPortfolio>();
  const [dealTaxCreditTransfers, setdealTaxCreditTransfer] = React.useState<
    IDealTaxCreditTransfer[]
  >([]);
  const [form, setForm] = React.useState<IDealTaxCreditTransferForm>(
    DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  );

  const [
    taxCreditTransferConstraintsForm,
    setTaxCreditTransferConstraintsForm,
  ] = React.useState<IDealTaxCreditTransferPortfolioForm>(
    UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
  );

  const [openTransferPortfolioModal, setOpenTransferPortfolioModal] =
    React.useState<boolean>(false);
  const [openTransferEditModal, setOpenTransferEditModal] =
    React.useState<boolean>(false);
  const [updateDealTimingModalOpen, setUpdateDealTimingModalOpen] =
    React.useState<boolean>(false);
  const [dealTiming, setDealTiming] = React.useState<IDealTiming>();
  const [dealTimingForm, setDealTimingForm] =
    React.useState<IUpdateDealTimingForm>(
      UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE,
    );

  const {
    callAPI: getDealTimingCallAPI,
    errored: getTimingFailed,
    loading: loadingTiming,
  } = useAPI((dealId: number) => getDealTiming(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: getDealTransferPortfolioCallAPI,
    errored: getDealTransferPortfolioFailed,
    loading: loadingDealTransferPortfolio,
  } = useAPI((dealId: number) => getDealTransferPortfolio(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: getDealTaxCreditDetailsCallAPI,
    errored: getDealProformaFailed,
    loading: loadingDealTaxCreditTransfer,
  } = useAPI((dealId: number) => getDealTaxCreditTransferDetails(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: updateDealTransfePortfolioCallAPI,
    loading: loadingDealTransferPortfolioUpdate,
    fieldErrors: dealFormErrors,
    setFieldErrors: setDealFormErrors,
  } = useAPI<
    IDealTaxCreditTransferPortfolio,
    IDealTaxCreditTransferPortfolioFormErrors
  >(
    (
      dealId: number,
      transferConfigId: number,
      form: IDealTaxCreditTransferPortfolioForm,
    ) => updateDealTransferPortfolio(dealId, transferConfigId, form),
  );

  const {
    callAPI: updateDealTimingCallAPI,
    fieldErrors: updateDealTimingFormErrors,
    setFieldErrors: setUpdateDealTimingFormErrors,
    loading: updateDealTimingLoading,
  } = useAPI<IDealTiming, IUpdateDealTimingFormErrors>(
    (dealId: number, timingId: number, form: IUpdateDealTimingForm) =>
      updateDealTiming(dealId, timingId, form),
  );

  const {
    callAPI: updateDealTaxCreditTransferDetailsCallAPI,
    loading: loadingUpdateDealTaxCreditTransferDetails,
    fieldErrors: formErrors,
    setFieldErrors: setFormErrors,
  } = useAPI<IDealTaxCreditTransferResults, IDealTaxCreditTransferFormErrors>(
    (
      dealId: number,
      form: IDealTaxCreditTransferForm,
      transferSizingId: number,
    ) => updateDealTaxCreditTransferDetails(dealId, transferSizingId, form),
  );

  const getDealTaxCreditDetails = (dealId: number) => {
    getDealTaxCreditDetailsCallAPI(dealId).then((response) => {
      response && setdealTaxCreditTransfer(response);
    });
  };

  React.useEffect(() => {
    getDealTransferPortfolioCallAPI(Number(caseDealId)).then((res) => {
      res?.length && setDealTransferPortfolio(res[0]);
    });
    getDealTaxCreditDetails(Number(caseDealId));
    getDealTimingCallAPI(Number(caseDealId)).then((res) => {
      res?.length && setDealTiming(res[0]);
    });
  }, [caseDealId]);

  const rows = [
    {
      key: "Key Terms",
    },
    {
      key: "Tax Credit Type",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          d?.project_credit_type || "N/A",
        ]),
      ),
    },
    {
      key: "Transfer Portion",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d?.project?.name,
          d?.transfer_sizing?.transfer_portion + "%" || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Rate",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d?.project?.name,
          Number(d?.transfer_sizing?.payment_rate).toFixed(4) + " $/Credit" ||
            "N/A",
        ]),
      ),
    },
    {
      key: "Payment Periodicity",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d?.project?.name,
          DEAL_TAX_CREDIT_TRANSFER_PERIODICITY[
            d?.transfer_sizing
              ?.payment_periodicity as keyof typeof DEAL_TAX_CREDIT_TRANSFER_PERIODICITY
          ] || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Reference Date",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(new Date(d?.payment_reference_date), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Dates Lag",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          d?.transfer_sizing?.payment_dates_lag + " months",
        ]),
      ),
    },
    {
      key: "Payment Date Num 1",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(new Date(d?.payment_date_num_1), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Key Dates",
    },
    {
      key: "Placed in Service Date",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(new Date(d?.placed_in_service_date), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Pre-Filing Date (per project)",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(new Date(d?.transfer_sizing?.pre_filing_date), "M/dd/yyyy") ||
            "N/A",
        ]),
      ),
    },
    {
      key: "Seller's Tax Filing Date",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(
            new Date(d?.transfer_sizing?.tct_seller_tax_filing_date) || "N/A",
            "M/dd/yyyy",
          ),
        ]),
      ),
    },
    {
      key: "Buyer's Tax Filing Date",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(
            new Date(d?.transfer_sizing?.tct_buyer_tax_filing_date) || "N/A",
            "M/dd/yyyy",
          ),
        ]),
      ),
    },
    {
      key: "Seller's Tax Year Num 1",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          d?.transfer_sizing?.tct_sellers_tax_year_num_1,
        ]),
      ),
    },
    {
      key: "Buyer's Tax Year Num 1",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          d?.transfer_sizing?.tct_buyers_tax_year_num_1,
        ]),
      ),
    },
  ];

  const columns = dealTaxCreditTransfers.map((d) => {
    return {
      name: d.project.name,
      id: d.transfer_sizing?.id,
      projectId: d?.project?.id,
    };
  });

  const openDealTaxCreditEditModal = (id: number) => {
    setOpenTransferEditModal(true);
    const dealTaxCreditTransfer = dealTaxCreditTransfers.find((d) => {
      return d.transfer_sizing.id === id;
    });
    setForm({
      id: String(id),
      payment_dates_lag:
        dealTaxCreditTransfer?.transfer_sizing?.payment_dates_lag ?? "",
      payment_date_num_1: dealTaxCreditTransfer?.payment_date_num_1 ?? "",
      payment_periodicity:
        dealTaxCreditTransfer?.transfer_sizing?.payment_periodicity ?? "",
      payment_rate: dealTaxCreditTransfer?.transfer_sizing?.payment_rate ?? "",
      placed_in_service_date:
        dealTaxCreditTransfer?.placed_in_service_date ?? "",
      pre_filing_date:
        dealTaxCreditTransfer?.transfer_sizing?.pre_filing_date ?? "",
      transfer_portion:
        dealTaxCreditTransfer?.transfer_sizing?.transfer_portion ?? "",
      isPTC: dealTaxCreditTransfer?.project_credit_type === "PTC",
      isITC: dealTaxCreditTransfer?.project_credit_type === "ITC",
      creditStartDate: "itc start date",
      creditEndDate: "itc end date",
    });
  };

  const openDealTaxCreditPortfolioEditModal = () => {
    setOpenTransferPortfolioModal(true);
    setTaxCreditTransferConstraintsForm({
      transfer_discount_rate:
        dealTransferPortfolio?.transfer_discount_rate ?? "",
      transfer_maximum_payment:
        dealTransferPortfolio?.transfer_maximum_payment ?? "",
      transfer_minimum_roi: dealTransferPortfolio?.transfer_minimum_roi ?? "",
    });
  };

  const handleEditDealTaxCreditTransfer = async (
    form: IDealTaxCreditTransferForm,
  ) => {
    const updatedForm = {
      payment_dates_lag: form.payment_dates_lag,
      payment_date_num_1: form.payment_date_num_1,
      payment_periodicity: form.isPTC ? form.payment_periodicity : null,
      payment_rate: form.payment_rate,
      placed_in_service_date: form.placed_in_service_date,
      pre_filing_date: form.pre_filing_date,
      transfer_portion: form.transfer_portion,
    };
    const { id } = form;
    const dealTaxCreditTransfer =
      await updateDealTaxCreditTransferDetailsCallAPI(
        Number(caseDealId),
        updatedForm,
        Number(id),
      );
    dealTaxCreditTransfer && getDealTaxCreditDetails(Number(caseDealId));
    dealTaxCreditTransfer && setOpenTransferEditModal(false);

    return dealTaxCreditTransfer;
  };

  const handleEditDealTaxCreditTransferPortfolio = async (
    form: IDealTaxCreditTransferPortfolioForm,
  ) => {
    const updatedPortfolio = await updateDealTransfePortfolioCallAPI(
      Number(caseDealId),
      dealTransferPortfolio?.id,
      form,
    );
    updatedPortfolio && setDealTransferPortfolio(updatedPortfolio);
    return updatedPortfolio;
  };

  const handleOpenUpdateDealTimingModal = () => {
    const {
      developer_horizon,
      tct_seller_tax_year_end_month,
      tct_buyer_tax_year_end_month,
      tct_seller_tax_filing_month,
      tct_buyer_tax_filing_month,
    } = dealTiming!;
    setDealTimingForm({
      developer_horizon,
      tct_seller_tax_year_end_month,
      tct_buyer_tax_year_end_month,
      tct_seller_tax_filing_month,
      tct_buyer_tax_filing_month,
    });
    setUpdateDealTimingModalOpen(true);
  };

  const handleUpdateDealTiming = async (form: IUpdateDealTimingForm) => {
    const updatedForm = form;
    if (currentDeal?.tax_credit_structure !== "TR") {
      delete updatedForm.tct_buyer_tax_filing_month;
      delete updatedForm.tct_buyer_tax_year_end_month;
      delete updatedForm.tct_seller_tax_filing_month;
      delete updatedForm.tct_seller_tax_year_end_month;
    }
    const timing = await updateDealTimingCallAPI(
      Number(caseDealId),
      dealTiming!.id,
      updatedForm,
    );
    timing && setDealTiming(timing);

    return timing;
  };

  const handleCloseUpdateDealTimingModal = () => {
    setUpdateDealTimingModalOpen(false);
  };

  // transfer sizing logs
  const {
    loadMoreLogs: loadMoreTransferSizingLogs,
    loadingLogs: loadingTransferSizingLogs,
    logs: transferSizingLogs,
    onCloseLogs: onCloseTransferSizingLogs,
    onOpenLogs: onOpenTransferSizingLogs,
    pagination: transferSizingPagination,
  } = useLogs();

  const {
    handleCloseDrawer: handleCloseTransferSizingDrawer,
    handleOpenDrawer: handleOpenTransferSizingDrawer,
    isDrawerOpen: isTransferSizingDrawerOpen,
  } = useDrawer({
    onOpen: onOpenTransferSizingLogs,
    onClose: onCloseTransferSizingLogs,
  });

  const dealTransferSizingLogsConfiguration: ILogsConfiguration = {
    id: dealTaxCreditTransfers
      .map((transfer) => transfer.transfer_sizing.id)
      .toString(), // sending all transfer sizing ids in a string separated by commas
    type: "transfersizing",
  };

  const handleOnOpenTransferSizingLogs = () => {
    handleOpenTransferSizingDrawer(
      dealTransferSizingLogsConfiguration.type,
      dealTransferSizingLogsConfiguration.id,
    );
  };

  // portfolio logs
  const {
    loadMoreLogs: loadMorePortfolioLogs,
    loadingLogs: loadingPortfolioLogs,
    logs: portfolioLogs,
    onCloseLogs: onClosePortfolioLogs,
    onOpenLogs: onOpenPortfolioLogs,
    pagination: portfolioPagination,
  } = useLogs();

  const {
    handleCloseDrawer: handleClosePortfolioDrawer,
    handleOpenDrawer: handleOpenPortfolioDrawer,
    isDrawerOpen: isPortfolioDrawerOpen,
  } = useDrawer({
    onOpen: onOpenPortfolioLogs,
    onClose: onClosePortfolioLogs,
  });

  const dealPortfolioLogsConfiguration: ILogsConfiguration = {
    id: dealTransferPortfolio?.id ?? "",
    type: "dealtransferconfig",
  };

  const handleOnOpenPortfolioLogs = () => {
    handleOpenPortfolioDrawer(
      dealPortfolioLogsConfiguration.type,
      dealPortfolioLogsConfiguration.id,
    );
  };

  const handleCloseEditPortfolioModal = () => {
    setOpenTransferPortfolioModal(false);
  };

  const handleCloseEditTransferModal = () => {
    setOpenTransferEditModal(false);
  };

  const portfolioSettingsDetails: IDetailsCardSection[] = React.useMemo(() => {
    return [
      {
        heading: "Additional Parameters",
        fields: [
          {
            label: "Discount Rate",
            value: {
              text: isNumber(dealTransferPortfolio?.transfer_discount_rate)
                ? dealTransferPortfolio?.transfer_discount_rate + "%"
                : "N/A",
            },
          },
        ],
      },
      {
        heading: "Constraints",
        fields: [
          {
            label: "Minimum ROI",
            value: {
              text: isNumber(dealTransferPortfolio?.transfer_minimum_roi)
                ? dealTransferPortfolio?.transfer_minimum_roi + "%"
                : "N/A",
            },
          },
          {
            label: "Maximum Payment",
            value: {
              text: isNumber(dealTransferPortfolio?.transfer_maximum_payment)
                ? numberToUSD.format(
                    dealTransferPortfolio?.transfer_maximum_payment ?? 0,
                  )
                : "N/A",
            },
          },
        ],
      },
    ];
  }, [dealTransferPortfolio]);

  const timingDetails: IDetailsCardSection[] = React.useMemo(() => {
    return [
      {
        fields: [
          {
            label: "Seller's Tax Year End Month",
            value: {
              text:
                getAbbreviatedMonthName(
                  Number(dealTiming?.tct_seller_tax_year_end_month),
                ) || "N/A",
            },
          },
          {
            label: "Buyer's Tax Year End Month",
            value: {
              text:
                getAbbreviatedMonthName(
                  Number(dealTiming?.tct_buyer_tax_year_end_month),
                ) || "N/A",
            },
          },
          {
            label: "Seller's Tax Year Filling Month",
            value: {
              text:
                getAbbreviatedMonthName(
                  Number(dealTiming?.tct_seller_tax_filing_month),
                ) || "N/A",
            },
          },
          {
            label: "Buyer's Tax Filling Month",
            value: {
              text:
                getAbbreviatedMonthName(
                  Number(dealTiming?.tct_buyer_tax_filing_month),
                ) || "N/A",
            },
          },
        ],
      },
    ];
  }, [dealTiming]);

  const PortfolioLogButton = (
    <IconButton onClick={handleOnOpenPortfolioLogs}>
      <LogsIcon />
    </IconButton>
  );

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  return (
    <>
      <ViewWrapper
        loading={loadingDealTaxCreditTransfer}
        error={getDealProformaFailed}
      >
        <Box>
          {dealTaxCreditTransfers.length === 0 ? (
            <Box className={styles.classes.message}>
              <Typography variant="body1" className={styles.classes.message}>
                This Deal does not have any Projects that have elected Tax
                Credit Transfer.
              </Typography>
            </Box>
          ) : (
            <React.Fragment>
              <Box className={cn("flex justify-end mb-4")}>
                <IconButton onClick={handleOnOpenTransferSizingLogs}>
                  <LogsIcon />
                </IconButton>
              </Box>
              <Box className={cn("grid md:grid-cols-2 gap-4 mb-4")}>
                <ViewWrapper
                  loading={loadingDealTransferPortfolio}
                  error={getDealTransferPortfolioFailed}
                >
                  <DetailsCard
                    heading="Portfolio Settings"
                    sections={portfolioSettingsDetails}
                    onEditClick={openDealTaxCreditPortfolioEditModal}
                    secondaryButton={PortfolioLogButton}
                  />
                </ViewWrapper>
                <ViewWrapper loading={loadingTiming} error={getTimingFailed}>
                  <DetailsCard
                    heading="Timing"
                    sections={timingDetails}
                    onEditClick={handleOpenUpdateDealTimingModal}
                  />
                </ViewWrapper>
              </Box>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead className={styles.classes.header}>
                      <TableRow>
                        <TableCell></TableCell>
                        {columns.map((c, idx) => {
                          return (
                            <TableCell key={idx}>
                              <Link
                                to={`/project/${c?.projectId}/tax/tax-credit`}
                                target="_blank"
                              >
                                {c?.name}
                              </Link>
                              <br />
                              {!isOnCase && (
                                <ConditionalProtect
                                  type="deal"
                                  permission={USER_PERMISSIONS.DEALS_CRUD}
                                >
                                  <Button
                                    canOpenUpgrade
                                    btnType="primary"
                                    label="Edit"
                                    size="small"
                                    startIcon={<EditIcon />}
                                    onClick={() =>
                                      openDealTaxCreditEditModal(c?.id)
                                    }
                                  />
                                </ConditionalProtect>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.map((row: { [key: string]: string }, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                fontWeight: ["Key Dates", "Key Terms"].includes(
                                  row?.key,
                                )
                                  ? "bold"
                                  : "",
                              }}
                            >
                              {row.key}
                            </TableCell>
                            {columns.map((c, sIdx) => {
                              return (
                                <TableCell key={sIdx}>{row[c?.name]}</TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </React.Fragment>
          )}
        </Box>
      </ViewWrapper>

      <EditTaxCreditTransfer
        headerLabel="Edit Tax Credit Transfer"
        loading={loadingUpdateDealTaxCreditTransferDetails}
        form={form}
        setForm={setForm}
        onClose={handleCloseEditTransferModal}
        open={openTransferEditModal}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        onConfirm={handleEditDealTaxCreditTransfer}
      />

      <DealTimingFormModal
        headerLabel="Edit Timing"
        open={updateDealTimingModalOpen}
        loading={updateDealTimingLoading}
        onClose={handleCloseUpdateDealTimingModal}
        onConfirm={handleUpdateDealTiming}
        formErrors={updateDealTimingFormErrors}
        setFormErrors={setUpdateDealTimingFormErrors}
        form={dealTimingForm}
        setForm={setDealTimingForm}
        isTransfer={currentDeal?.tax_credit_structure === "TR"}
        hide={["developer_horizon"]}
      />

      <EditDealTaxCreditTransfer
        headerLabel="Edit Portfolio Tax Credit Transfer"
        loading={loadingDealTransferPortfolioUpdate}
        form={taxCreditTransferConstraintsForm}
        setForm={setTaxCreditTransferConstraintsForm}
        onClose={handleCloseEditPortfolioModal}
        open={openTransferPortfolioModal}
        formErrors={dealFormErrors}
        setFormErrors={setDealFormErrors}
        onConfirm={handleEditDealTaxCreditTransferPortfolio}
      />

      <LogsWrapper
        onClose={handleCloseTransferSizingDrawer}
        open={isTransferSizingDrawerOpen}
      >
        <Logs
          nextPage={loadMoreTransferSizingLogs}
          logs={transferSizingLogs}
          type={dealTransferSizingLogsConfiguration.type}
          loading={loadingTransferSizingLogs}
          totalLogs={transferSizingPagination.totalItems}
          id={dealTransferSizingLogsConfiguration.id}
        />
      </LogsWrapper>

      <LogsWrapper
        onClose={handleClosePortfolioDrawer}
        open={isPortfolioDrawerOpen}
      >
        <Logs
          nextPage={loadMorePortfolioLogs}
          logs={portfolioLogs}
          type={dealPortfolioLogsConfiguration.type}
          loading={loadingPortfolioLogs}
          totalLogs={portfolioPagination.totalItems}
          id={dealPortfolioLogsConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
