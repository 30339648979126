import { PROJECT_API_ROUTES } from "../../constants";
import {
  AxiosHelper,
  replaceParams,
  transformBlobToFile,
} from "../../utils/helpers";
import {
  IProjectValuation,
  IProjectValuationForm,
  IReportTable,
} from "../../interfaces";

export const getProjectValuation = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.get, {
    projectId,
  });
  return await AxiosHelper.get<IProjectValuation[]>(url);
};

export const getProjectValuationCalculations = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.getCalcuations, {
    projectId,
  });
  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadProjectValuationCalculations_Report = async (
  projectId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.valuation.downloadCalculations_Report,
    {
      projectId,
    },
  );
  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(
      response,
      `project-${projectId}-valuation-calculation-report.xlsx`,
    );
  });
};

export const addProjectValuation = async (
  projectId: number,
  form: IProjectValuationForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.create, {
    projectId,
  });
  return await AxiosHelper.post<IProjectValuationForm, IProjectValuation>(
    url,
    form,
  );
};

export const editProjectValuation = async (
  projectId: number,
  valuationId: number,
  form: IProjectValuationForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.update, {
    projectId,
    valuationId,
  });
  return await AxiosHelper.put<IProjectValuationForm, IProjectValuation>(
    url,
    form,
  );
};
