import { makeStyles } from "tss-react/mui";

export default makeStyles<{ columnToHide: number }>()(
  (theme, { columnToHide }) => ({
    tableWithHideableColumn: {
      "& td:not(:last-child), & th:not(:last-child)": {
        textAlign: "left",
      },
      "& td:last-child, & th:last-child": {
        textAlign: "right",
      },
      '&[data-hide-total="true"]': {
        [`& tbody td:nth-of-type(${columnToHide})`]: {
          display: "none",
        },
        [`& thead th:nth-of-type(${columnToHide})`]: {
          display: "none",
        },
      },
    },
  }),
);
