import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    iconButton: {
      backgroundColor: `${selectedTheme.color.aboveSurface} !important`,
      ":hover": {
        backgroundColor: `${selectedTheme.color.surface} !important`,
      },
    },
  };
});
