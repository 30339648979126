import ProjectRevenueContractDetails from "../../../../../views/project/pro-forma/revenue/revenue-contract/details";
import ProjectRevenueLayout from "../../../../../components/project-revenue-layout";
import ProjectPagesLayout from "../../../../../components/project-pages-layout";
import TabbedLayout from "../../../../../components/tabbed-layout";
import { getCurves } from "../../../../../apis/configuration/base";
import { getProjectTiming } from "../../../../../apis/project/timing";
import {
  getProjectRevenueContractDetails,
  editProjectRevenueContract,
  deleteProjectRevenueContract,
  getProjectContractedRevenueComponents,
} from "../../../../../apis/project/revenue-contracts";

export default function RevenueDetails(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectRevenueLayout>
          <ProjectRevenueContractDetails
            getProjectTiming={getProjectTiming}
            getProjectRevenueContractDetails={getProjectRevenueContractDetails}
            editProjectRevenueContract={editProjectRevenueContract}
            deleteProjectRevenueContract={deleteProjectRevenueContract}
            getCurves={getCurves}
            getProjectContractedRevenueComponents={
              getProjectContractedRevenueComponents
            }
          />
        </ProjectRevenueLayout>
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
