import { PROJECT_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IProjectExpense,
  IProjectExpenseForm,
  IReportTable,
  ServerPaginatedResponse,
} from "../../interfaces";

export const getProjectExpenses = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.expense.get, { projectId });
  return await AxiosHelper.get<ServerPaginatedResponse<IProjectExpense[]>>(url);
};

export const getExpenseDetails = async (
  projectId: number,
  expenseId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.expense.getExpenseDetails, {
    projectId,
    expenseId,
  });
  return await AxiosHelper.get<IProjectExpense>(url);
};

export const addProjectExpenses = async (
  projectId: number,
  form: IProjectExpenseForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.expense.create, { projectId });
  return await AxiosHelper.post<IProjectExpenseForm, IProjectExpense>(
    url,
    form,
  );
};

export const updateProjectExpense = async (
  projectId: number,
  expenseId: number,
  form: IProjectExpenseForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.expense.update, {
    projectId,
    expenseId,
  });
  return await AxiosHelper.patch<IProjectExpenseForm, IProjectExpense>(
    url,
    form,
  );
};

export const deleteProjectExpense = async (
  projectId: number,
  expenseId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.expense.delete, {
    projectId,
    expenseId,
  });
  return await AxiosHelper.delete(url);
};

export const getProjectExpenseComponents = async (
  projectId: number,
  expenseId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.expense.expenseComponents, {
    projectId,
    expenseId,
  });

  return await AxiosHelper.get<IReportTable>(url);
};
