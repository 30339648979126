import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  addExpenseBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 250px)",
  },
  dataRow: {
    cursor: "pointer",
  },
}));
