import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDispatch } from "react-redux";
import { format, isToday, isYesterday, parseISO } from "date-fns";

import Log from "../log";
import Tabs from "../tabs";
import UserNote from "./note";
import useStyles from "./styles";
import ActionButton from "../action-button";
import LoaderScreen from "../loader-screen";
import ViewWrapper from "../view-wrapper";
import NoteFormModal from "../note-form-modal";
import { cn } from "../../utils/helpers";
import { useAPI } from "../../utils/hooks";
import { OBJECT_TYPE } from "../../constants";
import { setDeleteModalPropsAction } from "../../utils/redux/slices";
import {
  createNote,
  updateNote,
  getNotes,
  deleteNote,
} from "../../apis/common";
import {
  ILog,
  ITab,
  INoteForm,
  INoteFormErrors,
  INote,
} from "../../interfaces";

interface IProps {
  logs: ILog[];
  totalLogs: number;
  type: OBJECT_TYPE;
  loading: boolean;
  nextPage: (type: OBJECT_TYPE, id: number | string) => void;
  id: number | string;
}

type TabTypes = "notes" | "activity";

export default function Logs({
  logs,
  totalLogs,
  type,
  loading,
  nextPage,
  id,
}: IProps) {
  const TABS: ITab<TabTypes>[] = [
    {
      label: "Notes",
      value: "notes",
      disabled: id ? false : true,
    },
    {
      label: "Activity",
      value: "activity",
      disabled: false,
    },
  ];

  const styles = useStyles();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = React.useState<TabTypes>("activity");
  const [notes, setNotes] = React.useState<INote[]>([]);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = React.useState(false);
  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = React.useState(false);
  const [noteForm, setNoteForm] = React.useState<INoteForm>({
    note: "",
    object_id: id,
    object_type: type,
  });
  const [editingNoteId, setEditingNoteId] = React.useState<number | null>(null);
  const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>(
    {},
  );

  const {
    callAPI: createNoteCallAPI,
    loading: createNoteLoading,
    fieldErrors: createNoteFieldErrors,
    setFieldErrors: setCreateNoteFieldErrors,
  } = useAPI<INote, INoteFormErrors>(createNote);

  const {
    callAPI: updateNoteCallAPI,
    loading: updateNoteLoading,
    fieldErrors: updateNoteFieldErrors,
    setFieldErrors: setUpdateNoteFieldErrors,
  } = useAPI<INote, INoteFormErrors>(updateNote);

  const {
    callAPI: getNotesCallAPI,
    loading: getNotesLoading,
    errored: getNotesError,
  } = useAPI(getNotes, {
    initialLoading: true,
  });

  const { callAPI: deleteNoteCallAPI } = useAPI(deleteNote, {
    setConfirmModalLoading: true,
  });

  function groupByDate(logs: ILog[]) {
    return logs.reduce(
      (acc, log) => {
        const logDate = parseISO(log.timestamp);

        let dateKey;
        if (isToday(logDate)) {
          dateKey = "Today";
        } else if (isYesterday(logDate)) {
          dateKey = "Yesterday";
        } else {
          dateKey = format(logDate, "MMMM d");
        }

        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }

        acc[dateKey].push(log);
        return acc;
      },
      {} as Record<string, ILog[]>,
    );
  }

  const groupedLogs = React.useMemo(() => {
    return groupByDate(logs);
  }, [logs]);

  React.useEffect(() => {
    const initialState: { [key: string]: boolean } = {};
    Object.keys(groupedLogs).forEach((group) => {
      initialState[group] = true; // setting all days expanded
    });
    setExpanded(initialState);
  }, [groupedLogs]);

  React.useEffect(() => {
    if (selectedTab === "notes") {
      getNotesCallAPI(type, id).then((response) => {
        if (response && response.results) {
          setNotes(response.results);
        }
      });
    }
  }, [selectedTab, type, id]);

  const toggleExpand = (group: string) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [group]: !prevExpanded[group],
    }));
  };

  const handleTabChange = (tab: ITab) => {
    setSelectedTab(tab.value as TabTypes);
  };

  const handleOpenNoteModal = (note?: INote) => {
    if (note) {
      setNoteForm({
        note: note.note,
        object_id: id,
        object_type: type,
      });
      setEditingNoteId(note.id);
      setIsEditNoteModalOpen(true);
    } else {
      setNoteForm({
        note: "",
        object_id: id,
        object_type: type,
      });
      setIsAddNoteModalOpen(true);
    }
  };

  const handleCloseAddNoteModal = () => {
    setIsAddNoteModalOpen(false);
  };

  const handleCloseEditNoteModal = () => {
    setIsEditNoteModalOpen(false);
    setEditingNoteId(null);
  };

  const handleAddNote = async (form: INoteForm) => {
    const newNote = await createNoteCallAPI(form);
    newNote && setNotes((prevNotes) => [newNote, ...prevNotes]);
    return newNote;
  };

  const handleEditNote = async (form: INoteForm) => {
    const updatedNote = await updateNoteCallAPI(editingNoteId, form);
    updatedNote &&
      setNotes((prevNotes) => {
        const updatedNotes = prevNotes.map((note) =>
          note.id === editingNoteId ? updatedNote : note,
        );
        return updatedNotes;
      });
    return updatedNote;
  };

  const handleDeleteNoteConfirm = async (noteId: number) => {
    const deleted = await deleteNoteCallAPI(noteId);
    deleted &&
      setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
  };

  const handleDeleteNote = (noteId: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Note",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteNoteConfirm(noteId),
      }),
    );
  };

  return (
    <Box>
      <Tabs
        tabs={TABS}
        selectedTab={selectedTab}
        onClick={handleTabChange}
        borderBottom={false}
      />
      {selectedTab === "activity" && (
        <Box>
          {/* if no logs then show message */}
          {!loading && groupedLogs && Object.keys(groupedLogs).length === 0 && (
            <Box className={styles.classes.logContainer}>
              <Typography variant="body1">No changes found</Typography>
            </Box>
          )}
          {loading && Object.keys(groupedLogs)?.length === 0 && (
            <LoaderScreen />
          )}
          {Object.entries(groupedLogs).map(([group, logs]) => (
            <Box key={group} className={styles.classes.logContainer}>
              <Box className={styles.classes.logDetails}>
                <Typography variant="subtitle1">{group}</Typography>
                <IconButton onClick={() => toggleExpand(group)}>
                  {expanded[group] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Collapse in={expanded[group]}>
                {logs.map((log, i) => (
                  <Box key={i} ml={2}>
                    <Log log={log} type={type} />
                  </Box>
                ))}
              </Collapse>
            </Box>
          ))}
          {logs?.length < totalLogs && (
            <Box className={styles.classes.loadMoreContainer}>
              {loading ? (
                <LoaderScreen />
              ) : (
                <Button onClick={() => nextPage(type, id)} variant="outlined">
                  Load More
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
      {selectedTab === "notes" && (
        <Box className={styles.classes.logContainer}>
          {/* if no logs then show message */}
          {!getNotesLoading && notes.length === 0 && (
            <Box>
              <Typography variant="body1">No notes found</Typography>
            </Box>
          )}

          <ActionButton
            actionType="add"
            size="medium"
            onClick={() => handleOpenNoteModal()}
            classes={{ root: cn("!my-2") }}
          >
            Add Note
          </ActionButton>

          {getNotesLoading && <LoaderScreen />}

          {notes.map((note) => (
            <UserNote
              key={note.id}
              note={note}
              onEdit={handleOpenNoteModal}
              onDelete={handleDeleteNote}
            />
          ))}
        </Box>
      )}
      <NoteFormModal
        headerLabel="Add Note"
        open={isAddNoteModalOpen}
        loading={createNoteLoading}
        form={noteForm}
        setForm={setNoteForm}
        formErrors={createNoteFieldErrors}
        setFormErrors={setCreateNoteFieldErrors}
        onClose={handleCloseAddNoteModal}
        onConfirm={handleAddNote}
      />
      <NoteFormModal
        headerLabel="Edit Note"
        open={isEditNoteModalOpen}
        loading={updateNoteLoading}
        form={noteForm}
        setForm={setNoteForm}
        formErrors={updateNoteFieldErrors}
        setFormErrors={setUpdateNoteFieldErrors}
        onClose={handleCloseEditNoteModal}
        onConfirm={handleEditNote}
      />
    </Box>
  );
}
