import ProjectPagesLayout from "../../../../components/project-pages-layout";
import ProjectExpenseDetailsView from "../../../../views/project/pro-forma/expenses/details";
import TabbedLayout from "../../../../components/tabbed-layout";
import { getProjectTiming } from "../../../../apis/project/timing";
import {
  getExpenseDetails,
  updateProjectExpense,
  deleteProjectExpense,
  getProjectExpenseComponents,
} from "../../../../apis/project/expenses";

export default function ProjectExpenseDetails(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectExpenseDetailsView
          getExpenseDetails={getExpenseDetails}
          getProjectTiming={getProjectTiming}
          updateProjectExpense={updateProjectExpense}
          deleteExpense={deleteProjectExpense}
          getProjectExpenseComponents={getProjectExpenseComponents}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
