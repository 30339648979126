import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{ isFilterOpen: boolean }>()((
  theme,
  { isFilterOpen },
) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);
  return {
    header: {
      ".MuiTableCell-root": {
        fontWeight: 600,
      },
    },
    activeButton: {
      backgroundColor: selectedTheme.color.aboveSurface,
    },
    button: {
      textTransform: "capitalize",
    },
    tableContainer: {
      maxHeight: isFilterOpen ? "calc(100vh - 316px)" : "calc(100vh - 264px)",
    },
  };
});
