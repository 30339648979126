import {
  AxiosHelper,
  replaceParams,
  transformBlobToFile,
} from "../../utils/helpers";
import {
  IReportTable,
  IDealTaxCredit,
  IDealTaxCreditTransfer,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferResults,
} from "../../interfaces";
import { DEAL_API_ROUTES } from "../../constants";

export const getDealTaxCreditPTC_Data = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.taxCredit.getPTC_Data, { dealId });
  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadDealTaxCreditPTC_Data = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.taxCredit.downloadPTC_Data, {
    dealId,
  });
  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(response, `deal-${dealId}-ptc-report.xlsx`);
  });
};

export const getTaxCredit = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.taxCredit.get, { dealId });
  return await AxiosHelper.get<IDealTaxCredit[]>(url);
};

export const getDealTaxCreditTransferDetails = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.dealTaxCreditTransfer.get, {
    dealId,
  });
  return await AxiosHelper.get<IDealTaxCreditTransfer[]>(url);
};

export const getDealTaxCreditDirectPayDetails = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.dealTaxCreditDirectPay.get, {
    dealId,
  });
  return await AxiosHelper.get<IDealTaxCreditTransfer[]>(url);
};

export const updateDealTaxCreditTransferDetails = async (
  dealId: number,
  transferSizingId: number,
  form: IDealTaxCreditTransferForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealTaxCreditTransfer.update, {
    dealId,
    transferSizingId,
  });
  return await AxiosHelper.patch<
    IDealTaxCreditTransferForm,
    IDealTaxCreditTransferResults
  >(url, form);
};
