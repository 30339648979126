import {
  PROJECT_API_ROUTES,
  PROJECT_COST_REPORT_TYPE,
  REPORT_API_ROUTES,
} from "../../constants";
import {
  AxiosHelper,
  replaceParams,
  transformBlobToFile,
} from "../../utils/helpers";
import { IProjectsStageReport } from "../../interfaces";

export const getProjectsStageReport = async () => {
  return await AxiosHelper.get<IProjectsStageReport>(
    REPORT_API_ROUTES.project.getByStage,
  );
};

export const downloadProjectCostsReport = async (
  projectId: number,
  type: keyof typeof PROJECT_COST_REPORT_TYPE,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.summary.download, {
    projectId,
    type,
  });
  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(
      response,
      `project-cost-report-download-${projectId}.xlsx`,
    );
  });
};
