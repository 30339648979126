import React from "react";
import { OBJECT_TYPE } from "../../constants";

interface IProps {
  onOpen?: (
    type: OBJECT_TYPE,
    id: number | string,
    parentId?: number | string | undefined,
  ) => void;
  onClose?: () => void;
}

export const useDrawer = ({
  onOpen = () => undefined,
  onClose = () => undefined,
}: IProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  const handleOpenDrawer = (
    type: OBJECT_TYPE,
    id: number | string,
    parentId?: number | string | undefined,
  ) => {
    onOpen(type, id, parentId);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    onClose();
    setIsDrawerOpen(false);
  };

  return {
    isDrawerOpen,
    handleOpenDrawer,
    handleCloseDrawer,
  };
};
