import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectTaxCreditView from "../../../views/project/tax/tax-credit";
import TabbedLayout from "../../../components/tabbed-layout";
import {
  downloadProjectTaxCreditPTC_Report,
  getProjectTaxCredit,
  getProjectTaxCreditPTC_Report,
  updateProjectTaxCredit,
} from "../../../apis/project/tax-credit";

export default function TaxCredits(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Tax">
        <ProjectTaxCreditView
          getProjectTaxCredit={getProjectTaxCredit}
          getProjectTaxCreditPTC_Report={getProjectTaxCreditPTC_Report}
          downloadProjectTaxCreditPTC_Report={
            downloadProjectTaxCreditPTC_Report
          }
          updateProjectTaxCredit={updateProjectTaxCredit}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
