import * as Sentry from "@sentry/react";

export const initializeSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_NODE_ENV,
    enabled: process.env.REACT_APP_NODE_ENV !== "dev",
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],
    tracePropagationTargets: [
      /^https:\/\/app\.(uat\.|staging\.|miramar\.|qa\.|test\.)?capezero\.com\/$/,
    ],
    profilesSampleRate: process.env.REACT_APP_NODE_ENV === "prod" ? 0.4 : 1.0,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
